import Modal from '../Modal';
import BackDrop from '../../BackDrop';

function ModalWithBackdrop({ children, isOpen, onBackDropClick, className }) {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <BackDrop onClick={onBackDropClick} />
      <Modal isOpen={isOpen} className={className}>
        {children}
      </Modal>
    </>
  );
}

export default ModalWithBackdrop;
