const calcAPYs = (tokenInfosByAddress, userStableBorrowRate) => {
  const RAY = 10 ** 27; // 10 to the power 27
  const SECONDS_PER_YEAR = 31536000;

  const liquidityRate = Number(tokenInfosByAddress.liquidityRate.toString());
  const variableBorrowRate = Number(tokenInfosByAddress.variableBorrowRate.toString());
  const stableBorrowRate = Number(tokenInfosByAddress.stableBorrowRate.toString());
  userStableBorrowRate = Number(userStableBorrowRate.toString());

  // Deposit and Borrow calculations
  // APY and APR are returned here as decimals, multiply by 100 to get the percents
  const depositAPR = liquidityRate / RAY;
  const variableBorrowAPR = variableBorrowRate / RAY;
  const stableBorrowAPR = stableBorrowRate / RAY;
  const userStableBorrowAPR = userStableBorrowRate / RAY;

  const depositAPY = ((1 + depositAPR / SECONDS_PER_YEAR) ** SECONDS_PER_YEAR - 1) * 100;
  const variableBorrowAPY =
    ((1 + variableBorrowAPR / SECONDS_PER_YEAR) ** SECONDS_PER_YEAR - 1) * 100;
  const stableBorrowAPY = ((1 + stableBorrowAPR / SECONDS_PER_YEAR) ** SECONDS_PER_YEAR - 1) * 100;
  const userStableBorrowAPY =
    ((1 + userStableBorrowAPR / SECONDS_PER_YEAR) ** SECONDS_PER_YEAR - 1) * 100;

  return {
    depositAPY,
    variableBorrowAPY,
    stableBorrowAPY,
    userStableBorrowAPY
  };
};
export default calcAPYs;
