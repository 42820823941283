const WalletIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        fill="#FCFCFF"
        stroke="#FCFCFF"
        strokeWidth=".1"
        d="M15.47 3.795a2.213 2.213 0 0 0-2.2-2.004H3.16C1.943 1.79.95 2.782.95 4v9.997c0 1.22.992 2.211 2.21 2.211h11.68c1.218 0 2.21-.992 2.21-2.21V5.912c0-1-.667-1.846-1.58-2.118ZM3.16 2.9h10.11c.503 0 .929.34 1.06.802H3.16c-.4 0-.776.108-1.1.295A1.103 1.103 0 0 1 3.16 2.9Zm11.68 12.2H3.16a1.103 1.103 0 0 1-1.1-1.101V5.912c0-.607.493-1.101 1.1-1.101h11.68c.607 0 1.1.494 1.1 1.101v1.711h-3.167a2.365 2.365 0 0 0-2.363 2.363 2.365 2.365 0 0 0 2.363 2.363h3.168v1.649c0 .607-.495 1.1-1.102 1.1Zm1.1-3.86h-3.167a1.255 1.255 0 0 1-1.254-1.253c0-.691.562-1.253 1.254-1.253h3.168v2.507Z"
      />
      <path
        fill="#FCFCFF"
        stroke="#FCFCFF"
        strokeWidth=".1"
        d="M12.96 10.6a.575.575 0 1 0 0-1.15.575.575 0 0 0 0 1.15Z"
      />
    </svg>
  );
};

export default WalletIcon;
