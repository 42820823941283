import { useMemo } from 'react';

const useChartData = ({ datasets, labels, borderColor, theme }) => {
  const isDarkMode = theme === 'dark-mode';

  return useMemo(() => {
    return {
      type: 'line',
      options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false
        },
        tooltips: {
          mode: 'nearest'
        },
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              drawTicks: false
            },
            ticks: {
              padding: 24,
              color: isDarkMode ? 'rgba(252, 252, 255, 0.64)' : 'rgba(21, 19, 35, 0.64)'
            }
          },
          y: {
            grid: {
              color: isDarkMode ? '#3B3960' : '#DFE2FF',
              borderColor: isDarkMode ? '#3B3960' : '#DFE2FF',
              drawBorder: false,
              drawTicks: false,
              borderDash: [5, 5]
            },
            ticks: {
              padding: 18,
              color: isDarkMode ? 'rgba(252, 252, 255, 0.64)' : 'rgba(21, 19, 35, 0.64)'
            }
          }
        },

        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false
          }
        },
        elements: {
          point: {
            pointBorderWidth: 3,
            pointHoverBorderWidth: 1,
            pointHoverRadius: 8,
            radius: 0,
            pointStyle: 'circle'
          }
        }
      },
      data: {
        labels,
        datasets
      }
    };
  }, [datasets, isDarkMode, labels]);
};

export default useChartData;
