export const ETH_JSONRPC_URL = 'https://eth.public-rpc.com';
export const BINANCE_JSONRPC_URL = 'https://bsc-dataseed.binance.org/';
export const POLYGON_JSONRPC_URL = 'https://rpc-mainnet.maticvigil.com/';
export const AVAX_JSONRPC_URL = 'https://api.avax.network/ext/bc/C/rpc';
export const FANTOM_JSONRPC_URL = 'https://rpc.ftm.tools';
export const ARBITRUM_JSONRPC_URL = 'https://arb1.arbitrum.io/rpc';
export const GNOSIS_JSONRPC_URL = 'https://rpc.gnosischain.com';
export const OPTIMISM_JSONRPC_URL = 'https://mainnet.optimism.io';
export const KLAYTN_JSONRPC_URL = 'https://public-node-api.klaytnapi.com/v1/cypress';
export const ETHERSCAN_URL = 'https://etherscan.io/tx/';
