import { useEffect } from 'react';
import './index.scss';

function Modal({ children, isOpen, className = '' }) {
  useEffect(() => {
    // We need to disable scroll on the page if modal is opened
    const appEl = document.getElementsByTagName('body')[0];
    if (appEl) {
      appEl.style.overflowY = 'hidden';
    }
    return () => {
      appEl.style.overflowY = 'auto';
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`TPY_modal ${className}`}>
      <div className="TPY_modal-content">{children}</div>
    </div>
  );
}

export default Modal;
