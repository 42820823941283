import BLOCKCHAIN_IDS from '../constants/defi';
import WalletConnectProvider from '@walletconnect/web3-provider';
import {
  ARBITRUM_JSONRPC_URL,
  AVAX_JSONRPC_URL,
  BINANCE_JSONRPC_URL,
  ETH_JSONRPC_URL,
  FANTOM_JSONRPC_URL,
  GNOSIS_JSONRPC_URL,
  KLAYTN_JSONRPC_URL,
  OPTIMISM_JSONRPC_URL,
  POLYGON_JSONRPC_URL
} from '../constants/rpcUrls';

class Web3Provider {
  makeWalletConnectProvider = async () => {
    const provider = await new WalletConnectProvider({
      infuraId: '',
      bridge: '',
      rpc: {
        [BLOCKCHAIN_IDS.ETH]: ETH_JSONRPC_URL,
        [BLOCKCHAIN_IDS.BSC]: BINANCE_JSONRPC_URL,
        [BLOCKCHAIN_IDS.POLYGON]: POLYGON_JSONRPC_URL,
        [BLOCKCHAIN_IDS.AVAX]: AVAX_JSONRPC_URL,
        [BLOCKCHAIN_IDS.FANTOM]: FANTOM_JSONRPC_URL,
        [BLOCKCHAIN_IDS.ARBITRUM]: ARBITRUM_JSONRPC_URL,
        [BLOCKCHAIN_IDS.GNOSIS]: GNOSIS_JSONRPC_URL,
        [BLOCKCHAIN_IDS.OPTIMISM]: OPTIMISM_JSONRPC_URL,
        [BLOCKCHAIN_IDS.KLAYTN]: KLAYTN_JSONRPC_URL
      }
    });
    await provider.enable();
    return provider;
  };
}

export default new Web3Provider();
