import { changeNetwork } from '../utilitis';
import BLOCKCHAIN_IDS from '../constants/defi';

export const MetamaskConnect = async () => {
  if (!window?.ethereum) {
    return;
  }
  try {
    // Check if chain id isn't equal to GOERLI, change network to GOERLI
    if (Number(window.ethereum.networkVersion) !== BLOCKCHAIN_IDS.ETH) {
      await changeNetwork('mainnet');
    }

    const accounts = await window.ethereum?.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    console.log('account ?????????????', accounts);

    return account;
  } catch (err) {
    return '';
  }
};
