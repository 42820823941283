import BigNumber from 'bignumber.js/bignumber';

const getTotalBorrowed = (variableDebtTokenTotal, stableDebtTokenTotal, decimals) => {
  const tenPowDecimals = BigNumber(10).pow(decimals);

  const totalBorrowed = BigNumber(variableDebtTokenTotal).plus(BigNumber(stableDebtTokenTotal))
  const res = totalBorrowed.dividedBy(tenPowDecimals);
  return res;
};

export default getTotalBorrowed;
