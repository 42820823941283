import { memo, useEffect, useState } from 'react';
import PopupWithBackdrop from '../PopupWithBackdrop';
import { useIntl } from 'react-intl';

import './index.scss';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MoreMenuDropdown = () => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState();

  function toggleSettingsMenu() {
    setIsOpen((isOpen) => !isOpen);
  }

  useEffect(() => {
    const localLang = localStorage.getItem('lang');
    if (!localLang) return setLanguage('en-US');
    setLanguage(localLang);
  }, [localStorage.getItem('lang')]);

  return (
    <div className="more-menu-dropdown">
      <div className={`more-menu-button ${isOpen ? 'active' : ''}`} onClick={toggleSettingsMenu}>
        <p>{intl.messages.more}</p>
        <ExpandMoreIcon />
      </div>
      {isOpen && (
        <PopupWithBackdrop onClose={toggleSettingsMenu}>
          {/* TODO remove href for a tags and put real links */}
          <div className="menu-content">
            <a className="menu-item" target="_blank" href="https://thrupenny.io/">
              {intl.messages.official_website}
            </a>
            <a className="menu-item" target="_blank" href="https://twitter.com/Thrupennydefi">
              {intl.messages.twitter}
            </a>
            <a
              className="menu-item"
              target="_blank"
              href={
                language === 'en-US'
                  ? 'https://thrupenny.gitbook.io/whitepaper/'
                  : 'https://thrupenny.gitbook.io/cnwhitepaper/xiang-mu-wen-zhang/2023-nian-1-ji-du/thrupenny-qu-zhong-xin-hua-jin-rong-sheng-tai-xi-tong-wei-lai-de-huo-bi-guan-li'
              }>
              {intl.messages.whitepaper}
            </a>
            <a className="menu-item" target="_blank" href="https://www.youtube.com/@thrupenny6205">
              {intl.messages.youTube}
            </a>
            <a
              className="menu-item"
              target="_blank"
              href="https://www.linkedin.com/company/thrupenny/">
              {intl.messages.linkedIn}
            </a>
            <a className="menu-item" target="_blank" href="https://medium.com/@thrupennyprotocol">
              {intl.messages.medium}
            </a>
          </div>
        </PopupWithBackdrop>
      )}
    </div>
  );
};

export default memo(MoreMenuDropdown);
