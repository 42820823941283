import ModalWithBackdrop from '../Modals/ModalWithBackdrop';
import ModalContentWrapperWithClose from '../Modals/ModalContentWrapperWithClose';
import TPYOutlinedButton from '../TPYButtonUI';
import { useIntl } from 'react-intl';

import styles from './index.module.css';

const DisconnectWalletModal = ({
  address = '0xb8f918E8D77A3E7A1efB8d357c3b1A974e26D045',
  walletType = 'Metamask',
  isOpen,
  onClose,
  onDisconnectClick,
  connectedWalletImgSrc
}) => {
  const intl = useIntl();

  return (
    <ModalWithBackdrop isOpen={isOpen} onBackDropClick={onClose}>
      <ModalContentWrapperWithClose className={styles['disconnect-wallet-modal']} onClose={onClose}>
        <p className={styles.title}>Your Wallet</p>
        <div className={styles.flex}>
          <img className={styles.image} src={connectedWalletImgSrc} alt="Wallet" />
          <div className={styles['wallet-info']}>
            <p className={styles['wallet-type']}>{walletType}</p>
            <p className={styles.address}>{address}</p>
          </div>
        </div>
        <TPYOutlinedButton
          onClick={onDisconnectClick}
          className={styles.button}
          buttonLabel={intl.messages.log_out}
        />
      </ModalContentWrapperWithClose>
    </ModalWithBackdrop>
  );
};

export default DisconnectWalletModal;
