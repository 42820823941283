const ShareIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none">
      <g clipPath="url(#a)">
        <path
          fill="#151323"
          fillOpacity=".64"
          d="M15.5 1v4.5a.5.5 0 1 1-1 0V2.207L7.354 9.354a.499.499 0 0 1-.708 0 .5.5 0 0 1 0-.707L13.793 1.5H10.5a.5.5 0 1 1 0-1H15a.5.5 0 0 1 .5.5Zm-2 13V8a.5.5 0 1 0-1 0v6a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V4a.5.5 0 0 1 .5-.5h6a.5.5 0 1 0 0-1H2C1.173 2.5.5 3.173.5 4v10c0 .827.673 1.5 1.5 1.5h10c.827 0 1.5-.673 1.5-1.5Z"
        />
        <path
          stroke="#151323"
          strokeOpacity=".64"
          strokeWidth=".2"
          d="M15.6 5.5V1a.6.6 0 0 0-.6-.6h-4.5a.6.6 0 1 0 0 1.2h3.052L6.576 8.576a.6.6 0 1 0 .848.848L14.4 2.448V5.5a.6.6 0 1 0 1.2 0Zm-2 8.5V8a.6.6 0 1 0-1.2 0v6a.4.4 0 0 1-.4.4H2a.4.4 0 0 1-.4-.4V4c0-.22.18-.4.4-.4h6a.6.6 0 1 0 0-1.2H2C1.118 2.4.4 3.118.4 4v10c0 .882.718 1.6 1.6 1.6h10c.882 0 1.6-.718 1.6-1.6Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShareIcon;
