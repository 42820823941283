import ModalContentWrapper from '../ModalContentWrapper';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';

const ModalContentWrapperWithClose = ({ children, onClose, className }) => {
  return (
    <ModalContentWrapper className={className}>
      <div className="TPY_modal-close">
        <CloseIcon onClick={onClose} className="icon-close" />
      </div>
      {children}
    </ModalContentWrapper>
  );
};
export default ModalContentWrapperWithClose;
