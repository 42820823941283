import styles from './tPYInnerTitlesDescription.module.scss';

function TPYInnerTitlesDescription({ title, description, textAlign, className }) {
  return (
    <div className={`${styles.titleDescriptionBox} ${className}`} style={{ textAlign }}>
      <h3>{title}</h3>
      {description && <h4>{description}</h4>}
    </div>
  );
}

export default TPYInnerTitlesDescription;
