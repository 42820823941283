import './index.scss';

const TPYToggle = ({
  disabled = false,
  checked = false,
  onChange,
  className = '',
  toggleText,
  imgSrc,
  sliderClassName = '',
  ...defaultCheckBoxProps
}) => {
  return (
    <>
      <label
        className={`tpy-toggle ${disabled ? 'disabled' : ''} ${
          checked ? 'checked' : ''
        } ${className}`}>
        <input
          {...defaultCheckBoxProps}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <div className={`slider round ${sliderClassName}`}>
          <div className="circle">{imgSrc && <img src={imgSrc} alt="Icon" />}</div>
          {toggleText && <p className={`toggle-text ${checked ? 'on' : 'off'}`}>{toggleText}</p>}
        </div>
      </label>
    </>
  );
};

export default TPYToggle;
