import TextWithWarningIcon from '../TextWithWarningIcon';
import styles from './index.module.css';

const TitleWithValueAndWarningIcon = ({
  title,
  warning,
  value,
  convertedValue,
  className = ''
}) => {
  return (
    <div className={`${className} ${styles['title-with-value-and-warning-icon']}`}>
      <div>
        <TextWithWarningIcon text={title} warning={warning} />
        <span className={styles.value}>{value}</span>
      </div>
      {convertedValue && <p className={styles['converted-value']}>{convertedValue}</p>}
    </div>
  );
};

export default TitleWithValueAndWarningIcon;
