const config = {
  clientId: '',
  clientSecret: '',
  scopes:
    'wallet:accounts:read,wallet:transactions:read,wallet:deposits:read,wallet:withdrawals:read,wallet:addresses:read,wallet:addresses:create,wallet:user:read,wallet:user:email',
  redirect_uri: 'https://coinbase.com/oauth/javascript_sdk_redirect'
};

function coinbase(params) {
  return new Promise((resolve, reject) => {
    const baseUrl = 'https://coinbase.com';
    const redirectUri = `${baseUrl}/oauth/javascript_sdk_redirect`;
    let url = `${baseUrl}/oauth/authorize`;

    url += '?response_type=code';
    url += '&account=all';
    url += '&grant_type=authorization_code';
    url += `&redirect_uri=${encodeURIComponent(redirectUri)}`;
    url += `&client_id=${params.clientId}`;

    if (params?.scopes) {
      url += `&scope=${encodeURIComponent(params.scopes)}`;
    }

    if (params?.meta) {
      Object.keys(params.meta).forEach((key) => {
        url += `&meta[${encodeURIComponent(key)}]=${encodeURIComponent(params.meta[key])}`;
      });
    }

    const width = 850;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;
    const popUp = window.open(
      url,
      'coinbase-oauth',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    const onMessageRecieve = (event) => {
      if (event.origin?.replace('www.', '') !== baseUrl) {
        return;
      }

      event.source.close();
      const data = JSON.parse(event.data);

      if (data?.code) {
        resolve(data);
      } else {
        reject(new Error('Something went wrong.'));
      }

      window.removeEventListener('message', onMessageRecieve);
    };

    const loop = setInterval(() => {
      if (popUp?.closed) {
        window.removeEventListener('message', onMessageRecieve);
        clearInterval(loop);
      }
    }, 100);

    window.addEventListener('message', onMessageRecieve, false);
  });
}

export const CoinbaseConnect = async () => {
  const { clientId, scopes } = config;
  try {
    const data = await coinbase({
      clientId,
      scopes
    });
    return data?.code || '';
  } catch (e) {
    return '';
  }
};
