import TPYTooltipWrapper from '../TPYTooltipWrapper';

import styles from './index.module.scss';

function TPYOutlinedButton({ onClick, buttonLabel, isDisabled, className = '' }) {
  return (
    <div className={`${styles.tPYButtonUIBtn} ${className}`} onClick={onClick}>
      <TPYTooltipWrapper tooltipText={buttonLabel}>
        <button disabled={isDisabled}>{buttonLabel}</button>
      </TPYTooltipWrapper>
    </div>
  );
}

export default TPYOutlinedButton;
