import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import TPYButton from '../buttons/TPYButton';
import TPYOutlinedButton from '../TPYButtonUI';
import TextWithShareIcon from '../TextWithShareIcon';
import ModalWithBackdrop from '../Modals/ModalWithBackdrop';
import ModalContentWrapperWithClose from '../Modals/ModalContentWrapperWithClose';

import styles from './index.module.css';

import SuccessSrc from '../../images/icons/success-icon-with-shadow.svg';
import { useSelector } from 'react-redux';
import { addTokenToWallet } from '../../services/AddTokenToWallet';

const SuccessPopup = ({ isSuccessPopupModalOpen, showAddToWalletSection = true }) => {
  const intl = useIntl();
  const [isToggleModal, setIsToggleModal] = useState(false);
  const data = useSelector((state) => state.reserveReducer.successModalData);
  const dismissModal = () => {
    setIsToggleModal(false);
  };
  const capitalizeFirstLetter = (string = '') => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    setIsToggleModal(isSuccessPopupModalOpen);
  }, [isSuccessPopupModalOpen]);

  const addToken = () => {
    addTokenToWallet(data?.symbol);
  };

  return (
    <ModalWithBackdrop isOpen={isToggleModal} onBackDropClick={() => {}}>
      <ModalContentWrapperWithClose className={styles['success-modal']} onClose={dismissModal}>
        <p className={styles.message}>All Done!</p>
        <div className={styles['success-img']}>
          <img src={SuccessSrc} alt="" />
        </div>
        {data?.rate && showAddToWalletSection ? (
          <p className={styles.text}>
            {data?.rate === 1
              ? intl.messages.switch_rate_to_stable
              : intl.messages.switch_rate_to_variable}
          </p>
        ) : showAddToWalletSection ? (
          <p className={styles.text}>
            You {capitalizeFirstLetter(data?.type)} {data?.amount} {data?.symbol}
          </p>
        ) : data?.borrowRepayModal ? (
          <p className={styles.text}>
            You {capitalizeFirstLetter(data?.type)} {data?.amount} {data?.symbol}
          </p>
        ) : (
          <p className={styles.text}>
            You have {data.disableCollateral ? 'disabled' : 'enabled'} {data?.symbol} as collateral
          </p>
        )}
        {!data?.rate && showAddToWalletSection ? (
          <div className={styles['add-to-wallet-section']}>
            <img src={data?.image} alt="..." />
            <p>{intl.messages.add_a_token_to_wallet}</p>
            <TPYButton onClick={addToken} tooltipText={intl.messages.add_to_wallet}>
              {intl.messages.add_to_wallet}
            </TPYButton>
          </div>
        ) : null}
        <TextWithShareIcon
          className={styles['share-section']}
          text={intl.messages.review_tx_details}
          link={data?.txHeash}
        />
        <TPYOutlinedButton
          className={styles.button}
          buttonLabel="Ok,Close"
          onClick={dismissModal}
        />
      </ModalContentWrapperWithClose>
    </ModalWithBackdrop>
  );
};

export default SuccessPopup;
