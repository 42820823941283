import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import TPYSelect from '../TSelect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeContext } from '../../contexts/ThemeContext';
import TPYToggle from '../TPYToggle';
import SocialMediaIcons from '../SocialMediaIcons';
import MoreMenuDropdown from '../MoreMenuDropdown';
import { useIntl } from 'react-intl';

import './index.scss';

import Sun from '../../images/icons/sun.svg';
import Moon from '../../images/icons/moon.svg';

const HeaderMobileMenu = ({
  navigationItems,
  languagesItems,
  currentLocale,
  onChangeLanguage,
  onChangeTheme,
  isDarkMode,
  logoSrc,
  onClose
}) => {
  const intl = useIntl();

  return (
    <div className="more-menu">
      <div className="logo-with-close-icon">
        <img className="logo" src={logoSrc} alt="" />
        <CloseIcon onClick={onClose} />
      </div>
      <div className="navbar-items">
        <p>{intl.messages.menu}</p>
        <nav>
          {navigationItems.map((item) => (
            <Link key={item.to} onClick={() => onClose()} className="navigation-item" to={item.to}>
              {item.title}
            </Link>
          ))}
        </nav>
        <MoreMenuDropdown />
      </div>
      <hr />
      <div className="actions">
        <div className="mobile-action-item">
          <p className="action-title">{intl.messages.language}</p>
          <TPYSelect
            IconComponent={ExpandMoreIcon}
            options={languagesItems}
            value={currentLocale}
            onChange={onChangeLanguage}
            displayPropertyName="value"
          />
        </div>
        <div className="mobile-action-item">
          <p className="action-title">{intl.messages.dark_mode}</p>
          <ThemeContext.Consumer>
            {({ changeTheme }) => (
              <>
                <TPYToggle
                  imgSrc={!isDarkMode ? Sun : Moon}
                  toggleText={isDarkMode ? 'Off' : 'On'}
                  checked={!isDarkMode}
                  onChange={() => onChangeTheme(changeTheme)}
                />
              </>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
      <hr />
      <div className="support">
        <p className="label">{intl.messages.support}</p>
        <div className="social-media">
          <p>Social Media</p>
          <SocialMediaIcons isDarkMode={isDarkMode} />
        </div>
      </div>
    </div>
  );
};

export default HeaderMobileMenu;
