import { combineReducers } from 'redux';
import walletReducer from './reducers/walletReducer';
import contractsReducer from './reducers/contractsReducer';
import reserveReducer from './reducers/reserveReducer';

const rootReducer = combineReducers({
  wallet: walletReducer,
  contractsReducer: contractsReducer,
  reserveReducer: reserveReducer
});

export default rootReducer;
