import Tabs from '../Tabs';
import TabItem from '../TabItem';
import ModalContentWrapperWithClose from '../Modals/ModalContentWrapperWithClose';
import ModalWithBackdrop from '../Modals/ModalWithBackdrop';
import TextWithImage from '../TextWithImage';
import TextWithWarningIcon from '../TextWithWarningIcon';
import AmountBox from '../AmountBox';
import ButtonWithLoader from '../buttons/ButtonWithLoader';
import { useEffect, useState } from 'react';
import BorrowRepayTransactionBox from '../BorrowRepayTransactionBox';
import BorrowDropdown from '../BorrowDropdown';
import { useIntl } from 'react-intl';
import { contractAddresses } from '../../constants/contracts';

import BigNumber from 'bignumber.js';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  calculateHealthFactorOnAction,
  convertToNumber,
  getBorrowLimitInfo,
  getToken
} from '../../utilitis';
import { approveAllowance, doABorrow, doARepay, getTokenBalance } from '../../services/Web3';
import { infinityMinusNumber, infinityNumber, maxUint, ten } from '../../constants/numbers';
import TextWithShareIcon from '../TextWithShareIcon';
import { availableByToken } from '../../utilitis/available';
import { setSuccessModalData } from '../../redux/actions/reserveActions';

const TABS = {
  borrow: 'borrow',
  repay: 'repay'
};

const BorrowRepayModal = ({
  isOpen = false,
  onClose,
  initialActiveTab = TABS.borrow,
  tabData = {},
  walletAddress,
  getUserInfo,
  mode = 1,
  setIsToggleSuccessPopup
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.borrow);
  const [rate, setRate] = useState('variable');
  const [amount, setAmount] = useState(0);
  const [isETH, setIsETH] = useState(true);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [healthFactor, setHealthFactor] = useState({});
  const [debtBalance, setDebtBalance] = useState({});
  const [approved, setApproved] = useState(false);
  const [isToggleModal, setIsToggleModal] = useState(false);
  const [borrowLimit, setBorrowLimit] = useState({});
  const [isLoadingApproveToContinue, setIsLoadingApproveToContinue] = useState(false);
  const userReservesData = useSelector((state) => state.reserveReducer.userReservesData) || {};
  const [currentTabData, setCurrentTabData] = useState();
  const [availableBalance, setAvailableBalance] = useState(0);
  const walletBalanceProviderContract = useSelector(
    (state) => state.contractsReducer.walletBalanceProviderContract
  );
  const lendingPoolContract = useSelector((state) => state.contractsReducer.lendingPoolContract);
  const priceOracleContract = useSelector((state) => state.contractsReducer.priceOracleContract);

  const calculateHealthFactorChangeOnBorrow = (amountToSupply = 0) => {
    const beforeHealthFactor = userReservesData.healthFactor;
    const afterHealthFactor = calculateHealthFactorOnAction(
      userReservesData,
      tabData,
      tabData.underlyingAsset,
      activeTab,
      amountToSupply
    );
    return {
      beforeHealthFactor,
      afterHealthFactor
    };
  };

  const isActiveTabBorrow = activeTab === TABS.borrow;
  const isActiveTabRepay = activeTab === TABS.repay;

  const calculateBorrowLimitInfoOnAction = (amountToSupply = 0) => {
    const debtInETH = userReservesData.debtInETH;
    const maxInETH = debtInETH.plus(userReservesData.availableBorrowInETH);
    const borrowLimitInfoBefore = getBorrowLimitInfo(
      tabData.underlyingAsset,
      activeTab,
      0,
      tabData,
      userReservesData.availableBorrowInETH,
      debtInETH,
      maxInETH
    );
    const borrowLimitInfoAfter = getBorrowLimitInfo(
      tabData.underlyingAsset,
      activeTab,
      amountToSupply,
      tabData,
      userReservesData.availableBorrowInETH,
      debtInETH,
      maxInETH
    );
    return {
      borrowLimitInfoBefore,
      borrowLimitInfoAfter
    };
  };
  const handleBorrow = async () => {
    setIsLoading(true);
    setIsToggleSuccessPopup({
      isSuccessPopupModalOpen: false,
      showAddToWalletSection: false
    });
    try {
      const result = await doABorrow(
        isETH,
        walletAddress,
        window.ethereum,
        tabData,
        rate === 'variable' ? 2 : 1,
        amount,
        userReservesData.availableBorrowInETH
      );
      dispatch(
        setSuccessModalData({
          data: {
            txHeash: result.transactionHash,
            type: intl.messages.borrowed,
            amount: amount,
            symbol: tabData?.symbol,
            borrowRepayModal: true
          }
        })
      );
      await getUserInfo();
      setIsToggleModal(false);
      setIsToggleSuccessPopup({
        isSuccessPopupModalOpen: true,
        showAddToWalletSection: false
      });
    } catch (e) {}

    setIsLoading(false);
  };
  const handleRepay = async () => {
    setIsLoading(true);
    setIsToggleSuccessPopup({
      isSuccessPopupModalOpen: false,
      showAddToWalletSection: false
    });

    try {
      const result = await doARepay(
        isETH,
        walletAddress,
        tabData,
        userReservesData.userInfosByTokenAddress,
        mode,
        amount,
        window.ethereum,
        currentAmount
      );
      dispatch(
        setSuccessModalData({
          data: {
            txHeash: result.transactionHash,
            type: intl.messages.repaid,
            amount: currentAmount,
            symbol: tabData?.symbol,
            borrowRepayModal: true
          }
        })
      );
      await getUserInfo();
      setIsToggleModal(false);
      setIsToggleSuccessPopup({
        isSuccessPopupModalOpen: true,
        showAddToWalletSection: false
      });
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };
  const handleApproveToContinue = async () => {
    console.log(123);
    setIsLoadingApproveToContinue((prevState) => !prevState);
    try {
      const result = await approveAllowance(
        window.ethereum,
        walletAddress,
        isETH
          ? rate === 'stable'
            ? 'AWETHS'
            : 'AWETHV'
          : currentTabData?.symbol === 'ETH'
          ? 'WETH'
          : currentTabData?.symbol,
        isETH ? contractAddresses.wethGetwayContract : contractAddresses.lendingPoolContract,
        isETH
          ? rate === 'stable'
            ? tabData?.stableDebtTokenAddress
            : tabData?.variableDebtTokenAddress
          : '',
        isETH
      );
      setApproved(result.transactionHash);
      setCurrentTabData((prevState) => {
        return {
          ...prevState,
          allowance: 10
        };
      });
    } catch (e) {}
    setIsLoadingApproveToContinue(false);
  };

  const changeAmount = (amount, isMax) => {
    let amtValue = amount;
    if (isMax) {
      if (activeTab === TABS.repay) {
        if (isETH) {
          if (
            new BigNumber(tabData.balance)
              .multipliedBy(ten.pow(tabData.decimals))
              .gt(debtBalance.beforeRepayBalance)
          ) {
            amtValue = maxUint;
          }
        } else if (new BigNumber(tabData.realBalance).gt(debtBalance.beforeRepayBalance)) {
          console.log('bbb');
          amtValue = maxUint;
        }
      }
    }
    setCurrentAmount(amount);
    setAmount(amtValue);
    const healthFactorData = calculateHealthFactorChangeOnBorrow(amount || 0);
    setHealthFactor(healthFactorData);
    activeTab === 'repay' &&
      setDebtBalance((prevState) => ({
        ...prevState,
        afterRepayBalance: Math.abs(
          convertToNumber(prevState.beforeRepayBalance, tabData?.decimals) - amount || 0
        ).toFixed(2)
      }));
  };
  const checkAvailableBalance = async () => {
    return await availableByToken(
      priceOracleContract,
      isETH || tabData?.symbol === 'ETH' ? getToken('WETH').address : tabData?.underlyingAsset,
      lendingPoolContract,
      walletBalanceProviderContract,
      walletAddress
    );
  };

  useEffect(() => {
    setActiveTab(initialActiveTab);
    if (tabData && Object.keys(tabData).length) {
      const healthFactorData = calculateHealthFactorChangeOnBorrow(0);
      setHealthFactor(healthFactorData);
      // setBorrowLimit(calculateBorrowLimitInfoOnSupply(0));
    }
  }, [initialActiveTab]);

  const getBalance = async () => {
    const debtTokenAddr =
      mode === 1 ? tabData?.stableDebtTokenAddress : tabData?.variableDebtTokenAddress;
    const balance = await getTokenBalance(
      walletBalanceProviderContract,
      walletAddress,
      debtTokenAddr
    );
    setDebtBalance({
      beforeRepayBalance: balance,
      afterRepayBalance: convertToNumber(balance, tabData?.decimals)
    });

    return balance;
  };
  useEffect(() => {
    if (userReservesData?.availableBorrowInETH) {
      setBorrowLimit(calculateBorrowLimitInfoOnAction(0));
    }
    setIsToggleModal(isOpen);
    if (userReservesData?.userInfosByTokenAddress) {
      getBalance();
      if (isActiveTabBorrow && lendingPoolContract) {
        checkAvailableBalance()
          .then((data) => {
            setAvailableBalance(data);
          })
          .catch((e) => {
            console.log(e, '-------------');
          });
      } else {
        setAvailableBalance(0);
      }
    }
    setIsETH(tabData?.symbol === 'ETH');
    setCurrentTabData(tabData);
    setAmount(0);
    setCurrentAmount(0);
    setIsLoading(false);
    setApproved(false);
  }, [isOpen, isActiveTabBorrow]);
  return (
    <ModalWithBackdrop isOpen={isToggleModal} onBackDropClick={onClose}>
      <ModalContentWrapperWithClose className="borrow-repay-modal" onClose={onClose}>
        <div>
          <TextWithImage
            className="coin-with-name"
            text={
              isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
            }>
            <img
              src={
                isETH
                  ? getToken('ETH')?.image
                  : tabData?.symbol === 'ETH'
                  ? getToken('WETH')?.image
                  : getToken(tabData?.symbol)?.image
              }
              alt=""
            />
          </TextWithImage>
          <Tabs className={'tabs'}>
            <TabItem
              onClick={() => {
                setActiveTab(TABS.borrow);
              }}
              isActive={isActiveTabBorrow}
              text={intl.messages.borrow}
            />
            <TabItem
              onClick={() => {
                setActiveTab(TABS.repay);
              }}
              isActive={activeTab === TABS.repay}
              text={intl.messages.repay}
            />
          </Tabs>
          {isActiveTabBorrow && (
            <div>
              <TextWithWarningIcon className="amount-label" text={intl.messages.amount} warning />
              <AmountBox
                className="amount-section"
                setAmountValue={changeAmount}
                imgSrc={
                  isETH
                    ? getToken('ETH')?.image
                    : tabData?.symbol === 'ETH'
                    ? getToken('WETH')?.image
                    : getToken(tabData?.symbol)?.image
                }
                balance={availableBalance.toFixed(Number(tabData?.decimals) || 0)}
                coinName={
                  isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
                }
                tabData={tabData}
                balanceType={activeTab}
                checkMaxAmount={checkAvailableBalance}
              />
              {/*<TransactionBannerSection*/}
              {/*  currentPrice={`$ ${borrowLimit.borrowLimitInfoBefore?.currentInETH.toString()}`}*/}
              {/*  maxPrice={`$ ${convertToNumber(borrowLimit.borrowLimitInfoBefore?.maxInETH)}`}*/}
              {/*  borrowLimit={`$ ${convertToNumber(*/}
              {/*    borrowLimit.borrowLimitInfoBefore?.availableBorrowInETH*/}
              {/*  )}`}*/}
              {/*  borrowBalance={`$ ${priceFormatter.format(*/}
              {/*    convertMarketCurrencyPriceToUSD(*/}
              {/*      tabData?.priceInMarketReferenceCurrency,*/}
              {/*      tabData.networkBaseTokenPriceInUsd*/}
              {/*    ).toNumber() * availableBalance*/}
              {/*  )}`}*/}
              {/*/>*/}
              <div className="transactions-overview-title">
                <TextWithWarningIcon
                  className="transaction-label"
                  text={intl.messages.transaction_overview}
                  warning={false}
                />
                <TextWithWarningIcon
                  className="transaction-label"
                  text={intl.messages.borrow_apy_rate}
                  warning
                />
                <BorrowDropdown changeRate={setRate} disable={true} />
              </div>
              <BorrowRepayTransactionBox
                className="transaction-box"
                isETH={isETH}
                setIsETH={setIsETH}
                showIsETH={tabData?.symbol === 'ETH' || tabData?.symbol === 'WETH'}
                supplyPercent={
                  rate === 'variable'
                    ? `${tabData.variableBorrowAPY?.toFixed(2)} %`
                    : `${tabData.stableBorrowAPY?.toFixed(2)} %`
                }
                healthFactorPrice={
                  convertToNumber(healthFactor.afterHealthFactor) >= infinityNumber ||
                  convertToNumber(healthFactor.afterHealthFactor) < infinityMinusNumber
                    ? 'Infinity'
                    : convertToNumber(healthFactor.afterHealthFactor)
                }
                liquidationAt="1.0"
                from={
                  convertToNumber(healthFactor.beforeHealthFactor) >= infinityNumber ||
                  convertToNumber(healthFactor.beforeHealthFactor) < infinityMinusNumber
                    ? 'Infinity'
                    : convertToNumber(healthFactor.beforeHealthFactor)
                }
                symbol={tabData.symbol}
              />
              {approved ? (
                <div className="success-section">
                  <p className="success-text">{intl.messages.approve_confirmed}</p>
                  <TextWithShareIcon
                    text={intl.messages.review_approval_tx_details}
                    link={approved}
                  />
                </div>
              ) : null}
              {isETH ? (
                <ButtonWithLoader
                  className="approve-to-continue-button"
                  onClick={handleApproveToContinue}
                  isLoading={isLoadingApproveToContinue}
                  disabled={
                    !Number(currentAmount) || isLoadingApproveToContinue
                      ? true
                      : +convertToNumber(
                          rate === 'stable'
                            ? currentTabData?.stableAllowance
                            : currentTabData?.variableAllowance,
                          tabData?.decimals
                        ) >= currentAmount || approved
                  }>
                  {intl.messages.approve_to_continue}
                </ButtonWithLoader>
              ) : null}
              <ButtonWithLoader
                className="approve-to-continue-button"
                isLoading={isLoading}
                disabled={
                  isLoading || !Number(amount)
                    ? true
                    : !isETH
                    ? false
                    : +convertToNumber(
                        rate === 'stable'
                          ? currentTabData?.stableAllowance
                          : currentTabData?.variableAllowance,
                        tabData?.decimals
                      ) < currentAmount && !approved
                }
                onClick={handleBorrow}>
                {intl.messages.borrow}{' '}
                {isETH ? 'ETH' : tabData?.symbol === 'ETH' ? 'WETH' : tabData?.symbol}
              </ButtonWithLoader>
            </div>
          )}
          {isActiveTabRepay && (
            <div>
              <TextWithWarningIcon className="amount-label" text={intl.messages.amount} warning />
              <AmountBox
                className="amount-section"
                imgSrc={
                  isETH
                    ? getToken('ETH')?.image
                    : tabData?.symbol === 'ETH'
                    ? getToken('WETH')?.image
                    : getToken(tabData?.symbol)?.image
                }
                setAmountValue={changeAmount}
                tabData={tabData}
                balance={
                  tabData?.symbol === 'ETH'
                    ? isETH
                      ? tabData?.balance
                      : tabData?.balanceWETH
                    : tabData?.symbol === 'WETH' && isETH
                    ? tabData?.balanceETH
                    : convertToNumber(tabData?.realBalance, tabData?.decimals, false)
                }
                maxAmount={debtBalance?.beforeRepayBalance || 0}
                coinName={
                  isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
                }
                balanceType={activeTab}
                checkMaxAmount={getBalance}
              />
              {/*<TransactionBannerSection*/}
              {/*  currentPrice={`$ ${borrowLimit.borrowLimitInfoBefore?.currentInETH.toString()}`}*/}
              {/*  maxPrice={`$ ${convertToNumber(borrowLimit.borrowLimitInfoBefore?.maxInETH)}`}*/}
              {/*  borrowLimit={`$ ${convertToNumber(*/}
              {/*    borrowLimit.borrowLimitInfoBefore?.availableBorrowInETH*/}
              {/*  )}`}*/}
              {/*  borrowBalance={`$ ${priceFormatter.format(*/}
              {/*    convertMarketCurrencyPriceToUSD(*/}
              {/*      tabData?.priceInMarketReferenceCurrency,*/}
              {/*      tabData.networkBaseTokenPriceInUsd*/}
              {/*    ).toNumber() * availableBalance*/}
              {/*  )}`}*/}
              {/*/>*/}
              <div className="transactions-overview-title">
                <TextWithWarningIcon
                  className="transaction-label"
                  text={intl.messages.transaction_overview}
                  warning={false}
                />
                <TextWithWarningIcon
                  className="transaction-label"
                  text={intl.messages.borrow_apy_rate}
                  warning
                />
              </div>
              <BorrowRepayTransactionBox
                className="transaction-box"
                isETH={isETH}
                setIsETH={setIsETH}
                showIsETH={tabData?.symbol === 'ETH' || tabData?.symbol === 'WETH'}
                beforeRepayBalance={debtBalance?.beforeRepayBalance || '0'}
                afterRepayBalance={debtBalance?.afterRepayBalance || '0'}
                repay={true}
                decimals={tabData.decimals}
                supplyPercent={
                  mode === 1
                    ? `${tabData.variableBorrowAPY?.toFixed(2)} %`
                    : `${tabData.stableBorrowAPY?.toFixed(2)} %`
                }
                healthFactorPrice={
                  healthFactor.afterHealthFactor?.toString() > infinityNumber ||
                  healthFactor.afterHealthFactor?.toString() < infinityMinusNumber
                    ? 'Infinity'
                    : convertToNumber(healthFactor.afterHealthFactor)
                }
                liquidationAt="1.0"
                to={37.8}
                from={
                  healthFactor.beforeHealthFactor?.toString() >= infinityNumber ||
                  healthFactor.beforeHealthFactor?.toString() < infinityMinusNumber
                    ? 'Infinity'
                    : convertToNumber(healthFactor.beforeHealthFactor)
                }
                symbol={tabData.symbol}
              />
              {approved ? (
                <div className="success-section">
                  <p className="success-text">{intl.messages.approve_confirmed}</p>
                  <TextWithShareIcon
                    text={intl.messages.review_approval_tx_details}
                    link={approved}
                  />
                </div>
              ) : null}
              {!isETH ? (
                <ButtonWithLoader
                  className="approve-to-continue-button"
                  onClick={handleApproveToContinue}
                  isLoading={isLoadingApproveToContinue}
                  disabled={
                    !Number(currentAmount) || isLoadingApproveToContinue
                      ? true
                      : +convertToNumber(currentTabData?.allowance, tabData?.decimals) >=
                          currentAmount || approved
                  }>
                  {intl.messages.approve_to_continue}
                </ButtonWithLoader>
              ) : null}
              <ButtonWithLoader
                className="supply-dai"
                isLoading={isLoading}
                onClick={handleRepay}
                disabled={
                  !Number(currentAmount) || isLoading
                    ? true
                    : isETH
                    ? false
                    : +convertToNumber(currentTabData?.allowance, tabData?.decimals) <
                        currentAmount && !approved
                }>
                {intl.messages.repay_dai}{' '}
                {isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol}
              </ButtonWithLoader>
            </div>
          )}
        </div>
      </ModalContentWrapperWithClose>
    </ModalWithBackdrop>
  );
};

export default BorrowRepayModal;
