import { tenPow18 } from '../constants/numbers';
import BigNumber from 'bignumber.js';

const convertMarketCurrencyPriceToUSD = (amountInMarketCurrency, tokenPriceInUsd) => {
  return new BigNumber(amountInMarketCurrency)
    .multipliedBy(tokenPriceInUsd)
    .div(tenPow18)
    .div(new BigNumber(10).pow(8));
};

export default convertMarketCurrencyPriceToUSD;
