import { memo, useState } from 'react';
import PopupWithBackdrop from '../PopupWithBackdrop';
import { useIntl } from 'react-intl';

import './index.scss';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BorrowDropdown = ({ changeRate, disable }) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(intl.messages.variable_rate);

  function toggleSettingsMenu() {
    if (disable) return;
    setIsOpen((isOpen) => !isOpen);
  }
  const changeActiveRate = (rate) => {
    setActiveItem(rate === 'variable' ? 'Variable Rate' : 'Stable Rate');
    changeRate(rate);
    toggleSettingsMenu();
  };

  return (
    <div className="borrow-dropdown">
      <div
        className={`borrow-button ${isOpen ? 'active' : ''} ${disable ? 'disabled' : ''}`}
        onClick={toggleSettingsMenu}>
        <p>{activeItem}</p>
        {disable ? null : <ExpandMoreIcon />}
      </div>
      {isOpen && (
        <PopupWithBackdrop onClose={toggleSettingsMenu}>
          <div className="menu-content">
            <div onClick={() => changeActiveRate('variable')} className="menu-item">
              {intl.messages.variable_rate}
            </div>
            <div onClick={() => changeActiveRate('stable')} className="menu-item">
              {intl.messages.stable_rate}
            </div>
          </div>
        </PopupWithBackdrop>
      )}
    </div>
  );
};

export default memo(BorrowDropdown);
