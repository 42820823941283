import './index.scss';

function BackDrop({ children, onClick, className = '' }) {
  return (
    <div onClick={onClick} className={`TPY-backdrop ${className}`}>
      {children}
    </div>
  );
}

export default BackDrop;
