import Web3 from 'web3';
import Provider from '../services/Provider';

export const WalletConnect = async () => {
  try {
    const provider = await Provider.makeWalletConnectProvider();
    const web3 = await new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    return accounts?.[0] || '';
  } catch (e) {
    return '';
  }
};
