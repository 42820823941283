import styles from './index.module.css';

const TextWithHighlightedDot = ({ text, color, className = '' }) => {
  return (
    <div className={`${className} ${styles['text-with-highlighted-dot']}`}>
      <div className={`${styles.dot} ${color}`} style={{ background: color }} />
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default TextWithHighlightedDot;
