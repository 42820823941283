import Banner from '../../components/Banner';
import Section from '../../components/Section';
import SelectCoins from '../../components/SelectCoins';
import InnerBlocks from '../../components/InnerBlocks';
import GenericTitle from '../../components/GenericTitle';
import ConnectWalletBanner from '../../components/ConnectWalletBanner';
import { useIntl } from 'react-intl';

import ethereum from '../../images/coins/ethereum.svg';

import styles from './dashboard.module.scss';

const Dashboard = () => {
  const intl = useIntl();

  return (
    <Section className={styles.dashboardContainer}>
      <GenericTitle title={intl.messages.dashboard} />
      <Banner className={styles.firstBanner}>
        <SelectCoins title={intl.messages.ethereum_market} imgUrl={ethereum} />
        <div className={styles.dashboardInnerBlocks}>
          <InnerBlocks title={intl.messages.net_worth}/>
          <InnerBlocks title={intl.messages.net_apy}/>
        </div>
      </Banner>
      <ConnectWalletBanner />
    </Section>
  );
};

export default Dashboard;
