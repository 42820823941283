const initialState = {
  lendingPoolContract: {},
  uIPoolDataProiderContract: {},
  priceOracleContract: {},
  walletBalanceProviderContract: {}
};

const contractsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'ADD_CONTRACT_UI_POOL_DATA_PROVIDER':
      return {
        ...state,
        uIPoolDataProiderContract: payload.contract
      };
    case 'ADD_CONTRACT_LANDING_POOL':
      return {
        ...state,
        lendingPoolContract: payload.contract
      };
    case 'ADD_CONTRACT_PRICE_ORACLE':
      return {
        ...state,
        priceOracleContract: payload.contract
      };
    case 'ADD_CONTRACT_WALLET_BALANCE_PROVIDER':
      return {
        ...state,
        walletBalanceProviderContract: payload.contract
      };
    default:
      return state;
  }
};

export default contractsReducer;
