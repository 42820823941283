import React, { memo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuSelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import './index.scss';

/**
 * Select Component
 * @description Mui Select component customized with CoinStats design.
 *
 *  To get access to all props this component gets just pass to the following Mui doc link.
 *  Link - https://mui.com/material-ui/api/select/
 *
 * @returns {JSX.Element} Select Component
 */

function TPYSelect({
  options,
  value,
  onChange,
  inputProps,
  name,
  keyPropertyName = 'id',
  valuePropertyName = 'id',
  displayPropertyName,
  getDisplayPropertyName,
  doesUseValuePropertyName = true,
  error = null,
  placeholder,
  ...defaultSelectProps
}) {
  return (
    <>
      <div className="TPY_select">
        <FormControl error={error}>
          <MuSelect
            name={name}
            MenuProps={{ disablePortal: true }}
            inputProps={inputProps}
            value={value}
            onChange={onChange}
            IconComponent={(params) => {
              const { className } = params;
              return (
                <span className={className}>
                  <i className="icon-arrange" />
                </span>
              );
            }}
            {...defaultSelectProps}>
            {placeholder && (
              <MenuItem disabled value="">
                <em>{placeholder}</em>
              </MenuItem>
            )}
            {options.map((item, index) => {
              if (item && typeof item === 'object') {
                let displayValue = item[displayPropertyName];
                if (typeof getDisplayPropertyName === 'function') {
                  displayValue = getDisplayPropertyName(item);
                }
                return (
                  <MenuItem
                    key={item[keyPropertyName]}
                    value={doesUseValuePropertyName ? item[valuePropertyName] : item}>
                    {displayValue}
                  </MenuItem>
                );
              }

              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </MuSelect>
          {error && <FormHelperText>Error</FormHelperText>}
        </FormControl>
      </div>
    </>
  );
}

export default memo(TPYSelect);
