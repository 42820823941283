import tpy from '../images/coins/tpy.svg';
import dai from '../images/coins/dai.png';
import weth from '../images/coins/weth.svg';
import usdt from '../images/coins/usdt.svg';
import eth from '../images/coins/eth.svg';
import wbtc from '../images/coins/btc.svg';
import usdc from '../images/coins/usdc.svg';
import shib from '../images/coins/shib.svg';

const TOKENS = {
  TPY: {
    address: '0x5d17b74d346Ee3bC81c1d5E39feC412CF74C8FEf',
    image: tpy,
    symbol: 'TPY',
    decimals: 18
  },
  WETH: {
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    image: weth,
    symbol: 'WETH',
    decimals: 18
  },
  USDT: {
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    image: usdt,
    symbol: 'USDT',
    decimals: 6
  },
  DAI: {
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    image: dai,
    symbol: 'DAI',
    decimals: 18
  },
  ETH: {
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    image: eth,
    symbol: 'ETH',
    decimals: 18
  },
  WBTC: {
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    image: wbtc,
    symbol: 'WBTC',
    decimals: 8
  },
  USDC: {
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    image: usdc,
    symbol: 'USDC',
    decimals: 6
  },
  SHIB: {
    address: '0x8eCc462DA3A6Df851505168c988E75BF3BeD27fe',
    image: shib,
    symbol: 'SHIB',
    decimals: 18
  }
};

export const ETH_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const WETH_ADDRESS = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';

export default TOKENS;
