import BigNumber from 'bignumber.js/bignumber';
import convertTokenAmountToETH from './convertTokenAmountToETH';
import convertMarketCurrencyPriceToUSD from './convertMarketCurrencyPriceToUSD';

const getTotalBorrowedInUsd = (tokenInfo) => {
  const totalBorrowed = BigNumber(tokenInfo.variableDebtTokenTotal).plus(BigNumber(tokenInfo.stableDebtTokenTotal));
  const amountInETH = convertTokenAmountToETH(tokenInfo.tokenAddr, totalBorrowed, tokenInfo);

  const totalBorrowedInUsd = convertMarketCurrencyPriceToUSD(
    amountInETH,
    tokenInfo.networkBaseTokenPriceInUsd
  );
  return totalBorrowedInUsd;
};

export default getTotalBorrowedInUsd;
