import { getToken } from '../utilitis';

export const addTokenToWallet = async (symbol) => {
  const token = getToken(symbol);
  console.log(token, 'token');
  return window.ethereum?.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        image: `${token.image}`
      }
    }
  });
};
