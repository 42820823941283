import BigNumber from 'bignumber.js';
import { tenPow18, zero } from '../constants/numbers';
import { getAssetPrice, getUserAvailableETH } from '../services/Web3';

const available = (healthFactor, availableBorrowInETH, priceInMarketReferenceCurrency) => {
  if (new BigNumber(healthFactor).lte(tenPow18)) {
    return zero;
  }
  return availableBorrowInETH ? availableBorrowInETH.div(priceInMarketReferenceCurrency) : zero;
};

export const availableByToken = async (
  priceOracleContract,
  tokenAddr,
  lendingPoolContract,
  walletBalanceProviderContract,
  walletAddress
) => {
  const assetPrice = await getAssetPrice(priceOracleContract, tokenAddr);
  const availableAmount = await getUserAvailableETH(
    lendingPoolContract,
    walletBalanceProviderContract,
    priceOracleContract,
    walletAddress
  );
  if (assetPrice === '0') return 0;
  return new BigNumber(availableAmount).div(assetPrice);
};
export const availableWithdrawByToken = async (
  priceOracleContract,
  tokenAddr,
  lendingPoolContract,
  walletBalanceProviderContract,
  walletAddress
) => {
  const assetPrice = await getAssetPrice(priceOracleContract, tokenAddr);
  const availableAmount = await getUserAvailableETH(
    lendingPoolContract,
    walletBalanceProviderContract,
    priceOracleContract,
    walletAddress
  );

  return { availableAmount, assetPrice };
};

export default available;
