import ShareIcon from '../ShareIcon';
import WarningText from '../WarningText';
import IconWithBackground from '../IconWithBackground';

import styles from './index.module.css';

const InfoBanner = ({ title, value, href, className = '', secondValue, warning, warningText }) => {
  return (
    <div className={`${styles['info-banner']} ${className}`}>
      <div className={styles['warning-icon-with-text']}>
        <p className={styles.title}>{title}</p>
        {warning && <WarningText warningText={warningText} />}
      </div>
      <div className={styles['description-section']}>
        <p className={styles.value}>{value}</p>
        {href && (
          <a href={href}>
            <IconWithBackground>
              <ShareIcon />
            </IconWithBackground>
          </a>
        )}
      </div>
      {secondValue && <p className={styles['second-value']}>{secondValue}</p>}
    </div>
  );
};

export default InfoBanner;
