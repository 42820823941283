import { LOCALES } from './locales';

export const messages = {
  [LOCALES.ENGLISH]: {
    metamask: 'Metamask',
    trustWallet: 'Trust Wallet',
    coinBase: 'CoinBase',
    formatic: 'Formatic',
    walletConnect: 'WalletConnect',
    balance: 'Balance',
    walletBalance: 'Wallet Balance',
    supplyBalance: 'Supply Balance',
    max: 'Max',
    variable_rate: 'Variable Rate',
    stable_rate: 'Stable Rate',
    stable: 'Stable',
    borrow: 'Borrow',
    repay: 'Repay',
    transaction_overview: 'Transaction overview',
    approve_confirmed: 'Approve Confirmed',
    review_approval_tx_details: 'Review approval tx details',
    please_switch_to_ethereum: 'Please switch to Ethereum. Switch Network',
    enter_an_amount: 'Enter an Amount',
    repay_dai: 'Repay',
    borrow_api: 'Borrow APY',
    health_factor: 'Health Factor',
    dept_balance: 'Remaining debt',
    liqudation: 'Liqudation',
    high_risk: 'High Risk',
    claim_tpy: 'Claim TPY',
    claim: 'Claim',
    amount: 'Amount',
    tpy_per_month: 'TPY Per Month',
    please_connect_your_wallet: 'Please, Connect Your Wallet',
    connect_wallet: 'Connect Wallet',
    switch_network: 'Switch Network',
    select_your_preferred_wallet: 'Select your preferred Wallet',
    cooldown_to_unstake: 'Cooldown to unstake',
    the_cooldown_period_id:
      'The cooldown period is the time required prior to unstaking your tokens(10 days). You can only withdraw your assets from the Security Module after the cooldown period and within the active the unstake window.',
    cooldown_period: 'Cooldown period',
    unstake_window: 'Unstake window',
    your_wallet_is_empty: 'Your wallet is empty. Purchase or transfer assets',
    i_understand_how_cooldown:
      'I understand how cooldown ( 10 deys ) and unstaking ( 2 days ) work',
    activate_cooldown: 'Activate Cooldown',
    overview: 'Overview',
    your_info: 'Your Info',
    supply_info: 'Supply Info',
    supply_apy: 'Supply APY',
    total_borrowed: 'Total Borrowed',
    borrowed: 'Borrowed',
    repaid: 'Repaid',
    borrow_apy_variable: 'Borrow APY, variable',
    borrow_apy_stable: 'Borrow APY, stable',
    total_supplied: 'Total Supplied',
    distribution_apy: 'Distribution APY',
    max_ltv: 'Max LTV',
    liquidation_threshold: 'Liquidation threshold',
    liquidation_penalty: 'Liquidation penalty',
    borrow_info: 'Borrow Info',
    borrow_apy: 'Borrow APY',
    total_borrow: 'Total Borrow',
    collector_info: 'Collector Info',
    reserve_factor: 'Reserve factor',
    collector_contract: 'Collector Contract',
    view_contract: 'View Contract',
    interest_rate_model: 'Interest Rate Model',
    utilization_rate: 'Utilization Rate',
    wallet_balance: 'Wallet Balance',
    available_to_supply: 'Available to Supply',
    available_to_borrow: 'Available to Borrow',
    delegate_your_power: 'Delegate your power',
    asset_to_delegate: 'Asset to delegate',
    staked_tpy: 'Staked TPY',
    type_of_delegation: 'Type of delegation',
    voting_power: 'Voting power',
    proposition_power: 'Proposition power',
    delegate: 'Delegate',
    review_tx: 'Review tx ETH',
    new_apy: 'New APY',
    switch_rate: 'Switch Rate',
    support: 'Support',
    governance_vote: 'Governance Vote',
    dashboard: 'Dashboard',
    market: 'Market',
    stake: 'Stake',
    governance: 'Governance',
    menu: 'Menu',
    language: 'Language',
    dark_mode: 'Dark Mode',
    more: 'More',
    buy_tpy: 'Buy TPY',
    bridges_tpy: 'Bridges TPY',
    example: 'Example',
    disabling_this_asset:
      'Disabling this asset as collateral affects your borrowing power and Health Factor',
    supply_balance: 'Supply balance',
    disable_coin_as_collateral: 'Disable ETH as Collateral',
    stake_tpy: 'Stake TPY',
    cooldown_period_warning: 'Cooldown Period Warning',
    approve: 'Approve',
    add_a_token_to_wallet: 'Add a Token to wallet to track your balance.',
    switch_rate_to_variable: 'You have switched to variable rate',
    switch_rate_to_stable: 'You have switched to stable rate',
    add_to_wallet: 'Add to Wallet',
    switch_type: 'Switch APY type',
    review_tx_details: 'Review tx details',
    supply: 'Supply',
    withdraw: 'Withdraw',
    approve_to_continue: 'Approve to Continue',
    supply_dai: 'Supply DAI',
    withdraw_dai: 'Withdraw DAI',
    borrow_limit: 'Borrow limit',
    borrow_balance: 'Borrow balance',
    collateralization: 'Collateralization',
    success: 'Success',
    variables: 'Variables',
    variable: 'Variable',
    see_charts: 'See Charts',
    select_APY_type_to_switch: 'Select APY type to switch',
    asset: 'Asset',
    total_suplied: 'Total Suplied',
    available: 'Available',
    apy_variable: 'APY, variable',
    apy_stable: 'APY, stable',
    ethereum_market: 'Ethereum Market',
    your_supplies: 'Your Supplies',
    net_apy: 'Net APY',
    collateral: 'Collateral',
    your_borrows: 'Your Borrows',
    nothing_supplied_yet: 'Nothing supplied yet',
    assets_to_supply: 'Assets to Supply',
    assets_to_borrows: 'Assets to Borrows',
    assets_to_supplies: 'Assets to Supplies',
    can_be_collateral: 'Can Be Collateral',
    of_limit: 'APY type',
    debt: 'Debt',
    proposal_overview: 'Proposal Overview',
    not_voted: 'Not Voted',
    for: 'For',
    against: 'Against',
    votes: 'Votes',
    your_voting_info: 'Your Voting Info',
    you_did_not_participate_in_this_proposal: 'You did not participate in this proposal',
    voting_results: 'Voting Results',
    state: 'State',
    quorum: 'Quorum',
    reached: 'Reached',
    current_votes: 'Current Votes',
    required: 'Required',
    differential: 'Differential',
    ethereum_assets: 'Ethereum assets',
    markets: 'Markets',
    log_out: 'Log out',
    net_worth: 'Net Worth',
    on: 'On',
    off: 'Off',
    total_market_size: 'Total market size',
    total_available: 'Total available',
    total_borrows: 'Total borrows',
    staking: 'Staking',
    funds_in_the_safety_module: 'Funds In The Safety Module',
    total_emission_per_day: 'Total Emission Per Day',
    unstake_tpy: 'Unstake TPY',
    staked: 'Staked',
    earned: 'Earned',
    apy: 'APY',
    d10: '10 d',
    snapshots: 'Snapshots',
    forum: 'Forum',
    faq: 'FAQ',
    proposals: 'Proposals',
    all_proposals: 'All Proposals',
    add_stMATIC_to_aave_pool_on_polygon: 'Add stMATIC to Aave v3 pool on Polygon',
    executed: 'Executed',
    defeated: 'Defeated',
    voting_weight: 'Voting Weight',
    created: 'Created',
    active: 'Active',
    succeed: 'Succeed',
    queue: 'Queue',
    execute: 'Execute',
    addresses4: '4 addresses',
    addresses0: '0 addresses',
    executes_on_sep_17_2022: 'Executes on Sep 17, 2022',
    current_diferential: 'Current Diferential',
    total_voting_power: 'Total voting power',
    simple_summary: 'Simple Summary',
    abstract: 'Abstract',
    motivation: 'Motivation',
    specification: 'Specification',
    test_cases: 'Test Cases',
    implementation: 'Implementation',
    dai_stablecoin: 'Dai Stablecoin',
    reserve_size: 'Reserve size',
    available_liquidity: 'Available liquidity',
    oracle_price: 'Oracle price',
    collateral_usage: 'Collateral Usage',
    liquidation_at: 'Liquidation at',
    supply_balance_dai: 'Supply Balance',
    current: 'Current',
    borrow_apy_rate: 'Borrow APY Rate',
    official_website: 'Official Website',
    twitter: 'Twitter',
    whitepaper: 'Whitepaper',
    youTube: 'YouTube',
    linkedIn: 'LinkedIn',
    medium: 'Medium',
    warning_Text_Collateral:
      'Allows you to decide whether to use a supplied asset as collateral. An asset used as collateral will affect your borrowing power and health factor.',
    warning_Text_Health_Factor:
      'Your health factor and loan to value determine the assurance of your collateral. As soon as your health factor reaches  1.0 your position will be liquidated. To avoid liquidations, you can supply more collateral or repay borrow positions.',
    warning_Text_Net_APY:
      'Net APY is the combined effect of all supply and borrow positions on net worth, including incentives. It is possible to have a negative net APY if debt APY is higher than supply APY.',
    warning_Text_Borrow_APY_variable:
      'Variable interest rate will fluctuate based on the market conditions. Recommended for short-term positions.',
    warning_Text_Borrow_APY_stable:
      'Stable interest rate will stay the same for the duration of your loan. Recommended for long-term loan periods and for users who prefer predictability.',
    warning_Text_Max_LTV:
      'The Maximum LTV ratio represents the maximum borrowing power of a specific collateral. For example, if a collateral has an LTV of 75%, the user can borrow up to 0.75 worth of ETH in the principal currency for every 1 ETH worth of collateral.',
    warning_Text_Liquidation_Threshold:
      'This represents the threshold at which a borrow position will be considered undercollateralized and subject to liquidation for each collateral. For example, if a collateral has a liquidation threshold of 80%, it means that the position will be liquidated when the debt value is worth 80% of the collateral value.',
    warning_Text_Liquidation_penalty:
      'When a liquidation occurs, liquidators repay up to 50% of the outstanding borrowed amount on behalf of the borrower. In return, they can buy the collateral at a discount and keep the difference (liquidation penalty) as a bonus.',
    warning_Text_Reserve_factor:
      'Reserve factor is a percentage of interest which goes to a collector contract that is controlled by Thrupenny governance to promote ecosystem growth'
  },
  [LOCALES.CHINE]: {
    metamask: 'Metamask',
    trustWallet: 'Trust Wallet',
    coinBase: 'CoinBase',
    formatic: 'Formatic',
    walletConnect: 'WalletConnect',
    supplyBalance: 'Supply Balance',
    balance: '余额',
    max: '最大值',
    switch_type: 'Switch APY type',
    variable_rate: 'Variable Rate',
    stable_rate: 'Stable Rate',
    stable: 'Stable',
    borrow: '借贷',
    repay: '偿还',
    switch_rate_to_variable: 'You have switched to variable rate',
    switch_rate_to_stable: 'You have switched to stable rate',
    transaction_overview: '金额',
    approve_confirmed: '批准已确认',
    review_approval_tx_details: '查看批准交易明细',
    please_switch_to_ethereum: '请转换到以太网. （点击转换网络）',
    enter_an_amount: '输入金额',
    repay_dai: '偿还',
    borrow_api: '借贷年收益率',
    health_factor: '健康指数',
    dept_balance: 'Remaining debt',
    high_risk: '高风险',
    liqudation: '清算',
    claim_tpy: '认领TPY',
    claim: '认领',
    amount: '金额',
    tpy_per_month: '每月TPY数量',
    please_connect_your_wallet: '请连接您的钱包',
    connect_wallet: '连接钱包',
    switch_network: '交換網絡',
    select_your_preferred_wallet: '选择您需要连接的钱包',
    cooldown_to_unstake: '解除质押锁定期',
    the_cooldown_period_id: `一旦质押后，您的代币需要在锁定期（10天）后方可取出。您可在活跃的解押窗口内提交申请，从保险库提取您的资产。`,
    cooldown_period: '锁定期',
    unstake_window: '解除质押窗口',
    your_wallet_is_empty: '您钱包余额不足，请购买或转入资产',
    i_understand_how_cooldown: '已知晓锁定期（10天）和解锁（2天）的运作规则',
    activate_cooldown: '启用锁定期',
    overview: 'Overview',
    your_info: '您个人信息',
    supply_info: '供应信息',
    supply_apy: '供应年收益率',
    total_borrowed: '总借贷',
    borrowed: 'Borrowed',
    repaid: 'Repaid',
    borrow_apy_variable: '借贷年收益率；浮动',
    borrow_apy_stable: '借贷年收益率；稳定',
    total_supplied: '总供应量',
    distribution_apy: '年收益率分配',
    max_ltv: '最高借贷比例',
    liquidation_threshold: '清算比例',
    liquidation_penalty: '清算罚金',
    borrow_info: '借贷信息',
    borrow_apy: '借贷年收益率',
    total_borrow: '总借贷',
    collector_info: '借贷信息',
    reserve_factor: '储备系数',
    collector_contract: '收藏合约',
    view_contract: '查看合约',
    interest_rate_model: '利率',
    utilization_rate: '利用率',
    wallet_balance: '钱包余额',
    available_to_supply: '可供应',
    delegate_your_power: '委托您的投票权',
    asset_to_delegate: '委托的资产',
    staked_tpy: '已质押 TPY',
    type_of_delegation: 'Type of delegation',
    voting_power: '投票权',
    proposition_power: '提案权',
    delegate: '委托',
    review_tx: '查阅ETH交易',
    new_apy: '新年利率',
    switch_rate: '转换利率',
    support: '支持',
    governance_vote: '治理投票',
    dashboard: '仪表盘',
    market: '市场',
    stake: '质押',
    governance: '治理',
    menu: '菜单',
    language: '语言',
    dark_mode: '深色模式',
    more: '更多',
    buy_tpy: '买TPY',
    bridges_tpy: 'TPY桥',
    example: '实例',
    disabling_this_asset: '禁用这项资产作为抵押，将会影响您的借贷额度和健康指数',
    supply_balance: '供应余额',
    disable_coin_as_collateral: '禁止ETH作为抵押',
    stake_tpy: '质押 TPY',
    cooldown_period_warning: '锁定期提示',
    approve: 'Approve',
    add_a_token_to_wallet: 'Add a Token to wallet to track your balance.',
    add_to_wallet: 'Add to Wallet',
    review_tx_details: '查看批准交易明细',
    supply: '供应',
    withdraw: '提款',
    approve_to_continue: '批准并继续',
    supply_dai: '供应 DAI',
    withdraw_dai: '提款 DAI',
    borrow_limit: '借贷限额',
    borrow_balance: '借贷余额',
    collateralization: '借贷余额',
    success: '成功',
    variables: '变量',
    variable: 'Variable',
    see_charts: 'See Charts',
    select_APY_type_to_switch: 'Select APY type to switch',
    asset: '资产',
    total_suplied: '总供应',
    available: '可用',
    apy_variable: '借贷年收益率；浮动',
    apy_stable: '借贷年收益率；稳定',
    ethereum_market: '以太坊市场',
    your_supplies: '您的供应',
    net_apy: '年净收益率',
    collateral: '抵押',
    your_borrows: '您的借贷',
    nothing_supplied_yet: '您暂未供应',
    assets_to_supply: '供应的资产',
    assets_to_borrows: '借贷的资产',
    assets_to_supplies: '您的供应',
    of_limit: '限额比例',
    debt: '负债',
    proposal_overview: '提案概述',
    not_voted: '未投票',
    for: '赞成',
    against: '反对',
    votes: '投票',
    your_voting_info: '您的投票信息',
    you_did_not_participate_in_this_proposal: '您暂未参与任何提案投票',
    voting_results: '投票结果',
    state: '状态',
    quorum: '投票人数',
    reached: '已达到',
    current_votes: '当前投票数',
    required: '所需投票数',
    differential: '差额',
    ethereum_assets: '以太坊市场',
    markets: '市场',
    log_out: 'Log out',
    net_worth: '净值',
    on: '在',
    off: '关',
    total_market_size: '总市场规模',
    total_available: '总可用余额',
    total_borrows: '总借贷',
    staking: '质押',
    funds_in_the_safety_module: '保险库中的资金',
    total_emission_per_day: '日均总发行量',
    unstake_tpy: '钱包余额',
    staked: '已质押',
    earned: '已获得收益',
    apy: '年收益率',
    d10: '10天',
    snapshots: '快讯',
    forum: '论坛',
    faq: '常见问题解答',
    proposals: '提案',
    all_proposals: '所有提案',
    add_stMATIC_to_aave_pool_on_polygon: '将stMATIC添加到Polygon的Aave v3池中',
    executed: '已执行',
    defeated: '未通过',
    voting_weight: '投票权重',
    created: '已创建',
    active: '活跃',
    succeed: '已通过',
    queue: '排队中',
    execute: '已执行',
    addresses4: '4个钱包地址',
    addresses0: '0个钱包地址',
    executes_on_sep_17_2022: '已在 9.17.2022执行',
    current_diferential: '当前差额',
    total_voting_power: '总投票权',
    simple_summary: '简单概要',
    abstract: '摘要',
    motivation: '激励',
    specification: '规格',
    test_cases: '测试案例',
    implementation: '实施',
    dai_stablecoin: 'Dai 稳定币',
    reserve_size: '储备规模',
    available_liquidity: '可用流动资金',
    oracle_price: 'Oracle 价格',
    collateral_usage: '使用抵押',
    can_be_collateral: '可抵押',
    liquidation_at: '在 时将会进行清算',
    supply_balance_dai: '供应余额',
    current: '当前',
    borrow_apy_rate: '借贷年收益比率',
    official_website: '官网',
    twitter: '推特',
    whitepaper: '白皮书',
    youTube: 'YouTube',
    linkedIn: '领英',
    medium: 'Medium',
    warning_Text_Collateral:
      '您可以决定是否将您供应的资产作为抵押品。用作抵押的资产将影响您的借贷能力和健康指数。',
    warning_Text_Health_Factor:
      '健康指数“ - 您抵押品是否被清算取决于您的健康指数和借贷价值。一旦您的健康指数达到1.0，您的头寸将会被清算。为避免清算，您可以供应更多的资产作为抵押品或偿还借贷头寸',
    warning_Text_Net_APY:
      '年净收益率“ - 年净收益率是所有供应和借贷头寸对净值的综合影响，包含奖励。如借贷年收益率高于供应年收益率，则年净收益率为负数。',
    warning_Text_Borrow_APY_variable:
      '借贷年收益率，浮动” - 浮动利率将根据市场情况波动。推荐用于短期头寸。',
    warning_Text_Borrow_APY_stable:
      '借贷年收益率，稳定” - 稳定利率在您的贷款期限内保持不变。 推荐用于长期借贷和喜欢可预测性的用户。',
    warning_Text_Max_LTV:
      '最高借贷比例表示抵押品的最高借贷能力。例如：抵押品的最高借贷比例为75%，则用户每价值 1 ETH 抵押品最多可以借贷价值 0.75 ETH。',
    warning_Text_Liquidation_Threshold:
      '清算阈值“ - 是指借贷头寸被定义为抵押不足，每个抵押物面临被清算的门槛比率。例如，如果抵押品的清算阈值为80%，则意味着当借贷价值相当于抵押品价值的80%时，该头寸将被清算。',
    warning_Text_Liquidation_penalty:
      '清算罚金“ - 当发生清算时，清算人代表借贷人偿还了高达50%的未偿还借贷金额。作为回报，清算人不仅可以以折扣价购买抵押品而且将获得清算罚金作为奖励。',
    warning_Text_Reserve_factor:
      '储备系数是由Thrupenny治理控制的收集器合同的利息百分比，以促进生态系统的增长'
  }
};
