import processStepsSuccess from '../../images/icons/process-steps-success.svg';

import './styles.scss';

function ProcessSteps({ processData }) {
  return processData.map(({ text, date }, index) => (
    <div key={index} className="process-steps-box">
      <div className="process-steps-img">
        <img alt="/#" src={processStepsSuccess} />
        <h3>{text}</h3>
      </div>
      <h4>{date}</h4>
    </div>
  ));
}

export default ProcessSteps;
