const initialState = {
  selectedAddress: '',
  walletType: ''
};

const walletReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'CONNECT_WALLET':
      return {
        ...state,
        selectedAddress: payload.selectedAddress,
        walletType: payload.walletType
      };
    case 'DISCONNECT':
      return {
        ...state,
        selectedAddress: '',
        walletType: ''
      };
    default:
      return state;
  }
};

export default walletReducer;
