import { themes, getInitialTheme } from '../../contexts/ThemeContext';

import styles from './index.module.scss';

import darkWarning from '../../images/icons/dark-warning.svg';
import lightWarning from '../../images/icons/light-warning.svg';
import infinityIcon from '../../images/icons/infinity.svg';

const InfinityComponent = ({ text }) => {
  const str = text?.split(':');
  return (
    <span className={styles.infinity}>
      <span>{`${str[0]} : `}</span>
      <img src={infinityIcon} alt="..." />
    </span>
  );
};
const HealthComponent = ({ text, additionalClass }) => {
  const str = text?.split(':');
  return (
    <>
      <span>{`${str[0]} : `}</span>
      <span className={styles[additionalClass]}>{str[1]}</span>
    </>
  );
};

function WarningText({ text, warningText, className = '', additionalClass = '' }) {
  const themeState = getInitialTheme();
  const isLightMode = themeState === themes.light;
  return (
    <div className={`${styles.warningTextBox} ${className}`}>
      {text?.includes('Infinity') ? (
        <InfinityComponent text={text} />
      ) : (
        <p>
          {text?.includes('Health Factor') ? (
            <HealthComponent text={text} additionalClass={additionalClass} />
          ) : (
            text
          )}
        </p>
      )}
      <div className={styles.warningIcon}>
        <img src={isLightMode ? lightWarning : darkWarning} alt="#" />
        {warningText && <p>{warningText}</p>}
      </div>
    </div>
  );
}

export default WarningText;
