import styles from './index.module.scss';

const TextWithImage = ({ children, text, className = '' }) => {
  return (
    <div className={`${styles.textWithImage} ${className}`}>
      <span className={styles.childrenItem}>{children}</span>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default TextWithImage;
