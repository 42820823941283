import { useCallback, useEffect, useContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Header from './components/Header';
import { messages } from './lang/messages';
import Footer from './components/Footer';
import Market from './pages/Market/Market';
// import Staking from './pages/Staking/Staking';
import useWindowSize from './hooks/useWindowSize';
import Dashboard from './pages/Dashboard/Dashboard';
// import Governance from './pages/Governance/Governance';
import DashboardDetails from './components/DashboardDetails';
import DashboardSecond from './pages/DashboardSecond/DashboardSecond';
import ConnectedGoverance from './pages/ConnectedGoverance/ConnectedGoverance';

import { getContracts } from './services/Web3';
import { LOCALES } from './lang/locales';
import { ThemeContext } from './contexts/ThemeContext';
import {
  setLendingPoolContract,
  setPriceOracleContract,
  setUiPoolDataProiderContract,
  setWalletBalanceProviderContract
} from './redux/actions/contractsActions';

import './App.css';

import BackgroundImageLight from './images/background-light.png';
import BackgroundImageDark from './images/background-new.png';

function getInitialLocal() {
  const savedLocale = localStorage.getItem('locale');
  return savedLocale || LOCALES.ENGLISH;
}

function App() {
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) => state.wallet.selectedAddress);

  const { theme } = useContext(ThemeContext);

  const [currentLocale, setCurrentLocale] = useState(getInitialLocal());

  const { width } = useWindowSize();

  const handleChangeLanguage = useCallback((e) => {
    setCurrentLocale(e.target.value);
    localStorage.setItem('lang', e.target.value);
  }, []);

  const isDarkMode = theme === 'dark-mode';
  const isTabletSize = width <= 1150;

  useEffect(() => {
    (async () => {
      const contracts = await getContracts();
      dispatch(
        setLendingPoolContract({
          contract: contracts[0].lendingPoolContract,
          contractType: 'ADD_CONTRACT_LANDING_POOL'
        })
      );
      dispatch(
        setUiPoolDataProiderContract({
          contract: contracts[1].uIPoolDataProviderContract,
          contractType: 'ADD_CONTRACT_UI_POOL_DATA_PROVIDER'
        })
      );
      dispatch(
        setPriceOracleContract({
          contract: contracts[2].priceOracleContract,
          contractType: 'ADD_CONTRACT_PRICE_ORACLE'
        })
      );
      dispatch(
        setWalletBalanceProviderContract({
          contract: contracts[3].walletBalanceProviderContract,
          contractType: 'ADD_CONTRACT_WALLET_BALANCE_PROVIDER'
        })
      );
    })();
  }, [dispatch]);

  return (
    <IntlProvider
      messages={messages[currentLocale]}
      locale={currentLocale}
      defaultLocale={LOCALES.ENGLISH}>
      <div
        className="app container"
        style={{
          backgroundImage: `url("${isDarkMode ? BackgroundImageDark : BackgroundImageLight}")`
        }}>
        <BrowserRouter>
          <Header currentLocale={currentLocale} onChangeLanguage={handleChangeLanguage} />
          <Routes>
            <Route path="/" element={walletAddress ? <DashboardSecond /> : <Dashboard />} />
            <Route
              path="/dashboard"
              element={walletAddress ? <DashboardSecond /> : <Dashboard />}
            />
            <Route path="/dashboard-details" element={<DashboardDetails />} />
            {/* <Route path="/stake" element={<Staking />} /> */}
            <Route path="/market" element={<Market />} />
            {/* <Route path="/governance" element={<Governance />} /> */}
            <Route path="/governance-details" element={<ConnectedGoverance />} />
          </Routes>
          {!isTabletSize && <Footer isDarkMode={isDarkMode} />}
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
}

export default App;
