import styles from './index.module.css';

const TabItem = ({ text, isActive = false, onClick }) => {
  return (
    <div onClick={onClick} className={`${isActive ? styles.active : ''} ${styles['tab-item']}`}>
      {text}
    </div>
  );
};

export default TabItem;
