import { useState, useEffect } from 'react';
import Banner from '../Banner';
import TitleDescription from '../TitleDescription';
import ConnectWalletButton from '../ConnectWalletButton';
import { useIntl } from 'react-intl';

import dashboardWallet from '../../images/images/dashboard-wallet.png';

import styles from './connectWalletBanner.module.scss';

function ConnectWalletBanner() {
  const intl = useIntl();
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (intl.locale === 'en-US') {
      setShowText(true);
    } else {
      setShowText((preavState) => !preavState);
    }
  }, [intl.locale]);

  return (
    <Banner className={styles.connectWalletBannerBox}>
      <img src={dashboardWallet} alt="#" />
      <TitleDescription
        textAlign="center"
        title={intl.messages.please_connect_your_wallet}
        description={''}
      />
      <ConnectWalletButton />
    </Banner>
  );
}

export default ConnectWalletBanner;
