import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import WarningBanner from '../WarningBanner';
import TransactionBox from '../TransactionBox';
import TextWithWarningIcon from '../TextWithWarningIcon';
import ButtonWithLoader from '../buttons/ButtonWithLoader';
import ModalWithBackdrop from '../Modals/ModalWithBackdrop';
import ModalContentWrapperWithClose from '../Modals/ModalContentWrapperWithClose';
import { setSuccessModalData } from '../../redux/actions/reserveActions';
import styles from './index.module.css';
import { calculateHealthFactorOnAction } from '../../utilitis';
import orangeWarning from '../../images/icons/orange-warning.svg';
import { disableOrEnableCollateral } from '../../services/Web3';
import { infinityNumber, ten } from '../../constants/numbers';
import { convertToNumber } from '../../utilitis';
import { TABS } from '../../constants/tabs';
import BigNumber from 'bignumber.js/bignumber';
import { tenPow18 } from '../../constants/numbers';

const ReviewCoinModal = ({
  isOpen = false,
  setIsOpen,
  getUserInfo,
  walletAddress,
  reviewCoinData,
  setIsToggleSuccessPopup
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [healthFactor, setHealthFactor] = useState({});

  const userReservesData = useSelector((state) => state.reserveReducer.userReservesData);

  const dismissModal = () => {
    setIsOpen((prevState) => {
      return {
        ...prevState,
        isOpenReviewCoinModal: !prevState.isOpenReviewCoinModal
      };
    });
  };

  const handleReview = async () => {
    setIsToggleSuccessPopup({
      isSuccessPopupModalOpen: false,
      showAddToWalletSection: false
    });
    setIsLoading(true);
    try {
      const result = await disableOrEnableCollateral(
        walletAddress,
        window.ethereum,
        reviewCoinData,
        !reviewCoinData.usageAsCollateralEnabledOnUser
      );
      dispatch(
        setSuccessModalData({
          data: {
            txHeash: result.transactionHash,
            rate: null,
            symbol: reviewCoinData.symbol,
            disableCollateral: reviewCoinData.usageAsCollateralEnabledOnUser
          }
        })
      );
      await getUserInfo();
      dismissModal();
      setIsToggleSuccessPopup({
        isSuccessPopupModalOpen: true,
        showAddToWalletSection: false
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateHealthFactorChangeOnSupply = (amountToSupply) => {
    const beforeHealthFactor = userReservesData.healthFactor;
    const afterHealthFactor = calculateHealthFactorOnAction(
      userReservesData,
      reviewCoinData,
      reviewCoinData.underlyingAsset,
      reviewCoinData.usageAsCollateralEnabledOnUser ? TABS.withdraw : TABS.supply,
      amountToSupply.dividedBy(new BigNumber(ten).pow(reviewCoinData.decimals))
    );
    return {
      beforeHealthFactor,
      afterHealthFactor
    };
  };

  useEffect(() => {
    if (reviewCoinData && Object.keys(reviewCoinData).length) {
      const token = userReservesData?.userInfosByTokenAddress[reviewCoinData.underlyingAsset];
      const healthFactorData = calculateHealthFactorChangeOnSupply(token.aTokenBal);
      setHealthFactor(healthFactorData);
    }
  }, [reviewCoinData]);

  return (
    <ModalWithBackdrop isOpen={isOpen} onBackDropClick={dismissModal}>
      <ModalContentWrapperWithClose className={styles['review-coin-modal']} onClose={dismissModal}>
        <p className={styles.title}>{`Review tx ${reviewCoinData.symbol}`}</p>
        {reviewCoinData.usageAsCollateralEnabledOnUser && (
          <WarningBanner
            className={styles['warning-banner']}
            imgSrc={orangeWarning}
            type="default"
            title={intl.messages.disabling_this_asset}
          />
        )}
        <TextWithWarningIcon
          className={styles.subtitle}
          text={intl.messages.transaction_overview}
          warning={false}
        />
        <TransactionBox
          supplyTitle={intl.messages.supply_balance}
          className={styles['transaction-box']}
          supplyPercent={`${convertToNumber(reviewCoinData.supplyBalance)} ${
            reviewCoinData.symbol
          }`}
          healthFactorPrice={
            convertToNumber(healthFactor.afterHealthFactor) < infinityNumber
              ? convertToNumber(healthFactor.afterHealthFactor)
              : 'Infinity'
          }
          liquidationAt="1.0"
          isShowCollateralization={false}
          from={
            convertToNumber(healthFactor.beforeHealthFactor) < infinityNumber
              ? convertToNumber(healthFactor.beforeHealthFactor)
              : 'Infinity'
          }
        />
        <ButtonWithLoader
          className={styles.button}
          isLoading={isLoading}
          onClick={handleReview}
          disabled={
            reviewCoinData.usageAsCollateralEnabledOnUser &&
            tenPow18.multipliedBy(1.1).gt(healthFactor.afterHealthFactor)
          }>
          {`${reviewCoinData.usageAsCollateralEnabledOnUser ? 'Disable' : 'Enable'} ${
            reviewCoinData.symbol
          } as Collateral`}
        </ButtonWithLoader>
      </ModalContentWrapperWithClose>
    </ModalWithBackdrop>
  );
};

export default ReviewCoinModal;
