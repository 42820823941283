import styles from './index.module.scss';

function GenericTitle({ title, imageUrl }) {
  return (
    <div className={styles.titleBox}>
      {imageUrl && <img src={imageUrl} alt="#" />}
      <h1>{title}</h1>
    </div>
  );
}

export default GenericTitle;
