import { useState, useContext, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { themes, ThemeContext } from '../../contexts/ThemeContext';
import TPYInnerTitlesDescription from '../TPYInnerTitlesDescription';

import chartIcon from '../../images/icons/chart-icon.svg';
import successArrow from '../../images/icons/success-arrow.svg';
import arrowDropdown from '../../images/icons/arrow-dropdown.svg';
import arrowDropdownDark from '../../images/icons/arrow-dropdown-dark.svg';

import styles from './tPYSelectDropdown.module.scss';

function VariableDropdown({ dropdownSelectData, headerText, changeMode, address, disable }) {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === themes.light;
  const dropdownRef = useRef(null);
  const intl = useIntl();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const selectItem = (item) => {
    setIsOpenModal(false);
    changeMode({
      tokenAddr: address,
      variant: item.index,
      percent: item.value
    });
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpenModal && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpenModal(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpenModal]);
  const openDropdown = () => {
    if (disable) return;
    setIsOpenModal((prevState) => !prevState);
  };
  return (
    <div className={styles.dropdownBox} ref={dropdownRef}>
      <div className={styles.selectDropdown} onClick={openDropdown}>
        <p>{headerText ? headerText : intl.messages.variable}</p>
        {disable ? null : <img src={isLightMode ? arrowDropdownDark : arrowDropdown} alt="/#" />}
      </div>
      <div className={`${styles.dropdownModal} ${isOpenModal ? styles.showDropdownModal : ''}`}>
        <div className={styles.dropDownTitles}>
          <p>{intl.messages.select_APY_type_to_switch}</p>
        </div>
        <div className={styles.selectTextBox}>
          {dropdownSelectData.map((item, index) => (
            <div
              key={item.index}
              className={styles.selectTextBoxItem}
              onClick={() =>
                headerText && index + 1 === 1
                  ? setIsOpenModal(false)
                  : !headerText && index + 1 === 2
                  ? setIsOpenModal(false)
                  : selectItem(item)
              }>
              <div className={styles.successSelectItem}>
                <div className={styles.successImg}>
                  <img
                    alt="/#"
                    src={successArrow}
                    className={
                      headerText && index + 1 === 1
                        ? styles.showImg
                        : !headerText && index + 1 === 2
                        ? styles.showImg
                        : styles.hideImg
                    }
                  />
                </div>
                <TPYInnerTitlesDescription
                  description={item.text}
                  className={styles.dropdownText}
                />
              </div>
              <TPYInnerTitlesDescription description={item.value} className={styles.dropdownText} />
            </div>
          ))}
        </div>
        <div className={styles.dropDownTitles}>
          <p>{intl.messages.see_charts}</p>
          <img src={chartIcon} alt="/#" />
        </div>
      </div>
    </div>
  );
}

export default VariableDropdown;
