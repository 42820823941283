import styles from './index.module.scss';

function InnerBlocks({ title = '', subTitle = '--', className = '' }) {
  return (
    <div className={`${styles.innerBlocksBox} ${className}`}>
      <h3>{title}</h3>
      <h4>{subTitle}</h4>
    </div>
  );
}

export default InnerBlocks;
