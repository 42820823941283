import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js/bignumber';

import Banner from '../../components/Banner';
import getToken from '../../utilitis/getToken';
import Section from '../../components/Section';
import TPYTable from '../../components/TPYTable';
import useWindowSize from '../../hooks/useWindowSize';
import WarningText from '../../components/WarningText';
import SelectCoins from '../../components/SelectCoins';
import InnerBlocks from '../../components/InnerBlocks';
import GenericTitle from '../../components/GenericTitle';
import TitleDescription from '../../components/TitleDescription';
import getTotalSupplied from '../../utilitis/getTotalSupplied';
import getTotalBorrowed from '../../utilitis/getTotalBorrowed';
import getTotalBorrowedInUsd from '../../utilitis/getTotalBorrowedInUsd';
import getTotalSuppliedInUsd from '../../utilitis/getTotalSuppliedInUsd';
import TPYInnerTitlesDescription from '../../components/TPYInnerTitlesDescription';

import { nFormatter } from '../../utilitis/math';
import { getReserveData, getUserReservesData } from '../../services/Web3';
import { setReservesData, setUserReservesData } from '../../redux/actions/reserveActions';

import ethereum from '../../images/coins/ethereum.svg';

import styles from './market.module.scss';
import { WETH_ADDRESS } from '../../constants/tokens';

function Market() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const walletAddress = useSelector((state) => state.wallet.selectedAddress);
  const lendingPoolContract = useSelector((state) => state.contractsReducer.lendingPoolContract);
  const uIPoolDataProviderContract = useSelector(
    (state) => state.contractsReducer.uIPoolDataProiderContract
  );
  const priceOracleContract = useSelector((state) => state.contractsReducer.priceOracleContract);
  const walletBalanceProviderContract = useSelector(
    (state) => state.contractsReducer.walletBalanceProviderContract
  );
  const userReservesData = useSelector((state) => state.reserveReducer.userReservesData);
  const supplyTokenData = useSelector((state) => state.reserveReducer.reservesData);

  const navigate = useNavigate();

  const { width } = useWindowSize();

  function handleRowClick(item) {
    navigate('/dashboard-details', { state: { asset: item[0] } });
  }

  const TABLE_HEAD = [
    { text: intl.messages.asset },
    { text: intl.messages.total_suplied },
    { text: intl.messages.supply_apy },
    { text: intl.messages.total_borrowed },
    {
      text: (
        <WarningText
          text={intl.messages.borrow_apy_variable}
          className={styles.firstTableTitle}
          warningText={intl.messages.warning_Text_Borrow_APY_variable}
        />
      )
    }
  ];

  const getData = async () => {
    const reservData = await getReserveData(
      uIPoolDataProviderContract,
      walletBalanceProviderContract,
      priceOracleContract,
      walletAddress,
      window.ethereum,
      lendingPoolContract
    );

    const userReservesData = await getUserReservesData(
      uIPoolDataProviderContract,
      walletBalanceProviderContract,
      priceOracleContract,
      walletAddress,
      reservData,
      window.ethereum
    );
    // const response = await createDatabase();
    // console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb', response);
    // addUserReserveData(userReservesData);
    // const res = await getUserReserveData("0xfb0b3506dfc4200a5496a767e43ae30b07291e1c");

    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', res);
    dispatch(setUserReservesData({ data: userReservesData }));
    dispatch(setReservesData({ data: reservData }));
  };

  const getBodyData = () => {
    if (!Object.keys(supplyTokenData || {}).length) {
      return [[]];
    }
    const bodyData = [];
    let totalMarketSize = BigNumber(0);
    let totalBorrowSize = BigNumber(0);
    Object.values(supplyTokenData).forEach((token) => {
      // if (token.underlyingAsset === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") return;
      if (token.underlyingAsset === WETH_ADDRESS) return;
      const totalSupplied = getTotalSupplied(token.aTokenTotal, token.decimals);
      const totalSuppliedInUsd = getTotalSuppliedInUsd(token);
      const totalBorrowed = getTotalBorrowed(
        token.variableDebtTokenTotal,
        token.stableDebtTokenTotal,
        token.decimals
      );
      const totalBorrowedInUsd = getTotalBorrowedInUsd(token);
      totalMarketSize = totalMarketSize.plus(totalSuppliedInUsd);
      totalBorrowSize = totalBorrowSize.plus(totalBorrowedInUsd);

      bodyData.push([
        {
          imgSrc: getToken(token.symbol).image,
          text: token.symbol,
          description: token.symbol,
          button: null,
          totalSupplied: totalSupplied.toString(),
          totalSuppliedInUsd: totalSuppliedInUsd.toString(),
          totalBorrowed: totalBorrowed.toString(),
          totalBorrowedInUsd: totalBorrowedInUsd.toString(),
          priceInMarketReferenceCurrency: token.priceInMarketReferenceCurrency,
          networkBaseTokenPriceInUsd: token.networkBaseTokenPriceInUsd,
          availableSupplyInEth: userReservesData.supplyInETH.toString(),
          availableBorrowInEth: userReservesData.availableBorrowInETH.toString(),
          variableBorrowAPY: token.variableBorrowAPY,
          stableBorrowAPY: token.stableBorrowAPY,
          supplyAPY: token.supplyAPY,
          baseLTVasCollateral: token.baseLTVasCollateral,
          reserveLiquidationThreshold: token.reserveLiquidationThreshold,
          reserveFactor: token.reserveFactor,
          reserveLiquidationBonus: token.reserveLiquidationBonus,
          allowance: token.allowance,
          decimals: token.decimals,
          underlyingAsset: token.underlyingAsset,
          symbol: token.symbol,
          balance: token.balance,
          stableDebtTokenAddress: token.stableDebtTokenAddress,
          variableDebtTokenAddress: token.variableDebtTokenAddress,
          realBalance: token.realBalance
        },
        {
          imgSrc: '',
          text: nFormatter(totalSupplied, 2) < 0.01 ? '< 0.01' : nFormatter(totalSupplied, 2),
          description: `$ ${nFormatter(totalSuppliedInUsd, 2)}`,
          button: null,
          token: token
        },
        { imgSrc: '', text: `${token.supplyAPY.toFixed(2)} %`, description: '', button: null },
        {
          imgSrc: '',
          text: nFormatter(totalBorrowed, 2),
          description: `$ ${nFormatter(totalBorrowedInUsd?.toString(), 2)}`,
          button: null,
          token: token
        },
        {
          imgSrc: '',
          text: `${token.variableBorrowAPY.toFixed(2)} %`,
          description: '',
          button: null,
          token: token
        }
      ]);
    });

    const totalMarketSizeData = {
      marketSize: totalMarketSize?.toString(),
      borrowSize: totalBorrowSize?.toString(),
      totalAvilable: totalMarketSize.minus(totalBorrowSize)?.toString()
    };
    const isCheckTotalMarketSizeData = localStorage.getItem('totalMarketSizeData');
    if (isCheckTotalMarketSizeData) {
      localStorage.removeItem('totalMarketSizeData');
      localStorage.setItem('totalMarketSizeData', JSON.stringify(totalMarketSizeData));
    } else {
      localStorage.setItem('totalMarketSizeData', JSON.stringify(totalMarketSizeData));
    }
    // bodyData.shift();
    return bodyData;
  };

  const getTotalMarketSizeCurrentData = () => {
    return (
      JSON.parse(localStorage.getItem('totalMarketSizeData')) || {
        marketSize: 0,
        totalAvilable: 0,
        borrowSize: 0
      }
    );
  };

  useEffect(() => {
    if (
      lendingPoolContract?.methods !== undefined &&
      uIPoolDataProviderContract?.methods !== undefined &&
      !Object.keys(supplyTokenData || {}).length
    ) {
      getData();
    }
  }, [lendingPoolContract, uIPoolDataProviderContract, priceOracleContract]);

  return width > 768 ? (
    <Section className={styles.marketContainer}>
      <GenericTitle title={intl.messages.markets} />
      <Banner className={styles.firstBanner}>
        <SelectCoins title={intl.messages.ethereum_market} imgUrl={ethereum} />
        <div className={styles.marketInnerBlocks}>
          <InnerBlocks
            title={intl.messages.total_market_size}
            subTitle={`$ ${nFormatter(getTotalMarketSizeCurrentData().marketSize, 2)}`}
          />
          <InnerBlocks
            title={intl.messages.total_available}
            subTitle={`$ ${nFormatter(getTotalMarketSizeCurrentData().totalAvilable, 2)}`}
          />
          <InnerBlocks
            title={intl.messages.total_borrows}
            subTitle={`$ ${nFormatter(getTotalMarketSizeCurrentData().borrowSize, 2)}`}
          />
        </div>
      </Banner>
      <Banner className={styles.secondBanner}>
        <TitleDescription
          title={intl.messages.ethereum_assets}
          className={styles.marketBannerTitle}
        />
        <TPYTable
          isSort={true}
          headerData={TABLE_HEAD}
          bodyData={getBodyData()}
          onRowClick={handleRowClick}
        />
      </Banner>
    </Section>
  ) : (
    <Section className={styles.marketContainer}>
      <GenericTitle title={intl.messages.markets} />
      <Banner className={`${styles.bannersItem}`}>
        <SelectCoins title={intl.messages.ethereum_market} imgUrl={ethereum} />
        <div className={styles.bannersInnerBlocksBox}>
          <InnerBlocks
            className={styles.innerBlocks}
            title={intl.messages.supply_balance}
            subTitle={`$ ${nFormatter(getTotalMarketSizeCurrentData()?.marketSize, 2)}`}
          />
          <InnerBlocks
            className={styles.innerBlocks}
            title={intl.messages.collateral}
            subTitle={`$ ${nFormatter(getTotalMarketSizeCurrentData()?.totalAvilable, 2)}`}
          />
          <InnerBlocks
            className={styles.innerBlocks}
            title={intl.messages.collateralization}
            subTitle={`$ ${nFormatter(getTotalMarketSizeCurrentData()?.borrowSize, 2)}`}
          />
        </div>
      </Banner>
      <Banner className={styles.bannersItem}>
        <TitleDescription title={intl.messages.ethereum_assets} className={styles.bannerTitle} />
        <span className={styles.emptyRow} />
        {getBodyData().length > 1 &&
          getBodyData().map((token, index) => {
            const {
              text,
              imgSrc,
              supplyAPY,
              totalBorrowed,
              totalSupplied,
              stableBorrowAPY,
              variableBorrowAPY,
              totalSuppliedInUsd,
              totalBorrowedInUsd
            } = token['0'];
            return (
              <div key={index} onClick={() => handleRowClick(token)} className={styles.valod}>
                <div className={styles.secondBannerBox}>
                  <div className={styles.coinsInfoBox}>
                    <div className={styles.coinTitle}>
                      <img alt="#" src={imgSrc} />
                      <TPYInnerTitlesDescription title={text} description={text} />
                    </div>
                    <div className={styles.coinsInfoAmountBox}>
                      <div className={styles.coinsInfoAmount}>
                        <TPYInnerTitlesDescription description={intl.messages.total_suplied} />
                        <TPYInnerTitlesDescription
                          title={`$ ${nFormatter(totalSupplied, 2)}`}
                          description={`$ ${nFormatter(totalSuppliedInUsd, 2)}`}
                        />
                      </div>
                      <div className={styles.coinsInfoAmount}>
                        <TPYInnerTitlesDescription description={intl.messages.supply_apy} />
                        <TPYInnerTitlesDescription title={`${supplyAPY.toFixed(2)} %`} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.secondBannerBox}>
                  <div className={styles.coinsInfoBox}>
                    <div className={styles.coinsInfoAmountDescription}>
                      <TPYInnerTitlesDescription description={intl.messages.total_borrowed} />
                      <TPYInnerTitlesDescription
                        title={`$ ${nFormatter(totalBorrowed, 2)}`}
                        description={`$ ${nFormatter(totalBorrowedInUsd?.toString(), 2)}`}
                      />
                    </div>
                    <div className={styles.coinsInfoAmountDescription}>
                      <WarningText
                        className={styles.warningText}
                        text={intl.messages.borrow_apy_variable}
                      />
                      <TPYInnerTitlesDescription title={`${nFormatter(variableBorrowAPY, 2)} %`} />
                    </div>
                    <div className={styles.coinsInfoAmountDescription}>
                      <WarningText
                        className={styles.warningText}
                        text={intl.messages.borrow_apy_stable}
                      />
                      <TPYInnerTitlesDescription title={`${nFormatter(stableBorrowAPY, 2)} %`} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Banner>
    </Section>
  );
}

export default Market;
