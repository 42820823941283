import './index.scss';

const ExtensionWalletButton = ({ imgSrc, text, onClick }) => {
  return (
    <div onClick={onClick} className="TPY-extension-wallet-button">
      <img src={imgSrc} alt="" />
      <p>{text}</p>
    </div>
  );
};

export default ExtensionWalletButton;
