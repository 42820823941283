import BackDrop from '../BackDrop';
import './index.scss';

const PopupWithBackdrop = ({ children, onClose, className = '' }) => {
  return (
    <>
      <BackDrop onClick={onClose} className={`backdrop ${className}`} />
      <div className="popup-content">{children}</div>
    </>
  );
};

export default PopupWithBackdrop;
