import Fortmatic from 'fortmatic';
import Web3 from 'web3';

export const FormtaicConnect = () => {
  const fm = new Fortmatic('pk_live_352057474B5479EC');

  // test key pk_test_E81DD59DC7D2FC0C
  // prod key pk_live_352057474B5479EC

  window.web3 = new Web3(fm.getProvider());
  window.web3.currentProvider.enable();
  window.web3.eth.getAccounts((error, accounts) => {
    if (error) {
      return '';
    }
    return accounts?.[0] || '';
  });
};
