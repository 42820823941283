import BigNumber from 'bignumber.js';
import { ten } from '../constants/numbers';

const convertToNumber = (number, decimals = 18, fixed = true) => {
  const value = new BigNumber(number).div(new BigNumber(ten).pow(decimals));
  if (!fixed) {
    return value.toFixed(Number(decimals.toString()));
  }
  return value.toFixed(2);
};

export default convertToNumber;
