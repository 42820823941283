import TButton from '../buttons/TPYButton';
import { useEffect, useState } from 'react';
import ModalWithBackdrop from '../Modals/ModalWithBackdrop';
import ModalContentWrapperWithClose from '../Modals/ModalContentWrapperWithClose';
import ExtensionWalletButton from '../ExtensionWalletButton';
import { MetamaskConnect } from '../../services/MetamaskConnect';
import { WalletConnect } from '../../services/WalletConnect';
import { CoinbaseConnect } from '../../services/CoinbaseConnect';
import { FormtaicConnect } from '../../services/FormtaicConnect';
import WalletIcon from '../WalletIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setConnectWallet, setDisconnectWallet } from '../../redux/actions/walletAction';
import DisconnectWalletModal from '../DisconnectWalletModal';
import { useIntl } from 'react-intl';

import './index.scss';

import Metamask from '../../images/metamask.svg';
import WalletConnectImage from '../../images/wallet-connect.svg';
import CoinBase from '../../images/coinbase.svg';
import Formatic from '../../images/formatic.svg';
import TrustWallet from '../../images/trust-wallet.svg';
import TPYTooltipWrapper from '../TPYTooltipWrapper';
import networks from '../../constants/networks';

const WALLET_TYPES = {
  metamask: 'metamask',
  trustWallet: 'trust-wallet',
  coinBase: 'coinbase',
  formatic: 'formatic',
  walletConnect: 'wallet-connect'
};

const ConnectWalletButton = ({ onClick, disabled, ...defaultButtonProps }) => {
  const intl = useIntl();
  const [switchNetwork, setSwitchNetwork] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);

  const walletAddress = useSelector((state) => state.wallet.selectedAddress);
  const walletType = useSelector((state) => state.wallet.walletType);

  const dispatch = useDispatch();

  useEffect(() => {
    const address = localStorage.getItem('connected_address');
    const walletType = localStorage.getItem('wallet_type');
    if (!address) {
      return;
    }
    dispatch(setConnectWallet({ selectedAddress: address, walletType }));
  }, [dispatch]);

  function handleClose() {
    setIsOpen(false);
  }

  const handleConnectMetamask = async () => {
    const address = await MetamaskConnect();
    if (address) {
      dispatch(setConnectWallet({ selectedAddress: address, walletType: WALLET_TYPES.metamask }));
      localStorage.setItem('connected_address', address);
      localStorage.setItem('wallet_type', WALLET_TYPES.metamask);
      handleClick();
    }
  };

  const handleWalletConnect = async () => {
    const address = await WalletConnect();
    if (address) {
      dispatch(
        setConnectWallet({ selectedAddress: address, walletType: WALLET_TYPES.walletConnect })
      );
      localStorage.setItem('connected_address', address);
      localStorage.setItem('wallet_type', WALLET_TYPES.walletConnect);
      handleClick();
    }
  };

  const handleConnectCoinbase = async () => {
    const address = await CoinbaseConnect();
    if (address) {
      dispatch(setConnectWallet({ selectedAddress: address, walletType: WALLET_TYPES.coinBase }));
      localStorage.setItem('connected_address', address);
      localStorage.setItem('wallet_type', WALLET_TYPES.coinBase);

      handleClick();
    }
  };

  const handleFormtaicConnect = async () => {
    const address = await FormtaicConnect();
    if (address) {
      dispatch(setConnectWallet({ selectedAddress: address, walletType: WALLET_TYPES.formatic }));
      localStorage.setItem('connected_address', address);
      localStorage.setItem('wallet_type', WALLET_TYPES.formatic);
      handleClick();
    }
  };

  function toggleDisconnectModal() {
    setIsDisconnectModalOpen((prevState) => !prevState);
  }

  function handleDisconnectClick() {
    dispatch(setDisconnectWallet());
    localStorage.removeItem('connected_address');
    localStorage.removeItem('wallet_type');
    toggleDisconnectModal();
  }

  function getConnectedWalletImgSrc(type) {
    switch (type) {
      case WALLET_TYPES.metamask:
        return Metamask;
      case WALLET_TYPES.trustWallet:
        return TrustWallet;
      case WALLET_TYPES.walletConnect:
        return WalletConnectImage;
      case WALLET_TYPES.coinBase:
        return CoinBase;
      case WALLET_TYPES.formatic:
        return Formatic;
      default:
        return '';
    }
  }
  const checkNetwork = async () => {
    const currentChainId = await window.ethereum.request({
      method: 'eth_chainId'
    });
    if (currentChainId !== networks.mainnet.chainId) {
      setSwitchNetwork(false);
    }
    return currentChainId === networks.mainnet.chainId;
  };
  const handleChainChanged = async () => {
    if (await checkNetwork()) {
      return;
    }

    await window?.ethereum?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networks.mainnet.chainId }]
    });
    window.location.reload();
  };
  async function handleClick() {
    if (!switchNetwork) {
      await handleChainChanged();
      return;
    }
    setIsOpen((isOpen) => !isOpen);
  }
  useEffect(() => {
    if (window?.ethereum) {
      window?.ethereum?.on('chainChanged', handleChainChanged);
      handleChainChanged();
    }

    return () => {
      window?.ethereum?.on('chainChanged', handleChainChanged);
    };
  }, []);

  return (
    <>
      <TButton
        onClick={walletAddress && switchNetwork ? toggleDisconnectModal : handleClick}
        disabled={disabled}
        className="connect-wallet-button"
        {...defaultButtonProps}>
        <WalletIcon />
        <TPYTooltipWrapper
          tooltipText={
            switchNetwork
              ? walletAddress || intl.messages.connect_wallet
              : intl.messages.switch_network
          }>
          <span>
            {switchNetwork
              ? walletAddress || intl.messages.connect_wallet
              : intl.messages.switch_network}
          </span>
        </TPYTooltipWrapper>
      </TButton>
      <DisconnectWalletModal
        isOpen={isDisconnectModalOpen}
        address={walletAddress}
        onClose={toggleDisconnectModal}
        onDisconnectClick={handleDisconnectClick}
        walletType={intl.messages[walletType]}
        connectedWalletImgSrc={getConnectedWalletImgSrc(walletType)}
      />
      <ModalWithBackdrop isOpen={isOpen} onBackDropClick={handleClose}>
        <ModalContentWrapperWithClose
          className="TPY-connect-wallet-modal-wrapper"
          onClose={handleClose}>
          <div className="TPY-connect-wallet-modal">
            <h1 className="modal-title">{intl.messages.connect_wallet}</h1>
            <p className="modal-description">{intl.messages.select_your_preferred_wallet}</p>
            <ExtensionWalletButton
              onClick={handleConnectMetamask}
              imgSrc={Metamask}
              text={intl.messages.metamask}
            />
            <ExtensionWalletButton
              onClick={handleWalletConnect}
              imgSrc={TrustWallet}
              text={intl.messages.trustWallet}
            />
            <ExtensionWalletButton
              imgSrc={WalletConnectImage}
              onClick={handleWalletConnect}
              text={intl.messages.walletConnect}
            />
            <ExtensionWalletButton
              onClick={handleConnectCoinbase}
              imgSrc={CoinBase}
              text={intl.messages.coinBase}
            />
            <ExtensionWalletButton
              onClick={handleFormtaicConnect}
              imgSrc={Formatic}
              text={intl.messages.formatic}
            />
          </div>
        </ModalContentWrapperWithClose>
      </ModalWithBackdrop>
    </>
  );
};

export default ConnectWalletButton;
