import { createContext } from 'react';

export const themes = {
  dark: 'dark-mode',
  light: 'light-mode'
};

export function getInitialTheme() {
  const theme = localStorage.getItem('theme');
  return theme || themes.dark;
}

export const ThemeContext = createContext({
  theme: themes.dark,
  changeTheme: () => {}
});
