import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Tabs from '../Tabs';
import TabItem from '../TabItem';
import AmountBox from '../AmountBox';
import AmountFromTo from '../AmountFromTo';
import TextWithImage from '../TextWithImage';
import TransactionBox from '../TransactionBox';
import TextWithWarningIcon from '../TextWithWarningIcon';
import ButtonWithLoader from '../buttons/ButtonWithLoader';
import ModalWithBackdrop from '../Modals/ModalWithBackdrop';
import ModalContentWrapperWithClose from '../Modals/ModalContentWrapperWithClose';

import './index.scss';

import { approveAllowance, supplyToken, doAWithdraw } from '../../services/Web3';
import { calculateHealthFactorOnAction, convertToNumber, getToken } from '../../utilitis';
import BigNumber from 'bignumber.js';
import { infinityMinusNumber, infinityNumber, maxUint, ten } from '../../constants/numbers';
import TextWithShareIcon from '../TextWithShareIcon';
import { setSuccessModalData } from '../../redux/actions/reserveActions';
import { availableWithdrawByToken } from '../../utilitis/available';
import { ETH_ADDRESS, WETH_ADDRESS } from '../../constants/tokens';
import { contractAddresses } from '../../constants/contracts';

const TABS = {
  supply: 'supply',
  withdraw: 'withdraw'
};

const SupplyWithdrawModal = ({
  isOpen = false,
  onClose,
  tabData,
  initialActiveTab = TABS.supply,
  getUserInfo,
  setIsToggleSuccessPopup
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) => state.wallet.selectedAddress);
  const userReservesData = useSelector((state) => state.reserveReducer.userReservesData);
  const priceOracleContract = useSelector((state) => state.contractsReducer.priceOracleContract);
  const walletBalanceProviderContract = useSelector(
    (state) => state.contractsReducer.walletBalanceProviderContract
  );
  const lendingPoolContract = useSelector((state) => state.contractsReducer.lendingPoolContract);
  const [amountValue, setAmountValue] = useState();
  const [currentAmountValue, setCurrentAmountValue] = useState(0);
  const [beforeAfterBalance, setBeforeAfterBalance] = useState({});
  const [healthFactor, setHealthFactor] = useState({});
  const [activeTab, setActiveTab] = useState(TABS.supply);
  const [currentTabData, setCurrentTabData] = useState();
  const [isETH, setIsETH] = useState(false);
  const [withdrawBalance, setWithdrawBalance] = useState(0);
  const [approved, setApproved] = useState(false);
  const [isToggleModal, setIsToggleModal] = useState(false);
  const [isLoadingSupplyDai, setIsLoadingSupplyDai] = useState(false);
  const [isLoadingApproveToContinue, setIsLoadingApproveToContinue] = useState(false);
  const dismissModal = () => setIsToggleModal(false);
  const handleSupplyDai = async () => {
    setIsToggleSuccessPopup({
      isSuccessPopupModalOpen: false,
      showAddToWalletSection: true
    });
    const isCheckAllowance = currentTabData?.allowance <= 0;
    if (!isETH && isCheckAllowance) return;
    setIsLoadingSupplyDai({
      isSuccessPopupModalOpen: true,
      showAddToWalletSection: true
    });
    try {
      const result = await supplyToken(
        isETH,
        window.ethereum,
        amountValue,
        walletAddress,
        currentTabData?.underlyingAsset,
        tabData
      );
      dispatch(
        setSuccessModalData({
          data: {
            txHeash: result.transactionHash,
            type: activeTab,
            amount: amountValue,
            tokenAddress: tabData?.aTokenAddress,
            image: getToken(tabData?.symbol)?.image,
            symbol: tabData?.symbol
          }
        })
      );
      getUserInfo();
      dismissModal();
      setIsToggleSuccessPopup({
        isSuccessPopupModalOpen: true,
        showAddToWalletSection: true
      });
    } catch (e) {
      console.log(e);
    }

    setIsLoadingSupplyDai(false);
  };
  const handleWithdrawToken = async () => {
    setIsToggleSuccessPopup({
      isSuccessPopupModalOpen: false,
      showAddToWalletSection: true
    });
    setIsLoadingSupplyDai(true);
    try {
      const result = await doAWithdraw(
        isETH,
        window.ethereum,
        walletAddress,
        currentTabData?.underlyingAsset,
        tabData,
        amountValue
      );
      dispatch(
        setSuccessModalData({
          data: {
            txHeash: result.transactionHash,
            type: activeTab,
            amount: currentAmountValue,
            tokenAddress: tabData?.aTokenAddress,
            image: getToken(tabData?.symbol)?.image,
            symbol: tabData?.symbol
          }
        })
      );
      await getUserInfo();
      dismissModal();
      setIsToggleSuccessPopup({
        isSuccessPopupModalOpen: true,
        showAddToWalletSection: true
      });
    } catch (e) {
      console.log(e);
    }
    setIsLoadingSupplyDai(false);
  };
  const handleApproveToContinue = async () => {
    const isCheckAllowance =
      +convertToNumber(
        isETH ? currentTabData?.allowanceEth : currentTabData?.allowance,
        tabData?.decimals
      ) > currentAmountValue;
    if (!isETH && isCheckAllowance) return;
    setIsLoadingApproveToContinue(true);
    try {
      const result = await approveAllowance(
        window.ethereum,
        walletAddress,
        isETH ? 'AWETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol,
        isETH ? contractAddresses.wethGetwayContract : contractAddresses.lendingPoolContract,
        isETH ? tabData?.aTokenAddress : '',
        isETH
      );
      setApproved(result.transactionHash);
      setCurrentTabData((prevState) => {
        return {
          ...prevState,
          allowance: 10
        };
      });
    } catch (e) {}

    setIsLoadingApproveToContinue(false);
  };

  const calculateHealthFactorChangeOnSupply = (amountToSupply = 0) => {
    const beforeHealthFactor = userReservesData.healthFactor;
    const afterHealthFactor = calculateHealthFactorOnAction(
      userReservesData,
      tabData,
      tabData.underlyingAsset,
      activeTab,
      amountToSupply
    );
    return {
      beforeHealthFactor,
      afterHealthFactor
    };
  };

  const supplyBalanceOnSupply = (newSupplyAmount) => {
    const token = userReservesData.suppliedTokens.find(
      (token) => token.underlyingAsset === tabData.underlyingAsset
    );
    newSupplyAmount = new BigNumber(newSupplyAmount).multipliedBy(ten.pow(tabData?.decimals));
    const beforeBal = token.aTokenBal || new BigNumber(0);
    let afterBal;
    if (activeTab === 'supply') {
      afterBal = beforeBal.plus(newSupplyAmount);
    } else {
      afterBal = beforeBal.minus(newSupplyAmount);
    }
    return {
      beforeBal,
      afterBal
    };
  };

  // const calculateBorrowLimitInfoOnSupply = (amountToSupply = 0) => {
  //   const debtInETH = userReservesData.debtInETH;
  //   const maxInETH = debtInETH.plus(userReservesData.availableBorrowInETH);
  //   const borrowLimitInfoBefore = getBorrowLimitInfo(
  //     tabData.underlyingAsset,
  //     activeTab,
  //     0,
  //     tabData,
  //     userReservesData.availableBorrowInETH,
  //     debtInETH,
  //     maxInETH
  //   );
  //   const borrowLimitInfoAfter = getBorrowLimitInfo(
  //     tabData.underlyingAsset,
  //     activeTab,
  //     amountToSupply,
  //     tabData,
  //     userReservesData.availableBorrowInETH,
  //     debtInETH,
  //     maxInETH
  //   );
  //   return {
  //     borrowLimitInfoBefore,
  //     borrowLimitInfoAfter
  //   };
  // };

  const getWithdrawBalance = async () => {
    const tokenAddr =
      tabData?.symbol === 'ETH' || tabData?.symbol === 'WETH'
        ? WETH_ADDRESS
        : tabData?.underlyingAsset;

    const userTokenData = userReservesData?.userInfosByTokenAddress?.[tokenAddr];
    if (!userTokenData) {
      return 0;
    }
    const aTokenBal = userTokenData.aTokenBal;
    if (!userTokenData?.usageAsCollateralEnabledOnUser) {
      return aTokenBal;
    }
    if (userReservesData?.availableBorrowInETH) {
      const { availableAmount, assetPrice } = await availableWithdrawByToken(
        priceOracleContract,
        tokenAddr,
        lendingPoolContract,
        walletBalanceProviderContract,
        walletAddress
      );
      const availableWithdrawInETH = new BigNumber(availableAmount)
        .multipliedBy(10000)
        .div(tabData?.baseLTVasCollateral);
      const availableWithdraw = availableWithdrawInETH
        .multipliedBy(ten.pow(tabData?.decimals))
        .div(assetPrice);
      const available = availableWithdraw.gt(aTokenBal) ? aTokenBal : availableWithdraw;
      return available;
    }
    return 0;
  };
  useEffect(() => {
    setCurrentTabData(tabData);
    setIsToggleModal(isOpen);
    setActiveTab(initialActiveTab);
    if (tabData && Object.keys(tabData).length) {
      const healthFactorData = calculateHealthFactorChangeOnSupply(0);
      setHealthFactor(healthFactorData);
      setBeforeAfterBalance(supplyBalanceOnSupply(0));
      // setBorrowLimit(calculateBorrowLimitInfoOnSupply(0));
      getWithdrawBalance().then((data) => {
        setWithdrawBalance(data);
      });
      setIsETH(tabData.symbol === 'ETH');
    }
    setApproved(false);
    setIsLoadingSupplyDai(false);
  }, [tabData, isOpen]);
  const checkWithdrawBalance = async () => {
    const result = await getWithdrawBalance();
    setWithdrawBalance(result);
    return result;
  };
  const isActiveTabSupply = activeTab === TABS.supply;
  const isActiveTabWithdraw = activeTab === TABS.withdraw;
  const changeAmount = (amount, isMax) => {
    let amtValue = amount;
    if (isMax) {
      if (activeTab === TABS.withdraw) {
        const userTokenData = userReservesData?.userInfosByTokenAddress?.[tabData?.underlyingAsset];
        const wdrBal = withdrawBalance == 0 ? '0' : withdrawBalance.toFixed(0);
        const aTokenBal = userTokenData.aTokenBal.toFixed(0);
        if (
          !userTokenData?.usageAsCollateralEnabledOnUser ||
          new BigNumber(wdrBal).gte(aTokenBal)
        ) {
          amtValue = maxUint;
        }
      }
    }
    setAmountValue(amtValue);
    setCurrentAmountValue(amount);
    const healthFactorData = calculateHealthFactorChangeOnSupply(amount || 0);
    setHealthFactor(healthFactorData);
    setBeforeAfterBalance(supplyBalanceOnSupply(amount || 0));
    // setBorrowLimit(calculateBorrowLimitInfoOnSupply(amount || 0));
  };
  return (
    <ModalWithBackdrop isOpen={isToggleModal} onBackDropClick={onClose}>
      <ModalContentWrapperWithClose className="supply-withdraw-modal" onClose={onClose}>
        <div>
          <TextWithImage
            className="coin-with-name"
            text={
              isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
            }>
            <img
              src={
                isETH
                  ? getToken('ETH')?.image
                  : tabData?.symbol === 'ETH'
                  ? getToken('WETH')?.image
                  : getToken(tabData?.symbol)?.image
              }
              alt=""
            />
          </TextWithImage>
          <Tabs className={'tabs'}>
            <TabItem
              onClick={() => {
                setActiveTab(TABS.supply);
              }}
              isActive={isActiveTabSupply}
              text={intl.messages.supply}
            />
            <TabItem
              onClick={() => {
                setActiveTab(TABS.withdraw);
              }}
              isActive={isActiveTabWithdraw}
              text={intl.messages.withdraw}
            />
          </Tabs>
          {isActiveTabSupply && (
            <div>
              <TextWithWarningIcon className="amount-label" text={intl.messages.amount} warning />
              <AmountBox
                className="amount-section"
                setAmountValue={changeAmount}
                imgSrc={
                  isETH
                    ? getToken('ETH')?.image
                    : tabData?.symbol === 'ETH'
                    ? getToken('WETH')?.image
                    : getToken(tabData?.symbol)?.image
                }
                balance={
                  tabData?.symbol === 'ETH'
                    ? isETH
                      ? tabData?.balance
                      : tabData?.balanceWETH
                    : tabData?.symbol === 'WETH' && isETH
                    ? tabData?.balanceETH
                    : tabData?.balance
                }
                coinName={
                  isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
                }
                tabData={currentTabData}
                balanceType={activeTab}
              />
              <TextWithWarningIcon
                className="transaction-label"
                text={intl.messages.transaction_overview}
                warning={false}
              />
              <TransactionBox
                className="transaction-box"
                isETH={isETH}
                setIsETH={setIsETH}
                showIsETH={tabData?.symbol === 'ETH' || tabData?.symbol === 'WETH'}
                supplyPercent={`${tabData?.supplyAPY?.toFixed(2)} %`}
                healthFactorPrice={
                  convertToNumber(healthFactor.afterHealthFactor) >= infinityNumber ||
                  convertToNumber(healthFactor.afterHealthFactor) < infinityMinusNumber
                    ? 'Infinity'
                    : convertToNumber(healthFactor.afterHealthFactor)
                }
                liquidationAt="1.0"
                from={
                  convertToNumber(healthFactor.beforeHealthFactor) >= infinityNumber ||
                  convertToNumber(healthFactor.beforeHealthFactor) < infinityMinusNumber
                    ? 'Infinity'
                    : convertToNumber(healthFactor.beforeHealthFactor)
                }
                isShowCollateralization={tabData ? tabData.usageAsCollateralEnabled : false}
              />
              {/*<TransactionBannerSection*/}
              {/*  currentPrice={`$ ${borrowLimit.borrowLimitInfoBefore?.currentInETH.toString()}`}*/}
              {/*  maxPrice={`$ ${convertToNumber(borrowLimit.borrowLimitInfoBefore?.maxInETH)}`}*/}
              {/*  borrowLimit={`$ ${convertToNumber(*/}
              {/*    borrowLimit.borrowLimitInfoBefore?.availableBorrowInETH*/}
              {/*  )}`}*/}
              {/*/>*/}
              <div className="coin-supply-balance">
                <p>{`${intl.messages.supply_balance_dai} (${
                  isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
                })`}</p>
                <AmountFromTo
                  from={+convertToNumber(beforeAfterBalance.beforeBal, tabData?.decimals) || '0'}
                  to={+convertToNumber(beforeAfterBalance.afterBal, tabData?.decimals) || '0'}
                />
              </div>
              {approved ? (
                <div className="success-section">
                  <p className="success-text">{intl.messages.approve_confirmed}</p>
                  <TextWithShareIcon
                    text={intl.messages.review_approval_tx_details}
                    link={approved}
                  />
                </div>
              ) : null}
              {!isETH ? (
                <ButtonWithLoader
                  className="approve-to-continue-button"
                  onClick={handleApproveToContinue}
                  isLoading={isLoadingApproveToContinue}
                  disabled={
                    !Number(currentAmountValue) || isLoadingApproveToContinue
                      ? true
                      : +convertToNumber(currentTabData?.allowance, tabData?.decimals) >=
                          currentAmountValue || approved
                  }>
                  {intl.messages.approve_to_continue}
                </ButtonWithLoader>
              ) : null}
              <ButtonWithLoader
                className="supply-dai"
                onClick={handleSupplyDai}
                isLoading={isLoadingSupplyDai}
                disabled={
                  !Number(currentAmountValue) || isLoadingSupplyDai
                    ? true
                    : !isETH
                    ? +convertToNumber(currentTabData?.allowance, tabData?.decimals) <
                        currentAmountValue && !approved
                    : false
                }>
                {intl.messages.supply +
                  ` ${
                    isETH
                      ? 'ETH'
                      : currentTabData?.symbol === 'ETH'
                      ? 'WETH'
                      : currentTabData?.symbol
                  }`}
              </ButtonWithLoader>
            </div>
          )}
          {isActiveTabWithdraw && (
            <div>
              <TextWithWarningIcon className="amount-label" text={intl.messages.amount} warning />
              <AmountBox
                className="amount-section"
                setAmountValue={changeAmount}
                imgSrc={
                  isETH
                    ? getToken('ETH')?.image
                    : tabData?.symbol === 'ETH'
                    ? getToken('WETH')?.image
                    : getToken(tabData?.symbol)?.image
                }
                balance={convertToNumber(withdrawBalance, tabData?.decimals, false)}
                coinName={
                  isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
                }
                tabData={currentTabData}
                balanceType={activeTab}
                checkMaxAmount={checkWithdrawBalance}
              />
              <TextWithWarningIcon
                className="transaction-label"
                text={intl.messages.transaction_overview}
                warning={false}
              />
              <TransactionBox
                className="transaction-box"
                isETH={isETH}
                setIsETH={setIsETH}
                showIsETH={tabData?.symbol === 'ETH' || tabData?.symbol === 'WETH'}
                supplyPercent={`${tabData?.supplyAPY?.toFixed(2)} %`}
                healthFactorPrice={
                  healthFactor.afterHealthFactor?.toString() >= infinityNumber ||
                  healthFactor.afterHealthFactor?.toString() < infinityMinusNumber
                    ? 'Infinity'
                    : convertToNumber(healthFactor.afterHealthFactor)
                }
                liquidationAt="1.0"
                from={
                  healthFactor.beforeHealthFactor?.toString() >= infinityNumber ||
                  healthFactor.beforeHealthFactor?.toString() < infinityMinusNumber
                    ? 'Infinity'
                    : convertToNumber(healthFactor.beforeHealthFactor)
                }
              />
              {/*<TransactionBannerSection*/}
              {/*  currentPrice={`$ ${borrowLimit.borrowLimitInfoBefore?.currentInETH.toString()}`}*/}
              {/*  maxPrice={`$ ${convertToNumber(borrowLimit.borrowLimitInfoBefore?.maxInETH)}`}*/}
              {/*  borrowLimit={`$ ${convertToNumber(*/}
              {/*    borrowLimit.borrowLimitInfoBefore?.availableBorrowInETH*/}
              {/*  )}`}*/}
              {/*/>*/}
              <div className="coin-supply-balance">
                <p>{`${intl.messages.supply_balance_dai} (${
                  isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
                })`}</p>
                <AmountFromTo
                  from={
                    (+convertToNumber(beforeAfterBalance.beforeBal, tabData?.decimals)).toFixed(
                      2
                    ) || '0'
                  }
                  to={
                    Math.abs(
                      +convertToNumber(beforeAfterBalance.afterBal, tabData?.decimals)
                    ).toFixed(2) || '0'
                  }
                />
              </div>
              {/*<div className="success-section">*/}
              {/*  <p className="success-text">{intl.messages.approve_confirmed}</p>*/}
              {/*  <TextWithShareIcon text={intl.messages.review_approval_tx_details} />*/}
              {/*</div>*/}
              {/*<WarningBanner*/}
              {/*  className="error-section"*/}
              {/*  imgSrc={RedWarning}*/}
              {/*  type="error"*/}
              {/*  title={intl.messages.please_switch_to_ethereum}*/}
              {/*/>*/}
              {approved ? (
                <div className="success-section">
                  <p className="success-text">{intl.messages.approve_confirmed}</p>
                  <TextWithShareIcon
                    text={intl.messages.review_approval_tx_details}
                    link={approved}
                  />
                </div>
              ) : null}
              {isETH ? (
                <ButtonWithLoader
                  className="approve-to-continue-button"
                  onClick={handleApproveToContinue}
                  isLoading={isLoadingApproveToContinue}
                  disabled={
                    !Number(currentAmountValue) || isLoadingApproveToContinue
                      ? true
                      : +convertToNumber(currentTabData?.allowanceEth, tabData?.decimals) >=
                          currentAmountValue || approved
                  }>
                  {intl.messages.approve_to_continue}
                </ButtonWithLoader>
              ) : null}
              <ButtonWithLoader
                onClick={handleWithdrawToken}
                className="supply-dai"
                disabled={
                  isLoadingSupplyDai || !Number(currentAmountValue)
                    ? true
                    : isETH
                    ? +convertToNumber(currentTabData?.allowanceEth, tabData?.decimals) <
                        currentAmountValue && !approved
                    : false
                }
                isLoading={isLoadingSupplyDai}>
                {`${intl.messages.withdraw} ${
                  isETH ? 'ETH' : currentTabData?.symbol === 'ETH' ? 'WETH' : currentTabData?.symbol
                }`}
              </ButtonWithLoader>
            </div>
          )}
        </div>
      </ModalContentWrapperWithClose>
    </ModalWithBackdrop>
  );
};

export default SupplyWithdrawModal;
