import TextWithImage from '../TextWithImage';
import AmountFromTo from '../AmountFromTo';
import { useIntl } from 'react-intl';

import styles from './index.module.scss';

import { convertToNumber, getToken } from '../../utilitis';
import TPYToggle from '../TPYToggle';

const BorrowRepayTransactionBox = ({
  supplyPercent,
  healthFactorPrice,
  liquidationAt,
  className = '',
  from,
  symbol,
  repay = false,
  beforeRepayBalance = 0,
  afterRepayBalance = 0,
  decimals = 18,
  showIsETH = true,
  setIsETH,
  isETH = true
}) => {
  const intl = useIntl();

  return (
    <div className={`${className} ${styles['transaction-box']}`}>
      {showIsETH ? (
        <div className={`${styles['supply-section']} ${styles['flex-between']}`}>
          <TPYToggle
            sliderClassName={`${styles.toggle} ${isETH ? styles['toggle-slider'] : ''}`}
            checked={isETH}
            onChange={() => setIsETH((prevState) => !prevState)}
          />
          <p className={styles.title}>{isETH ? 'ETH' : 'WETH'}</p>
        </div>
      ) : null}
      {repay ? (
        <div className={`${styles['repay-balance']} ${styles['flex-between']}`}>
          <p className={styles.title}>{intl.messages.dept_balance}</p>
          <AmountFromTo
            from={convertToNumber(beforeRepayBalance, decimals)}
            to={afterRepayBalance}
          />
        </div>
      ) : null}
      <div className={`${styles['supply-section']} ${styles['flex-between']}`}>
        <TextWithImage text={intl.messages.borrow_api}>
          <img
            src={
              isETH
                ? getToken('ETH')?.image
                : symbol === 'ETH'
                ? getToken('WETH')?.image
                : getToken(symbol)?.image
            }
            alt=""
          />
        </TextWithImage>
        <p className={styles['supply-percent']}>{supplyPercent}</p>
      </div>
      <div className={`${styles['health-factor']} ${styles['flex-between']}`}>
        <p className={styles.title}>{intl.messages.health_factor}</p>
        <AmountFromTo from={from} to={healthFactorPrice} factory={true} />
      </div>
      <p
        className={
          styles['liquidation-at']
        }>{`${intl.messages.liquidation_at} < ${liquidationAt}`}</p>
    </div>
  );
};

export default BorrowRepayTransactionBox;
