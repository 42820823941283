import styles from './index.module.scss';

function SelectCoins({ title, imgUrl }) {
  return (
    <div className={styles.selectCoinsBox}>
      <div className={styles.coinImage}>
        <img src={imgUrl} alt="#" />
      </div>
      <h3>{title}</h3>
    </div>
  );
}

export default SelectCoins;
