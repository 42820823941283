import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Tabs from '../../components/Tabs';
import Banner from '../../components/Banner';
import getToken from '../../utilitis/getToken';
import TabItem from '../../components/TabItem';
import Section from '../../components/Section';
import TPYTable from '../../components/TPYTable';
import TPYToggle from '../../components/TPYToggle';
import SelectCoins from '../../components/SelectCoins';
import WarningText from '../../components/WarningText';
import ProgressBar from '../../components/ProgressBar';
import useWindowSize from '../../hooks/useWindowSize';
import InnerBlocks from '../../components/InnerBlocks';
import GenericTitle from '../../components/GenericTitle';
import SuccessPopup from '../../components/SuccessModal';
import TPYButton from '../../components/buttons/TPYButton';
import TPYOutlinedButton from '../../components/TPYButtonUI';
import ReviewCoinModal from '../../components/ReviewCoinModal';
import TitleDescription from '../../components/TitleDescription';
import EnterAmountModal from '../../components/EnterAmountModal';
import VariableDropdown from '../../components/VariableDropdown';
import BorrowRepayModal from '../../components/BorrowRepayModal';
import SupplyWithdrawModal from '../../components/SupplyWithdrawModal';
import TPYInnerTitlesDescription from '../../components/TPYInnerTitlesDescription';
import convertMarketCurrencyPriceToUSD from '../../utilitis/convertMarketCurrencyPriceToUSD';

import styles from './dashboardSecond.module.scss';

import success from '../../images/icons/success.svg';
import ethereum from '../../images/coins/ethereum.svg';

import { TABS } from '../../constants/tabs';
import { infinityNumber } from '../../constants/numbers';
import { convertToNumber } from '../../utilitis';
import { setReservesData, setUserReservesData } from '../../redux/actions/reserveActions';
import { getEthPriceInUsd, getReserveData, getUserReservesData } from '../../services/Web3';
import BigNumber from 'bignumber.js';

function DashboardSecond() {
  const intl = useIntl();

  const { supply, borrow } = TABS;

  const { width } = useWindowSize();

  const dispatch = useDispatch();
  // Get contract and wallet Address from Redux
  const walletAddress = useSelector((state) => state.wallet.selectedAddress);
  const supplyTokenData = useSelector((state) => state.reserveReducer.reservesData);
  const userReservesData = useSelector((state) => state.reserveReducer.userReservesData);
  const lendingPoolContract = useSelector((state) => state.contractsReducer.lendingPoolContract);
  const priceOracleContract = useSelector((state) => state.contractsReducer.priceOracleContract);
  const uIPoolDataProviderContract = useSelector(
    (state) => state.contractsReducer.uIPoolDataProiderContract
  );
  const walletBalanceProviderContract = useSelector(
    (state) => state.contractsReducer.walletBalanceProviderContract
  );
  const priceFormatter = Intl.NumberFormat('en', { notation: 'compact' });

  const [ethPrice, setEthPrice] = useState(0);
  const [tableBody, setTableBody] = useState([]);
  const [activeTab, setActiveTab] = useState(supply);
  const [changeRateData, setChangeRateData] = useState({});
  const [suppliedTokenData, setSuppliedTokenData] = useState([]);
  const [isToggleChangeRate, setIsToggleChangeRate] = useState(false);
  const [toggleSuccessPopupState, setIsToggleSuccessPopup] = useState({
    isSuccessPopupModalOpen: false,
    showAddToWalletSection: true
  });
  const [repayBorrowModalState, setRepayBorrowModalState] = useState({
    isRepayBorrowModalOpen: false,
    activeTab: 'borrow',
    tabData: {}
  });
  const [supplyWithdrawModalState, setSupplyWithdrawModalState] = useState({
    isWithdrawSupplyModalOpen: false,
    activeTab: 'supply',
    tabData: {}
  });
  const [reviewCoinModalState, setReviewCoinModalState] = useState({
    isOpenReviewCoinModal: false,
    reviewCoinData: {}
  });

  const handleChangeRateMode = ({ tokenAddr, variant, percent }) => {
    setChangeRateData({
      tokenAddr: tokenAddr,
      variant: variant,
      percent: percent
    });
    setIsToggleChangeRate(true);
  };

  const handleChangeTabName = (tabName) => setActiveTab(tabName);

  const TABLE_HEAD = [
    { text: intl.messages.asset },
    { text: intl.messages.balance },
    { text: intl.messages.apy },
    {
      text: (
        <WarningText
          text={intl.messages.collateral}
          className={styles.itemTableTitle}
          warningText={intl.messages.warning_Text_Collateral}
        />
      )
    }
  ];

  const TABLE_HEAD_SECOND = [
    { text: intl.messages.asset },
    { text: intl.messages.debt },
    { text: intl.messages.apy },
    { text: <WarningText text={`${intl.messages.of_limit}`} className={styles.itemTableTitle} /> }
  ];

  const variableDropdownData = useMemo(() => {
    return [
      { text: intl.messages.apy_variable, value: '< 0.01 %', index: 0 },
      { text: intl.messages.apy_stable, value: '5.13 %', index: 1 }
    ];
  }, [intl.messages.apy_stable, intl.messages.apy_variable]);
  const tableBodySecond = useMemo(() => {
    const borrowTokens = [];
    Object.values(supplyTokenData || {}).map((item) => {
      if (item.symbol !== 'WETH') {
        let stable = {
          value: 0,
          price: 0,
          apy: '0 %'
        };
        let variable = {
          value: 0,
          price: 0,
          apy: '0 %'
        };
        if (Number(userReservesData.userInfosByTokenAddress[item.underlyingAsset].stableDebtBal)) {
          stable.value = convertToNumber(
            userReservesData.userInfosByTokenAddress[item.underlyingAsset].stableDebtBal,
            item.decimals
          );
          stable.price = priceFormatter.format(
            convertMarketCurrencyPriceToUSD(
              item.priceInMarketReferenceCurrency,
              item.networkBaseTokenPriceInUsd
            ).toNumber() * stable.value
          );
          stable.value = stable.value < 0.01 ? '< 0.01' : stable.value;
          stable.price = stable.price < 0.01 ? '< $ 0.01' : `$ ${stable.price}`;
        }
        stable.apy = `${userReservesData.userInfosByTokenAddress[
          item.underlyingAsset
        ].userStableBorrowAPY.toFixed(2)} %`;

        if (
          Number(userReservesData.userInfosByTokenAddress[item.underlyingAsset].variableDebtBal)
        ) {
          variable.value = convertToNumber(
            userReservesData.userInfosByTokenAddress[item.underlyingAsset].variableDebtBal,
            item.decimals
          );
          variable.price = priceFormatter.format(
            convertMarketCurrencyPriceToUSD(
              item.priceInMarketReferenceCurrency,
              item.networkBaseTokenPriceInUsd
            ).toNumber() * variable.value
          );
          variable.value = variable.value < 0.01 ? '< 0.01' : variable.value;
          variable.price = variable.price < 0.01 ? '< $ 0.01' : `$ ${variable.price}`;
        }
        variable.apy = `${item.variableBorrowAPY.toFixed(2)} %`;
        if (Number(userReservesData.userInfosByTokenAddress[item.underlyingAsset].stableDebtBal)) {
          borrowTokens.push([
            { imgSrc: getToken(item.symbol).image, text: item.symbol, button: null },
            {
              //to do
              text: `${stable.value}`,
              description: `${stable.price}`,
              button: null
            },
            {
              text: <div className={styles.itemTableSelect}>{stable.apy}</div>,
              button: null
            },
            {
              text: (
                <div className={styles.itemTableSelect}>
                  <VariableDropdown
                    address={item.underlyingAsset}
                    changeMode={handleChangeRateMode}
                    headerText={intl.messages.stable}
                    disable={true}
                    dropdownSelectData={[
                      {
                        text: intl.messages.apy_stable,
                        value: stable.apy,
                        index: 1
                      },
                      {
                        text: intl.messages.apy_variable,
                        value: variable.apy,
                        index: 2
                      }
                    ]}
                  />
                </div>
              ),
              button: null
            },
            {
              button: (
                <div className={styles.itemTableButtons}>
                  <TPYButton
                    tooltipText={intl.messages.repay}
                    onClick={() => toggleRepayBorrowModal('repay', item, 1)}>
                    {intl.messages.repay}
                  </TPYButton>
                  <TPYOutlinedButton
                    buttonLabel={intl.messages.borrow}
                    onClick={() => toggleRepayBorrowModal('borrow', item, 1)}
                  />
                </div>
              )
            }
          ]);
        }
        if (
          Number(userReservesData.userInfosByTokenAddress[item.underlyingAsset].variableDebtBal)
        ) {
          borrowTokens.push([
            { imgSrc: getToken(item.symbol).image, text: item.symbol, button: null },
            {
              //to do
              text: `${variable.value}`,
              description: `${variable.price}`,
              button: null
            },
            {
              text: <div className={styles.itemTableSelect}>{variable.apy}</div>,
              button: null
            },
            {
              text: (
                <div className={styles.itemTableSelect}>
                  <VariableDropdown
                    changeMode={handleChangeRateMode}
                    address={item.underlyingAsset}
                    disable={true}
                    dropdownSelectData={[
                      {
                        text: intl.messages.apy_stable,
                        value: stable.apy,
                        index: 1
                      },
                      {
                        text: intl.messages.apy_variable,
                        value: variable.apy,
                        index: 2
                      }
                    ]}
                  />
                </div>
              ),
              button: null
            },
            {
              button: (
                <div className={styles.itemTableButtons}>
                  <TPYButton
                    tooltipText={intl.messages.repay}
                    onClick={() => toggleRepayBorrowModal('repay', item, 2)}>
                    {intl.messages.repay}
                  </TPYButton>
                  <TPYOutlinedButton
                    buttonLabel={intl.messages.borrow}
                    onClick={() => toggleRepayBorrowModal('borrow', item, 2)}
                  />
                </div>
              )
            }
          ]);
        }
      }
    });
    return borrowTokens;
  }, [intl.messages.borrow, intl.messages.repay, variableDropdownData, supplyTokenData]);

  const tableHeaderThird = useMemo(() => {
    return [
      { text: intl.messages.asset },
      { text: intl.messages.available },
      {
        text: (
          <WarningText
            text={intl.messages.apy_variable}
            warningText={intl.messages.warning_Text_Borrow_APY_variable}
          />
        )
      }
    ];
  }, [intl]);

  const TABLE_HEAD_SUPPLY = useMemo(() => {
    return [
      { text: intl.messages.asset },
      { text: intl.messages.wallet_balance },
      { text: intl.messages.apy },
      { text: intl.messages.can_be_collateral },
      { text: '' }
    ];
  }, [intl]);

  const tableBodyThird = useMemo(() => {
    const supplyTokens = [];
    const suppliedTokens = [];
    if (!userReservesData?.suppliedTokens) {
      return [];
    }

    Object.values(supplyTokenData || {}).forEach((item) => {
      const { symbol, balance, supplyAPY, underlyingAsset, realBalance, decimals } = item;
      const usageAsCollateralEnabledOnUser =
        userReservesData?.userInfosByTokenAddress[underlyingAsset].usageAsCollateralEnabledOnUser;
      const supplied = userReservesData?.suppliedTokens.find(
        (item) => item.underlyingAsset === underlyingAsset
      );
      if (supplied[1] !== '0' && symbol !== 'WETH') {
        const value = convertToNumber(
          userReservesData?.userInfosByTokenAddress[underlyingAsset].aTokenBal,
          item.decimals
        );
        suppliedTokens.push([
          { imgSrc: getToken(symbol).image, text: symbol, button: null },
          {
            text: `${value < 0.01 ? '< 0.01' : value}`,
            description: `$ ${priceFormatter.format(
              convertMarketCurrencyPriceToUSD(
                item.priceInMarketReferenceCurrency,
                item.networkBaseTokenPriceInUsd
              ).toNumber() *
                convertToNumber(
                  userReservesData?.userInfosByTokenAddress[underlyingAsset].aTokenBal,
                  item.decimals,
                  false
                )
            )}`,
            button: null
          },
          { text: `${supplyAPY?.toFixed(2)} %`, button: null },
          {
            text: (
              <TPYToggle
                disabled={!item.usageAsCollateralEnabled}
                sliderClassName={`${styles.tableToggle} ${
                  usageAsCollateralEnabledOnUser ? styles['toggle-slider'] : ''
                }`}
                checked={usageAsCollateralEnabledOnUser}
                onChange={() =>
                  toggleRepayReviewCoinModal(
                    item,
                    userReservesData?.userInfosByTokenAddress[underlyingAsset].aTokenBal,
                    usageAsCollateralEnabledOnUser
                  )
                }
              />
            ),
            button: null
          },
          {
            button: (
              <div className={styles.itemTableButtons}>
                <TPYButton
                  tooltipText={intl.messages.withdraw}
                  onClick={() => toggleWithdrawSupplyModal('withdraw', item)}>
                  {intl.messages.withdraw}
                </TPYButton>
                <TPYOutlinedButton
                  onClick={() => toggleWithdrawSupplyModal('supply', item)}
                  buttonLabel={intl.messages.supply}
                />
              </div>
            )
          }
        ]);
      }
      supplyTokens.push([
        {
          imgSrc: getToken(symbol).image,
          text: symbol,
          button: null,
          usageAsCollateralEnabled: item.usageAsCollateralEnabled
        },
        {
          text:
            symbol === 'ETH'
              ? new BigNumber(balance).toFixed(2)
              : convertToNumber(realBalance, decimals),
          button: null
        },
        { text: `${supplyAPY.toFixed(2)} %`, button: null },
        {
          text: item.usageAsCollateralEnabled ? (
            <img alt="/#" src={success} />
          ) : (
            <span className={styles.tableBodyEmptyRow} />
          ),
          button: null
        },
        {
          button: (
            <TPYButton
              tooltipText={intl.messages.supply}
              disabled={balance === 0 && realBalance === '0'}
              onClick={() => toggleWithdrawSupplyModal('supply', item)}>
              {intl.messages.supply}
            </TPYButton>
          )
        }
      ]);
    });
    setTableBody(suppliedTokens);
    return supplyTokens;
  }, [intl, supplyTokenData, suppliedTokenData]);
  const tableBodyThirdSecond = useMemo(() => {
    return Object.values(supplyTokenData || {}).map((item) => {
      const { symbol, stableBorrowAPY, variableBorrowAPY, borrowingEnabled, availableBalance } =
        item;
      if (borrowingEnabled) {
        return [
          {
            imgSrc: getToken(symbol)?.image,
            text: symbol,
            button: null,
            stableBorrowAPY: item.stableBorrowAPY.toFixed(2)
          },
          { text: availableBalance.toFixed(2), button: null },
          { text: `${variableBorrowAPY.toFixed(2)} %`, button: null },
          {
            button: (
              <TPYButton
                tooltipText={intl.messages.borrow}
                disabled={availableBalance <= 0}
                onClick={() => toggleRepayBorrowModal('borrow', item)}>
                {intl.messages.borrow}
              </TPYButton>
            )
          }
        ];
      }
      return [];
    });
  }, [intl, supplyTokenData]);

  function toggleWithdrawSupplyModal(activeTab = '', tabData) {
    setSupplyWithdrawModalState((prevState) => {
      return {
        ...prevState,
        isWithdrawSupplyModalOpen: !prevState.isWithdrawSupplyModalOpen,
        tabData,
        activeTab
      };
    });
  }

  //todo add dinamic data
  function toggleRepayBorrowModal(activeTab = 'borrow', tabData, mode = 1) {
    setRepayBorrowModalState((prevState) => {
      return {
        ...prevState,
        activeTab,
        isRepayBorrowModalOpen: !prevState.isRepayBorrowModalOpen,
        tabData: tabData,
        mode
      };
    });
  }

  function toggleRepayReviewCoinModal(tabData, supplyBalance, usageAsCollateralEnabledOnUser) {
    setReviewCoinModalState((prevState) => {
      return {
        reviewCoinData: { ...tabData, supplyBalance, usageAsCollateralEnabledOnUser },
        isOpenReviewCoinModal: !prevState.isOpenReviewCoinModal
      };
    });
  }

  const isActiveTabSupply = activeTab === supply;
  const isActiveTabBorrow = activeTab === borrow;

  const getUserInfo = async () => {
    let reservData = await getReserveData(
      uIPoolDataProviderContract,
      walletBalanceProviderContract,
      priceOracleContract,
      walletAddress,
      window.ethereum,
      lendingPoolContract
    );
    const userReservData = await getUserReservesData(
      uIPoolDataProviderContract,
      walletBalanceProviderContract,
      priceOracleContract,
      walletAddress,
      reservData,
      window.ethereum
    );
    dispatch(setReservesData({ data: reservData }));
    dispatch(setUserReservesData({ data: userReservData }));
    setSuppliedTokenData([]);
  };
  const checkNetApy = (apy) => {
    if (isNaN(apy)) return 0;
    if (apy < 0) {
      return apy < -0.01 ? apy?.toFixed(2) : '> -0.01';
    }
    return apy < 0.01 ? '< 0.01' : apy?.toFixed(2);
  };

  useEffect(() => {
    const getEthPrice = async () => {
      const getEthPrice = await getEthPriceInUsd();
      setEthPrice(getEthPrice || 0);
    };
    getEthPrice();
  }, []);

  useEffect(() => {
    if (
      lendingPoolContract?.methods !== undefined &&
      uIPoolDataProviderContract?.methods !== undefined
    ) {
      getUserInfo();
    }
  }, [lendingPoolContract, uIPoolDataProviderContract, priceOracleContract]);

  return (
    <Section className={styles.dashboardContainer}>
      <SuccessPopup
        isSuccessPopupModalOpen={toggleSuccessPopupState.isSuccessPopupModalOpen}
        showAddToWalletSection={toggleSuccessPopupState.showAddToWalletSection}
      />
      <ReviewCoinModal
        getUserInfo={getUserInfo}
        setIsOpen={setReviewCoinModalState}
        setIsToggleSuccessPopup={setIsToggleSuccessPopup}
        walletAddress={walletAddress}
        isOpen={reviewCoinModalState.isOpenReviewCoinModal}
        reviewCoinData={reviewCoinModalState.reviewCoinData}
      />
      <EnterAmountModal
        getUserInfo={getUserInfo}
        setIsOpen={setIsToggleChangeRate}
        data={changeRateData}
        isOpen={isToggleChangeRate}
        walletAddress={walletAddress}
        setIsToggleSuccessPopup={setIsToggleSuccessPopup}
      />
      <SupplyWithdrawModal
        tabData={supplyWithdrawModalState.tabData}
        initialActiveTab={supplyWithdrawModalState.activeTab}
        isOpen={supplyWithdrawModalState.isWithdrawSupplyModalOpen}
        onClose={toggleWithdrawSupplyModal}
        getUserInfo={getUserInfo}
        setIsToggleSuccessPopup={setIsToggleSuccessPopup}
        priceInUsd={ethPrice}
      />
      <BorrowRepayModal
        tabData={repayBorrowModalState.tabData}
        initialActiveTab={repayBorrowModalState.activeTab}
        isOpen={repayBorrowModalState.isRepayBorrowModalOpen}
        onClose={toggleRepayBorrowModal}
        walletAddress={walletAddress}
        getUserInfo={getUserInfo}
        mode={repayBorrowModalState.mode}
        setIsToggleSuccessPopup={setIsToggleSuccessPopup}
      />
      {width > 768 ? (
        <>
          <div className={styles.topTitleBox}>
            <GenericTitle title={intl.messages.dashboard} />
            <SelectCoins title={intl.messages.ethereum_market} imgUrl={ethereum} />
          </div>
          <div className={styles.bannersBox}>
            <Banner className={`${styles.bannersItem} ${styles.firstBannersItem}`}>
              <TitleDescription title={intl.messages.your_supplies} />
              <div className={styles.bannerWarningBox}>
                <WarningText
                  text={intl.messages.net_apy}
                  warningText={intl.messages.warning_Text_Net_APY}
                />
              </div>
              <p className={styles.percentText}>{checkNetApy(userReservesData?.netAPY) || 0} %</p>
              <ProgressBar
                progressValue={60}
                showWarnings={true}
                healthFactor={
                  convertToNumber(userReservesData?.healthFactor) > infinityNumber
                    ? 'Infinity'
                    : convertToNumber(userReservesData?.healthFactor || 0)
                }
              />
            </Banner>
            <Banner
              className={`${styles.bannersItem} ${styles.blocksBannersItem} ${styles.firstBannersItem}`}>
              <div className={styles.bannersInnerBlocksBox}>
                <InnerBlocks
                  title={intl.messages.supply_balance}
                  subTitle={`$ ${priceFormatter?.format(
                    convertToNumber(userReservesData?.supplyInETH * ethPrice || 0)
                  )}`}
                  className={styles.innerBlocks}
                />
                <InnerBlocks
                  title={intl.messages.collateral}
                  subTitle={`$ ${priceFormatter?.format(
                    convertToNumber(userReservesData?.collateralInETH * ethPrice || 0)
                  )}`}
                  className={styles.innerBlocks}
                />
                <InnerBlocks
                  title={intl.messages.borrow_balance}
                  subTitle={`$ ${priceFormatter?.format(
                    convertToNumber(userReservesData?.debtInETH * ethPrice || 0)
                  )}`}
                  className={styles.innerBlocks}
                />
              </div>
            </Banner>
          </div>
          <div className={styles.bannersBox}>
            <Banner className={styles.bannersItem}>
              <TitleDescription
                title={intl.messages.your_supplies}
                className={styles.bannersInformationBox}
                description={tableBody.length ? '' : intl.messages.nothing_supplied_yet}
              />
              <TPYTable
                bodyData={tableBody}
                headerData={TABLE_HEAD}
                className={styles.bannerTable}
              />
            </Banner>
            <Banner className={styles.bannersItem}>
              <TitleDescription
                title={intl.messages.your_borrows}
                className={styles.bannersInformationBox}
                description={tableBodySecond.length ? '' : intl.messages.nothing_supplied_yet}
              />
              <TPYTable
                bodyData={tableBodySecond}
                headerData={TABLE_HEAD_SECOND}
                className={styles.bannerTable}
              />
            </Banner>
          </div>
          <div className={styles.bannersBox}></div>
          <div className={styles.bannersBox}>
            <Banner className={styles.bannersItem}>
              <TitleDescription title={intl.messages.assets_to_supply} />
              <TPYTable
                className={styles.bannerTable}
                headerData={TABLE_HEAD_SUPPLY}
                bodyData={tableBodyThird}
              />
            </Banner>
            <Banner className={styles.bannersItem}>
              <TitleDescription title={intl.messages.assets_to_borrows} />
              <TPYTable
                headerData={tableHeaderThird}
                bodyData={tableBodyThirdSecond}
                className={styles.bannerTable}
              />
            </Banner>
          </div>
        </>
      ) : (
        <>
          <Banner className={`${styles.bannersItem} ${styles.blocksBannersItem} `}>
            <SelectCoins title={intl.messages.ethereum_market} imgUrl={ethereum} />
            <div className={styles.bannersInnerBlocksBox}>
              <InnerBlocks
                title={intl.messages.supply_balance}
                subTitle={`$ ${priceFormatter?.format(
                  convertToNumber(userReservesData?.supplyInETH * ethPrice || 0)
                )}`}
                className={styles.innerBlocks}
              />
              <InnerBlocks
                title={intl.messages.collateral}
                subTitle={`$ ${priceFormatter?.format(
                  convertToNumber(userReservesData?.collateralInETH * ethPrice || 0)
                )}`}
                className={styles.innerBlocks}
              />
              <InnerBlocks
                title={intl.messages.borrow_balance}
                subTitle={`$ ${priceFormatter?.format(
                  convertToNumber(userReservesData?.debtInETH * ethPrice || 0)
                )}`}
                className={styles.innerBlocks}
              />
            </div>
          </Banner>
          <div className={styles.bannersBox}>
            <Banner className={styles.bannersItem}>
              <TitleDescription title={intl.messages.your_supplies} />
              <div className={styles.bannerWarningBox}>
                <WarningText text={intl.messages.net_apy} />
              </div>
              <p className={styles.percentText}>{checkNetApy(userReservesData?.netAPY) || 0} %</p>
              <ProgressBar
                progressValue={60}
                showWarnings={true}
                healthFactor={
                  convertToNumber(userReservesData?.healthFactor) > infinityNumber
                    ? 'Infinity'
                    : convertToNumber(userReservesData?.healthFactor || 0)
                }
              />
            </Banner>
          </div>
          <div className={styles.mobileBtn}>
            <Tabs className={'tabs'}>
              <TabItem
                onClick={() => {
                  handleChangeTabName(supply);
                }}
                isActive={activeTab === supply}
                text={intl.messages.supply}
              />
              <TabItem
                onClick={() => {
                  handleChangeTabName(borrow);
                }}
                isActive={activeTab === borrow}
                text={intl.messages.borrow}
              />
            </Tabs>
          </div>
          {isActiveTabSupply && (
            <>
              <div className={styles.bannersBox}>
                <Banner className={styles.bannersItem}>
                  <TitleDescription
                    title={intl.messages.your_supplies}
                    className={styles.bannerTitle}
                  />
                  <span className={styles.emptyRow} />
                  {tableBody.length
                    ? tableBody.map((rows, index) => (
                        <div key={`${index}`}>
                          <div className={styles.bannerTableMobile}>
                            <div className={styles.coinTitle}>
                              <img alt="#" width={27} height={27} src={rows[0].imgSrc} />
                              <p>{rows[0].text}</p>
                            </div>
                            <div className={styles.coinsInfoBox}>
                              <div className={styles.coinsInfo}>
                                <TPYInnerTitlesDescription
                                  description={intl.messages.wallet_balance}
                                />
                                <TPYInnerTitlesDescription
                                  textAlign="right"
                                  title={rows[1].text}
                                  description={rows[1].description}
                                />
                              </div>
                              <div className={styles.coinsInfo}>
                                <TPYInnerTitlesDescription description={intl.messages.apy} />
                                <TPYInnerTitlesDescription
                                  title={rows[2].text}
                                  textAlign="right"
                                  className={styles.selectItemTitle}
                                />
                              </div>
                              <div className={styles.coinsInfo}>
                                <WarningText
                                  text={intl.messages.collateral}
                                  className={styles.dashboardBannerWarningText}
                                />
                                {rows[3].text}
                              </div>
                            </div>
                          </div>
                          {rows[4]?.button}
                        </div>
                      ))
                    : null}
                </Banner>
              </div>
              <div className={styles.bannersBox}>
                <Banner className={styles.bannersItem}>
                  <TitleDescription title={intl.messages.your_supplies} />
                  {/*<WarningBanner*/}
                  {/*  imgSrc={blueWarning}*/}
                  {/*  className={styles.bannerInnerWarning}*/}
                  {/*  type="suggested"*/}
                  {/*  title={intl.messages.your_wallet_is_empty}*/}
                  {/*/>*/}
                  <span className={styles.emptyRow} style={{ marginTop: 18 }} />
                  {tableBodyThird.length
                    ? tableBodyThird.map((item, index) => (
                        <div key={index}>
                          <div className={styles.bannerTableMobile}>
                            <div className={styles.coinTitle}>
                              <img alt="#" width={27} height={27} src={item[0].imgSrc} />
                              <p>{item[0].text}</p>
                            </div>
                            <div className={styles.coinsInfoBox}>
                              <div className={styles.coinsInfo}>
                                <TPYInnerTitlesDescription description={intl.messages.balance} />
                                <TPYInnerTitlesDescription textAlign="right" title={item[1].text} />
                              </div>
                              <div className={styles.coinsInfo}>
                                <TPYInnerTitlesDescription description={intl.messages.apy} />
                                <TPYInnerTitlesDescription
                                  title={`${item[2].text}`}
                                  textAlign="right"
                                  className={styles.selectItemTitle}
                                />
                              </div>
                              <div className={styles.coinsInfo}>
                                <TPYInnerTitlesDescription
                                  description={intl.messages.can_be_collateral}
                                />
                                {item[0].usageAsCollateralEnabled ? (
                                  <img alt="/#" src={success} />
                                ) : (
                                  <TPYInnerTitlesDescription textAlign="right" title="--" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={styles.mobileBtnInner}>{item[4]?.button}</div>
                        </div>
                      ))
                    : null}
                </Banner>
              </div>
            </>
          )}
          {isActiveTabBorrow && (
            <>
              <div className={styles.bannersBox}>
                <Banner className={styles.bannersItem}>
                  <TitleDescription
                    title={intl.messages.your_borrows}
                    className={styles.bannerTitle}
                  />
                  <span className={styles.emptyRow} />
                  {tableBodySecond
                    ? tableBodySecond.map((item, index) => (
                        <div key={index}>
                          <div className={styles.bannerTableMobile}>
                            <div className={styles.coinTitle}>
                              <img alt="#" width={27} height={27} src={item[0].imgSrc} />
                              <p>{item[0].text}</p>
                            </div>
                            <div className={styles.coinsInfoBox}>
                              <div className={styles.coinsInfo}>
                                <TPYInnerTitlesDescription description={intl.messages.debt} />
                                <TPYInnerTitlesDescription
                                  textAlign="right"
                                  title={item[1].text}
                                  description={item[1].description}
                                />
                              </div>
                              <div className={styles.coinsInfo}>
                                <TPYInnerTitlesDescription description={intl.messages.apy} />
                                <div>
                                  <TPYInnerTitlesDescription
                                    title={item[2].text}
                                    textAlign="right"
                                    className={styles.selectItemTitle}
                                  />
                                </div>
                              </div>
                              <div className={styles.coinsInfo}>
                                <WarningText
                                  text="APY type"
                                  className={styles.dashboardBannerWarningText}
                                />
                                <div className={styles.linearProgress}>{item[3].text}</div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.mobileBtnInner}>{item[4]?.button}</div>
                        </div>
                      ))
                    : null}
                </Banner>
              </div>
              <div className={styles.bannersBox}>
                <Banner className={styles.bannersItem}>
                  <TitleDescription title={intl.messages.assets_to_borrows} />
                  {/*<WarningBanner*/}
                  {/*  imgSrc={blueWarning}*/}
                  {/*  className={styles.bannerInnerWarning}*/}
                  {/*  type="suggested"*/}
                  {/*  title={intl.messages.your_wallet_is_empty}*/}
                  {/*/>*/}
                  <span className={styles.emptyRow} style={{ marginTop: 18 }} />
                  {tableBodyThirdSecond.length
                    ? tableBodyThirdSecond.map((item, index) => {
                        return item[0] ? (
                          <div key={index} className={styles.bannerTableMobile}>
                            <div>
                              <div className={styles.coinTitle}>
                                <img alt="#" width={27} height={27} src={item[0].imgSrc} />
                                <p>{item[0].text}</p>
                              </div>
                              <div className={styles.coinsInfoBox}>
                                <div className={styles.coinsInfo}>
                                  <TPYInnerTitlesDescription
                                    description={intl.messages.available}
                                  />
                                  <TPYInnerTitlesDescription
                                    textAlign="right"
                                    title={item[1].text}
                                  />
                                </div>
                                <div className={styles.coinsInfo}>
                                  <TPYInnerTitlesDescription
                                    description={intl.messages.apy_variable}
                                  />
                                  <TPYInnerTitlesDescription
                                    title={item[2].text}
                                    textAlign="right"
                                    className={styles.selectItemTitle}
                                  />
                                </div>
                                <div className={styles.coinsInfo}>
                                  <TPYInnerTitlesDescription
                                    description={intl.messages.apy_stable}
                                  />
                                  <TPYInnerTitlesDescription
                                    textAlign="right"
                                    title={`${item[0].stableBorrowAPY} %`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={styles.mobileBtnInner}>
                              <div />
                              {item[3]?.button}
                            </div>
                          </div>
                        ) : null;
                      })
                    : null}
                </Banner>
              </div>
            </>
          )}
        </>
      )}
    </Section>
  );
}

export default DashboardSecond;
