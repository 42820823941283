import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import infinityIcon from '../../images/icons/infinity.svg';

import styles from './index.module.css';

const AmountFromTo = ({ from, to, className = '', factory = false }) => {
  const checkColor = (value) => {
    if (factory) {
      return value <= 1.3 ? 'red' : value <= 1.6 ? 'yellow' : 'green';
    }
    return 'green';
  };

  return (
    <div className={`${className} ${styles['amount-from-to']}`}>
      {from && (
        <span className={`${styles.from} ${styles[checkColor(from)]}`}>
          {from === 'Infinity' ? <img src={infinityIcon} alt={'...'} /> : from}
        </span>
      )}
      {from && <ArrowRightAltIcon className="arrow-icon" />}
      {to && (
        <span className={`${styles.to} ${styles[checkColor(to)]}`}>
          {to === 'Infinity' ? <img src={infinityIcon} alt={'...'} /> : to}
        </span>
      )}
    </div>
  );
};

export default AmountFromTo;
