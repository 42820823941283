export const setReservesData = (data) => ({
  type: 'ADD_RESERVES_DATA',
  payload: data
});

export const setUserReservesData = (data) => ({
  type: 'ADD_USER_RESERVES_DATA',
  payload: data
});

export const setSuccessModalData = (data) => ({
  type: 'SET_SUCCESS_MODAL_DATA',
  payload: data
});
