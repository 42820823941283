import BigNumber from 'bignumber.js';
import { convertTokenAmountToETH } from './index';

const getBorrowLimitInfo = (
  tokenAddr,
  action,
  amount,
  tokenInfo,
  availableBorrowInETH,
  currentInETH,
  maxInETH
) => {
  if (Number(amount.toString()) === 0) {
    return {
      currentInETH,
      maxInETH,
      availableBorrowInETH
    };
  }
  const amountInETH = convertTokenAmountToETH(tokenAddr, amount, tokenInfo);
  const ltv = new BigNumber(tokenInfo.baseLTVasCollateral);
  if (action === 'supply') {
    maxInETH = maxInETH.plus(amountInETH.multipliedBy(ltv).div(10000));
    availableBorrowInETH = availableBorrowInETH.plus(amountInETH.multipliedBy(ltv).div(10000));
  } else if (action === 'withdraw') {
    maxInETH = maxInETH.minus(amountInETH.multipliedBy(ltv).div(10000));
    availableBorrowInETH = availableBorrowInETH.minus(amountInETH.multipliedBy(ltv).div(10000));
  } else if (action === 'borrow') {
    currentInETH = currentInETH.plus(amountInETH);
    availableBorrowInETH = availableBorrowInETH.minus(amountInETH);
  } else if (action === 'replay') {
    currentInETH = currentInETH.minus(amountInETH);
    availableBorrowInETH = availableBorrowInETH.plus(amountInETH);
  }
  return {
    currentInETH,
    maxInETH,
    availableBorrowInETH
  };
};

export default getBorrowLimitInfo;
