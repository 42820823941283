import TextWithImage from '../TextWithImage';
import AmountFromTo from '../AmountFromTo';
import { useIntl } from 'react-intl';

import styles from './index.module.scss';

import SuccessImg from '../../images/icons/success.svg';
import TPYToggle from '../TPYToggle';

const TransactionBox = ({
  supplyPercent,
  healthFactorPrice,
  liquidationAt,
  supplyTitle = 'Supply APY',
  className = '',
  from,
  isShowCollateralization = true,
  showIsETH = false,
  setIsETH,
  isETH = true
}) => {
  const intl = useIntl();

  return (
    <div className={`${className} ${styles['transaction-box']}`}>
      {showIsETH ? (
        <div className={`${styles['supply-section']} ${styles['flex-between']}`}>
          <TPYToggle
            sliderClassName={`${styles.toggle} ${isETH ? styles['toggle-slider'] : ''}`}
            checked={isETH}
            onChange={() => setIsETH((prevState) => !prevState)}
          />
          <p className={styles.title}>{isETH ? 'ETH' : 'WETH'}</p>
        </div>
      ) : null}
      <div className={`${styles['supply-section']} ${styles['flex-between']}`}>
        <p className={styles.title}>{supplyTitle}</p>
        <p className={styles['supply-percent']}>{supplyPercent}</p>
      </div>
      {isShowCollateralization && (
        <div className={`${styles['collateralization']} ${styles['flex-between']}`}>
          <p className={styles.title}>{intl.messages.collateralization}</p>
          <TextWithImage className={styles.success} text={intl.messages.Success}>
            <img src={SuccessImg} alt="" />
          </TextWithImage>
        </div>
      )}
      <div className={`${styles['health-factor']} ${styles['flex-between']}`}>
        <p className={styles.title}>{intl.messages.health_factor}</p>
        <AmountFromTo from={from} to={healthFactorPrice} factory={true} />
      </div>
      <p
        className={
          styles['liquidation-at']
        }>{`${intl.messages.liquidation_at} < ${liquidationAt}`}</p>
    </div>
  );
};

export default TransactionBox;
