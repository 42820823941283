import TPYButton from '../TPYButton';
import Loader from '../../Loader';

import styles from './index.module.css';

const ButtonWithLoader = ({
  children,
  onClick,
  className = '',
  title = '',
  isLoading = false,
  ...defaultButtonProps
}) => {
  return (
    <TPYButton
      onClick={onClick}
      className={`${className} ${styles['loader-button']}`}
      title={title}
      {...defaultButtonProps}>
      {isLoading && <Loader className={styles.loader} />}
      {children}
    </TPYButton>
  );
};

export default ButtonWithLoader;
