// Some values in this file just hardcoded and need to be refactored

import Web3 from 'web3';
import { getEthPriceNow } from 'get-eth-price';

import lendingPoolContractAbi from '../contractsJsonData/lendingPoolContractAbi.json';
import priceOracleContractAbi from '../contractsJsonData/priceOracleContractAbi.json';
import uIPoolDataProviderContractAbi from '../contractsJsonData/uIPoolDataProviderContractAbi.json';
import walletBalanceProviderContractAbi from '../contractsJsonData/walletBalanceProviderContract.json';
import protocolDataProviderContractAbi from '../contractsJsonData/protocolDataProviderContractAbi.json';
import wethGetwayContractAbi from '../contractsJsonData/WETHGetwayAbi.json';

import erc20DAIAbi from '../contractsJsonData/erc20DAIAbi.json';
import erc20TPYAbi from '../contractsJsonData/erc20TPYAbi.json';
import erc20USDTAbi from '../contractsJsonData/erc20USDTAbi.json';
import erc20WETHAbi from '../contractsJsonData/erc20WETHAbi.json';
import erc20SHIBAbi from '../contractsJsonData/erc20SHIBAbi.json';
import erc20USDCAbi from '../contractsJsonData/erc20USDCAbi.json';
import erc20WBTCAbi from '../contractsJsonData/erc20WBTCAbi.json';

import erc20AWETHAbi from '../contractsJsonData/erc20AWETHAbi.json';
import erc20AWETHStableAbi from '../contractsJsonData/erc20AWETHStableAbi.json';
import erc20AWETHVariableAbi from '../contractsJsonData/erc20AWETHVariableAbi.json';

import stableDebtAbi from '../contractsJsonData/stableDebtTokenAbi.json';
import variableDebtAbi from '../contractsJsonData/variableDebtTokenAbi.json';

import { calcAPYs, convertTokenAmountToETH, convertToNumber, getToken } from '../utilitis';
import { maxUint, ten, zero } from '../constants/numbers';
import BigNumber from 'bignumber.js';
import wadDiv from '../utilitis/wadDiv';
import percentMul from '../utilitis/percentMul';
import calculateAvailableBorrowsETH from '../utilitis/calculateAvailableBorrowsETH';
import TOKENS, { ETH_ADDRESS } from '../constants/tokens';
import { toFixed } from '../utilitis/math';
import { availableByToken } from '../utilitis/available';
import { addressesProvider, contractAddresses } from '../constants/contracts';

const erc20DAIAbiParsed = JSON.parse(erc20DAIAbi);
const erc20TPYAbiParsed = JSON.parse(erc20TPYAbi);
const erc20USDTAbiParsed = JSON.parse(erc20USDTAbi);
const erc20WETHAbiParsed = JSON.parse(erc20WETHAbi);
const erc20WBTCAbiParsed = erc20WBTCAbi;
const erc20USDCAbiParsed = JSON.parse(erc20USDCAbi);
const erc20SHIBAbiParsed = JSON.parse(erc20SHIBAbi);

const lendingPoolContractAbiParsed = lendingPoolContractAbi;
const priceOracleContractAbiParsed = priceOracleContractAbi;
const uIPoolDataProviderContractAbiParsed = uIPoolDataProviderContractAbi;
const walletBalanceProviderContractAbiParsed = JSON.parse(walletBalanceProviderContractAbi);
const protocolDataProviderContractAbiParsed = JSON.parse(protocolDataProviderContractAbi);
const wethGetwayContractAbiParsed = wethGetwayContractAbi;

const BN = Web3.utils.BN;

const REFERRAL_CODE = 0;

export const CONTRACTS = [
  {
    name: 'lendingPoolContract',
    isProxy: true,
    abi: lendingPoolContractAbiParsed,
    proxyAddress: contractAddresses.lendingPoolContract,
    contractAddress: contractAddresses.lendingPoolContract
  },
  {
    name: 'uIPoolDataProviderContract',
    isProxy: false,
    proxyAddress: null,
    abi: uIPoolDataProviderContractAbiParsed,
    contractAddress: contractAddresses.uIPoolDataProviderContract
  },
  {
    name: 'priceOracleContract',
    isProxy: false,
    proxyAddress: null,
    abi: priceOracleContractAbiParsed,
    contractAddress: contractAddresses.priceOracleContract
  },
  {
    name: 'walletBalanceProviderContract',
    isProxy: false,
    proxyAddress: null,
    abi: walletBalanceProviderContractAbiParsed,
    contractAddress: contractAddresses.walletBalanceProviderContract
  },
  {
    name: 'protocolDataProviderContract',
    isProxy: false,
    proxyAddress: null,
    abi: protocolDataProviderContractAbiParsed,
    contractAddress: contractAddresses.protocolDataProviderContract
  },
  {
    name: 'wethGetwayContract',
    isProxy: false,
    proxyAddress: null,
    abi: wethGetwayContractAbiParsed,
    contractAddress: contractAddresses.wethGetwayContract
  }
];

export const getContracts = async () => {
  try {
    const web3 = new Web3('https://eth-mainnet.g.alchemy.com/v2/gU9NwU6R2leMp9THFmEJzPnAE2ic9mI3');
    web3.eth.handleRevert = true;

    return CONTRACTS.reduce((currentContractsArr, contract, index) => {
      const { abi, name, isProxy, proxyAddress, contractAddress } = contract;
      currentContractsArr[index] = {
        [name]: new web3.eth.Contract(abi, isProxy ? proxyAddress : contractAddress)
      };
      return currentContractsArr;
    }, []);
  } catch (e) {
    console.log(e);
  }
  // Init web3 provider for calling blockchain contracts
};

export const getEthPriceInUsd = async () => {
  const res = await getEthPriceNow();

  return Object.values(res)[0].ETH.USD;
};

export const getUserDashboardInfo = async (contract, walletAddress) => {
  try {
    // Get user account data
    const userAccountData = await contract.methods.getUserAccountData(walletAddress).call();
    // TODO
    // console.log('userAccountData >>>>>>>>>>', userAccountData);

    // Create response model
    const necessaryFileds = [
      'healthFactor',
      'totalDebtETH',
      'totalCollateralETH',
      'availableBorrowsETH'
    ];

    return necessaryFileds.reduce((currentObj, fieldName) => {
      return {
        ...currentObj,
        [fieldName]: Web3.utils.fromWei(BN(userAccountData[fieldName]).toString(), 'ether')
      };
    }, {});
  } catch (err) {
    console.log('getUserDashboardInfo Error', err);
  }
};

export const getReserveData = async (
  uIPoolDataProviderContract,
  walletBalanceProviderContract,
  priceOracleContract,
  walletAddress,
  provider,
  lendingPoolContract
) => {
  try {
    const reservesData = await uIPoolDataProviderContract.methods
      .getReservesData(addressesProvider)
      .call();

    const tokenAddresses = [];
    let baseCurrencyInfo = reservesData[1];
    const tokenInfosByAddress = {};
    for (const reserveData of reservesData[0]) {
      const underlyingAsset = reserveData.underlyingAsset;
      tokenAddresses.push(underlyingAsset);
      tokenInfosByAddress[underlyingAsset] = { ...reserveData };
      tokenInfosByAddress[underlyingAsset].aToken = getERC20TokenContractBySymbol(
        provider,
        reserveData.symbol,
        reserveData.aTokenAddress
      );
      tokenInfosByAddress[underlyingAsset].aTokenTotal = await tokenInfosByAddress[
        underlyingAsset
      ].aToken.methods
        .totalSupply()
        .call();

      tokenInfosByAddress[underlyingAsset].availableBalance = await availableByToken(
        priceOracleContract,
        reserveData.symbol === 'ETH' ? getToken('WETH').address : underlyingAsset,
        lendingPoolContract,
        walletBalanceProviderContract,
        walletAddress
      );
      tokenInfosByAddress[underlyingAsset].stableDebtToken = getDeptTokenContract(
        provider,
        stableDebtAbi,
        reserveData.stableDebtTokenAddress
      );
      tokenInfosByAddress[underlyingAsset].variableDebtToken = getDeptTokenContract(
        provider,
        variableDebtAbi,
        reserveData.variableDebtTokenAddress
      );

      [
        tokenInfosByAddress[underlyingAsset].stableDebtPrincipalTotal,
        tokenInfosByAddress[underlyingAsset].stableDebtTokenTotal
      ] = Object.values(
        await tokenInfosByAddress[underlyingAsset].stableDebtToken.methods.getSupplyData().call()
      );

      tokenInfosByAddress[underlyingAsset].variableDebtTokenTotal = await tokenInfosByAddress[
        underlyingAsset
      ].variableDebtToken.methods
        .totalSupply()
        .call();

      tokenInfosByAddress[underlyingAsset].variableDebtTokenScaledTotal = await tokenInfosByAddress[
        underlyingAsset
      ].variableDebtToken.methods
        .scaledTotalSupply()
        .call();

      // tokenInfosByAddress[underlyingAsset].interestRateStrategy = await ethers.getContractAt(
      //     'DefaultReserveInterestRateStrategy',
      //     reserveData.interestRateStrategyAddress
      // );
      //
      const balance = await walletBalanceProviderContract.methods
        .balanceOf(walletAddress, reserveData.underlyingAsset)
        .call();
      tokenInfosByAddress[underlyingAsset].realBalance = balance.toString();

      tokenInfosByAddress[underlyingAsset].balance = toFixed(
        balance * 10 ** -reserveData.decimals,
        2
      );
      tokenInfosByAddress[underlyingAsset].networkBaseTokenPriceInUsd =
        baseCurrencyInfo.networkBaseTokenPriceInUsd;

      tokenInfosByAddress[underlyingAsset].allowance = await walletBalanceProviderContract.methods
        .getAllowance(
          walletAddress,
          contractAddresses.lendingPoolContract,
          reserveData.underlyingAsset
        )
        .call();

      // const apyData = calcAPYs(tokenInfosByAddress[underlyingAsset]);
      // tokenInfosByAddress[underlyingAsset].supplyAPY = apyData.depositAPY;
      // tokenInfosByAddress[underlyingAsset].variableBorrowAPY = apyData.variableBorrowAPY;
      // tokenInfosByAddress[underlyingAsset].stableBorrowAPY = apyData.stableBorrowAPY;
    }
    const web3 = new Web3(provider);
    const ethBalance = await web3.eth.getBalance(walletAddress);
    //
    let allowance = 0;
    let variableAllowance = 0;
    let stableAllowance = 0;
    let contract;
    try {
      contract = getERC20TokenContractBySymbol(
        provider,
        'AWETH',
        tokenInfosByAddress[getToken('WETH').address].aTokenAddress
      );
      const contractVariable = getERC20TokenContractBySymbol(
        provider,
        'AWETHV',
        tokenInfosByAddress[getToken('WETH').address].variableDebtTokenAddress
      );
      const contractStable = getERC20TokenContractBySymbol(
        provider,
        'AWETHS',
        tokenInfosByAddress[getToken('WETH').address].stableDebtTokenAddress
      );
      allowance = await contract.methods
        .allowance(walletAddress, CONTRACTS[5].contractAddress)
        .call();
      variableAllowance = await contractVariable.methods
        .borrowAllowance(walletAddress, CONTRACTS[5].contractAddress)
        .call();
      stableAllowance = await contractStable.methods
        .borrowAllowance(walletAddress, CONTRACTS[5].contractAddress)
        .call();

      tokenInfosByAddress[getToken('ETH').address] = {
        ...tokenInfosByAddress[getToken('WETH').address],
        balanceWETH: convertToNumber(
          tokenInfosByAddress[getToken('WETH').address].realBalance,
          18,
          false
        ),
        balance: convertToNumber(ethBalance, 18, false),
        underlyingAsset: ETH_ADDRESS,
        symbol: 'ETH',
        allowanceEth: allowance,
        variableAllowance: variableAllowance,
        stableAllowance: stableAllowance
      };
      tokenInfosByAddress[getToken('WETH').address] = {
        ...tokenInfosByAddress[getToken('WETH').address],
        allowanceEth: allowance,
        balanceETH: convertToNumber(ethBalance, 18, false),
        variableAllowance: variableAllowance,
        stableAllowance: stableAllowance
      };
    } catch (e) {
      console.log(e, '---');
    }
    return tokenInfosByAddress;
  } catch (err) {
    console.log('getReserveData Error!!!!!!!!!!!', err);
  }
};

export const approveAllowance = async (
  provider,
  walletAddress,
  symbol,
  address = contractAddresses.lendingPoolContract,
  tokenAddr = '',
  isEth = false
) => {
  const contract = getERC20TokenContractBySymbol(provider, symbol, tokenAddr);
  if (isEth) {
    if (symbol === 'AWETH') {
      return await contract.methods
        .approve(
          address,
          new Web3.utils.BN('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
        )
        .send({ from: walletAddress });
    }
    return await contract.methods
      .approveDelegation(
        address,
        new Web3.utils.BN('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
      )
      .send({ from: walletAddress });
  }
  return await contract.methods
    .approve(
      address,
      new Web3.utils.BN('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
    )
    .send({ from: walletAddress });
};

export const supplyToken = async (
  isETH,
  provider,
  amount,
  walletAddress,
  assetAddress,
  tokenInfo
) => {
  amount = new BigNumber(amount).multipliedBy(ten.pow(tokenInfo?.decimals)).toFixed(0);
  const web3 = new Web3(provider);
  web3.eth.handleRevert = true;
  if (isETH) {
    const contract = new web3.eth.Contract(
      wethGetwayContractAbiParsed,
      CONTRACTS[5].contractAddress
    );
    return await contract.methods
      .depositETH(contractAddresses.lendingPoolContract, walletAddress, 0)
      .send({ value: amount, from: walletAddress });
  }
  const contract = new web3.eth.Contract(
    lendingPoolContractAbiParsed,
    contractAddresses.lendingPoolContract
  );
  return await contract.methods
    .deposit(
      tokenInfo.symbol === 'ETH' ? getToken('WETH').address : assetAddress,
      amount,
      walletAddress,
      REFERRAL_CODE
    )
    .send({ from: walletAddress });
};

const getERC20TokenContractBySymbol = (provider, symbol, address = '') => {
  // Init web3 provider for calling blockchain contracts
  const web3 = new Web3(provider);
  web3.eth.handleRevert = true;

  // eslint-disable-next-line default-case
  switch (symbol) {
    case 'TPY':
      return new web3.eth.Contract(erc20TPYAbiParsed, address || TOKENS.TPY.address);
    case 'WETH':
      return new web3.eth.Contract(erc20WETHAbiParsed, address || TOKENS.WETH.address);
    case 'USDT':
      return new web3.eth.Contract(erc20USDTAbiParsed, address || TOKENS.USDT.address);
    case 'DAI':
      return new web3.eth.Contract(erc20DAIAbiParsed, address || TOKENS.DAI.address);
    case 'WBTC':
      return new web3.eth.Contract(erc20WBTCAbiParsed, address || TOKENS.WBTC.address);
    case 'USDC':
      return new web3.eth.Contract(erc20USDCAbiParsed, address || TOKENS.USDC.address);
    case 'SHIB':
      return new web3.eth.Contract(erc20SHIBAbiParsed, address || TOKENS.SHIB.address);
    case 'AWETH':
      return new web3.eth.Contract(erc20AWETHAbi, address);
    case 'AWETHV':
      return new web3.eth.Contract(erc20AWETHVariableAbi, address);
    case 'AWETHS':
      return new web3.eth.Contract(erc20AWETHStableAbi, address);
  }
};

const getDeptTokenContract = (provider, eDebtAbi, address = '') => {
  // Init web3 provider for calling blockchain contracts
  const web3 = new Web3(provider);
  web3.eth.handleRevert = true;
  return new web3.eth.Contract(eDebtAbi, address);
};

const getUserTokensBalances = async (
  userAddr,
  tokenInfosByAddress,
  tokensAddr,
  walletBalanceProviderContract
) => {
  const balArr = [];
  for (const tokenAddr of tokensAddr) {
    try {
      balArr.push(tokenAddr);
      balArr.push(tokenInfosByAddress[tokenAddr].aTokenAddress);
      balArr.push(tokenInfosByAddress[tokenAddr].stableDebtTokenAddress);
      balArr.push(tokenInfosByAddress[tokenAddr].variableDebtTokenAddress);
    } catch (e) {
      console.log(e, '----');
    }
  }
  const retObj = {};
  const balances = await walletBalanceProviderContract.methods
    .batchBalanceOf([userAddr], balArr)
    .call();
  let i = 0;
  for (const tokenAddr of tokensAddr) {
    retObj[tokenAddr] = [balances[i], balances[i + 1], balances[i + 2], balances[i + 3]];
    i += 4;
  }
  return retObj;
};

export const getUserReservesData = async (
  uIPoolDataProviderContract,
  walletBalanceProviderContract,
  priceOracleContract,
  walletAddress,
  tokenInfosByAddress,
  provider
) => {
  try {
    // Get reserve account data
    const userReservesData = await uIPoolDataProviderContract.methods
      .getUserReservesData(addressesProvider, walletAddress)
      .call();

    let userInfosByTokenAddress = {};
    const userGeneralData = {};
    const tokensAddr = [];
    userReservesData.forEach((userReserveData) => {
      const underlyingAsset = userReserveData.underlyingAsset;
      tokensAddr.push(userReserveData.underlyingAsset);
      userInfosByTokenAddress[underlyingAsset] = { ...userReserveData };
      if (userReserveData.underlyingAsset === getToken('WETH').address) {
        tokensAddr.push(getToken('ETH').address);
        userInfosByTokenAddress[getToken('ETH').address] = {
          ...userReserveData,
          underlyingAsset: ETH_ADDRESS
        };
      }
    });
    userGeneralData['suppliedTokens'] = Object.values(userInfosByTokenAddress);
    let supplyInETH = zero;
    let collateralInETH = zero;
    let debtInETH = zero;
    let ltvWeightSum = zero;
    let liqThrWeightSum = zero;
    let netAPY = 0;
    const tokensBalances = await getUserTokensBalances(
      walletAddress,
      tokenInfosByAddress,
      tokensAddr,
      walletBalanceProviderContract
    );

    for (const tokenAddr of tokensAddr) {
      const userInfo = userInfosByTokenAddress[tokenAddr];

      const tokenInfo = tokenInfosByAddress[tokenAddr];

      userInfo.assetBal = new BigNumber(tokensBalances[tokenAddr][0]);
      userInfo.aTokenBal = new BigNumber(tokensBalances[tokenAddr][1]);
      userInfo.stableDebtBal = new BigNumber(tokensBalances[tokenAddr][2]);
      userInfo.variableDebtBal = new BigNumber(tokensBalances[tokenAddr][3]);
      const apyData = calcAPYs(tokenInfo, userInfo.stableBorrowRate);
      tokenInfo.supplyAPY = apyData.depositAPY;
      tokenInfo.variableBorrowAPY = apyData.variableBorrowAPY;
      tokenInfo.stableBorrowAPY = apyData.stableBorrowAPY;
      userInfo.userStableBorrowAPY = apyData.userStableBorrowAPY;
      if (
        userInfo.aTokenBal.isZero() &&
        userInfo.stableDebtBal.isZero() &&
        userInfo.variableDebtBal.isZero()
      ) {
        continue;
      }

      const tokenUnit = ten.pow(tokenInfo.decimals);
      const reserveUnitPrice = new BigNumber(tokenInfo.priceInMarketReferenceCurrency);
      const liquidityBalanceETH = reserveUnitPrice.multipliedBy(userInfo.aTokenBal).div(tokenUnit);

      userInfosByTokenAddress[tokenAddr].liquidityBalanceETH = liquidityBalanceETH;
      if (tokenAddr !== ETH_ADDRESS) {
        supplyInETH = supplyInETH.plus(liquidityBalanceETH);
        // change depositAPY to supplyAPY
        netAPY += liquidityBalanceETH.toNumber() * tokenInfo.supplyAPY;

        if (
          !new BigNumber(tokenInfo.reserveLiquidationThreshold).isZero() &&
          userInfo?.usageAsCollateralEnabledOnUser
        ) {
          collateralInETH = collateralInETH.plus(liquidityBalanceETH);
          ltvWeightSum = ltvWeightSum.plus(
            liquidityBalanceETH.multipliedBy(tokenInfo.baseLTVasCollateral)
          );
          liqThrWeightSum = liqThrWeightSum.plus(
            liquidityBalanceETH.multipliedBy(tokenInfo.reserveLiquidationThreshold)
          );
        }
        if (!userInfo.stableDebtBal.isZero() || !userInfo.variableDebtBal.isZero()) {
          netAPY -=
            convertTokenAmountToETH(tokenAddr, userInfo.stableDebtBal, tokenInfo).toNumber() *
            userInfo.userStableBorrowAPY;
          netAPY -=
            convertTokenAmountToETH(tokenAddr, userInfo.variableDebtBal, tokenInfo).toNumber() *
            tokenInfo.variableBorrowAPY;
          debtInETH = debtInETH.plus(
            reserveUnitPrice
              .multipliedBy(userInfo.stableDebtBal.plus(userInfo.variableDebtBal))
              .div(tokenUnit)
          );
        }
      }
      userInfosByTokenAddress[tokenAddr] = { ...userInfo };
    }

    netAPY /= supplyInETH.toNumber();
    const web3 = new Web3(provider);
    const nativeBal = new BigNumber(await web3.eth.getBalance(walletAddress));
    const avgLtv = collateralInETH.isZero() ? zero : ltvWeightSum.div(collateralInETH);
    const avgLiquidationThreshold = collateralInETH.isZero()
      ? zero
      : liqThrWeightSum.div(collateralInETH);
    const healthFactor = wadDiv(percentMul(collateralInETH, avgLiquidationThreshold), debtInETH);
    const availableBorrowInETH = calculateAvailableBorrowsETH(collateralInETH, debtInETH, avgLtv);

    userGeneralData.userAddr = walletAddress;
    userGeneralData.nativeBal = nativeBal;
    userGeneralData.supplyInETH = supplyInETH;
    userGeneralData.collateralInETH = collateralInETH;
    userGeneralData.debtInETH = debtInETH;
    userGeneralData.ltvWeightSum = ltvWeightSum;
    userGeneralData.liqThrWeightSum = liqThrWeightSum;
    userGeneralData.avgLtv = avgLtv;
    userGeneralData.avgLiquidationThreshold = avgLiquidationThreshold;
    userGeneralData.healthFactor = healthFactor;
    userGeneralData.availableBorrowInETH = availableBorrowInETH;
    userGeneralData.netAPY = netAPY;
    userGeneralData.userInfosByTokenAddress = userInfosByTokenAddress;
    return userGeneralData;
  } catch (err) {
    console.log('getReserveData Error', err);
  }
};

export const doAWithdraw = async (
  isETH,
  provider,
  userAddr,
  tokenAddr,
  tokenInfo,
  amountToWithdraw
) => {
  if (amountToWithdraw !== maxUint) {
    amountToWithdraw = new BigNumber(amountToWithdraw)
      .multipliedBy(ten.pow(tokenInfo.decimals))
      .toFixed(0);
  }
  const web3 = new Web3(provider);
  web3.eth.handleRevert = true;
  if (isETH) {
    const contract = new web3.eth.Contract(
      wethGetwayContractAbiParsed,
      CONTRACTS[5].contractAddress
    );
    return await contract.methods
      .withdrawETH(contractAddresses.lendingPoolContract, amountToWithdraw, userAddr)
      .send({ from: userAddr });
    // check allowance. if not allowance than approve
    // const allowance = BigNumber.from(await userInfo.aToken.allowance(userAddr, wethGateway.address));
    // if (allowance.lt(amountToWithdraw)) {
    //   await userInfo.aToken.approve(wethGateway.address, new Web3.utils.BN('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'));
    // }
    // // withdraw ETH via weth gateway
    // await wethGateway.withdrawETH(lendingPool.address, amountToWithdraw, userAddr);
  } else {
    const contract = new web3.eth.Contract(
      lendingPoolContractAbiParsed,
      contractAddresses.lendingPoolContract
    );
    return await contract.methods
      .withdraw(
        tokenInfo.symbol === 'ETH' ? getToken('WETH').address : tokenInfo.underlyingAsset,
        amountToWithdraw,
        userAddr
      )
      .send({ from: userAddr });
  }
};

export const doABorrow = async (
  isETH,
  userAddr,
  provider,
  tokenInfo,
  borrowMode,
  amountToBorrow,
  availableBorrowInETH
) => {
  amountToBorrow = new BigNumber(amountToBorrow)
    .multipliedBy(ten.pow(tokenInfo.decimals))
    .toFixed(0);
  const amountInETH = convertTokenAmountToETH(tokenInfo.underlyingAsset, amountToBorrow, tokenInfo);
  if (amountInETH.gt(availableBorrowInETH)) {
    throw new Error('cannot borrow that amount');
  }
  const web3 = new Web3(provider);
  web3.eth.handleRevert = true;

  const contract = new web3.eth.Contract(
    lendingPoolContractAbiParsed,
    contractAddresses.lendingPoolContract
  );
  if (isETH) {
    const contract = new web3.eth.Contract(
      wethGetwayContractAbiParsed,
      CONTRACTS[5].contractAddress
    );
    return await contract.methods
      .borrowETH(contractAddresses.lendingPoolContract, amountToBorrow, borrowMode, 0)
      .send({ from: userAddr });
  } else {
    return await contract.methods
      .borrow(
        tokenInfo.symbol === 'ETH' ? getToken('WETH').address : tokenInfo.underlyingAsset,
        amountToBorrow,
        borrowMode,
        0,
        userAddr
      )
      .send({ from: userAddr });
  }
};

export const doARepay = async (
  isETH,
  userAddr,
  tokenInfo,
  userInfos,
  mode,
  amountToRepay,
  provider,
  currentAmount
) => {
  if (amountToRepay !== maxUint) {
    amountToRepay = new BigNumber(amountToRepay)
      .multipliedBy(ten.pow(tokenInfo.decimals))
      .toFixed(0);
  }
  if (currentAmount) {
    currentAmount = new BigNumber(currentAmount).lt(0.001)
      ? new BigNumber(currentAmount).multipliedBy(1.01)
      : new BigNumber(currentAmount).plus(0.001);
    currentAmount = currentAmount.multipliedBy(ten.pow(tokenInfo.decimals)).toFixed(0);
  }
  const web3 = new Web3(provider);
  web3.eth.handleRevert = true;
  if (isETH) {
    const contract = new web3.eth.Contract(
      wethGetwayContractAbiParsed,
      CONTRACTS[5].contractAddress
    );
    return await contract.methods
      .repayETH(contractAddresses.lendingPoolContract, amountToRepay, mode, userAddr)
      .send({ value: currentAmount, from: userAddr });
  } else {
    // check allowance. if not allowance than approve
    // if (amountToRepay !== maxUint && new BigNumber(tokenInfo.allowance).lt(amountToRepay)) {
    //   const contract = getERC20TokenContractBySymbol(provider, tokenInfo.symbol);
    //   await contract.methods
    //     .approve(
    //       contractAddresses.lendingPoolContract,
    //       new Web3.utils.BN('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
    //     )
    //     .send({ from: walletAddress });
    // }
    const contract = new web3.eth.Contract(
      lendingPoolContractAbiParsed,
      contractAddresses.lendingPoolContract
    );
    return await contract.methods
      .repay(
        tokenInfo.symbol === 'ETH' ? getToken('WETH').address : tokenInfo.underlyingAsset,
        amountToRepay,
        mode,
        userAddr
      )
      .send({ from: userAddr });
  }
};

export const disableOrEnableCollateral = async (
  walletAddress,
  provider,
  tokenInfo,
  useAsCollateral
) => {
  const web3 = new Web3(provider);
  web3.eth.handleRevert = true;

  const contract = new web3.eth.Contract(
    lendingPoolContractAbiParsed,
    contractAddresses.lendingPoolContract
  );

  const accounts = await web3.eth.requestAccounts();

  return await contract.methods
    .setUserUseReserveAsCollateral(
      tokenInfo.symbol === 'ETH' ? getToken('WETH').address : tokenInfo.underlyingAsset,
      useAsCollateral
    )
    .send({ from: accounts[0] });
};

export const changeBorrowRateMode = async (provider, walletAddress, assetAddress, rateMode) => {
  const web3 = new Web3(provider);
  web3.eth.handleRevert = true;

  const contract = new web3.eth.Contract(
    lendingPoolContractAbiParsed,
    contractAddresses.lendingPoolContract
  );

  return await contract.methods
    .swapBorrowRateMode(assetAddress, rateMode)
    .send({ from: walletAddress });
};
export const getUserAvailableETH = async (
  lendingPoolContract,
  walletBalanceProviderContract,
  priceOracleContract,
  walletAddress
) => {
  try {
    const userReservesData = await lendingPoolContract.methods
      .getUserAccountData(walletAddress)
      .call();
    return userReservesData.availableBorrowsETH;
  } catch (err) {
    console.log('getReserveData Error', err);
  }
};
export const getAssetPrice = async (priceOracleContract, tokenAddr) => {
  try {
    const assetPrice = await priceOracleContract.methods.getAssetPrice(tokenAddr).call();
    return assetPrice;
  } catch (e) {}
};

export const getTokenBalance = async (walletBalanceProviderContract, userAddr, tokenAdr) => {
  try {
    const balance = await walletBalanceProviderContract.methods
      .balanceOf(userAddr, tokenAdr)
      .call();
    return balance;
  } catch (e) {}
};
