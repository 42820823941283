import { useIntl } from 'react-intl';
import WarningText from '../WarningText';

import styles from './index.module.scss';

function ProgressBar({ progressValue = 0, showWarnings, className = '', healthFactor = 0 }) {
  const intl = useIntl();

  const checkColor = () => {
    return healthFactor <= 1.3 ? 'red' : healthFactor <= 1.6 ? 'yellow' : 'green';
  };
  // const linearBackgroundColor = () => {
  //   if (progressValue > 50 && progressValue <= 80) {
  //     return `linear-gradient(90deg, #51CC73 0%, #FBCC5F 100.13%)`;
  //   } else if (progressValue >= 80) {
  //     return `linear-gradient(90deg, #51CC73 0%, #FBCC5F 60%, #FC4A4A 80%)`;
  //   }
  // };

  return (
    <div className={`${className} ${styles.progressBarBox}`}>
      {showWarnings && (
        <>
          <WarningText
            additionalClass={checkColor()}
            className={`${styles.barWarning} ${styles.firstWarning}`}
            text={`${intl.messages.health_factor}: ${healthFactor.toString()}`}
            warningText={intl.messages.warning_Text_Health_Factor}
          />
          {/*<WarningText*/}
          {/*  className={`${styles.barWarning}  ${styles.secondWarning}`}*/}
          {/*  text={intl.messages.high_risk}*/}
          {/*/>*/}
          {/*<WarningText*/}
          {/*  className={`${styles.barWarning}  ${styles.lastWarning}`}*/}
          {/*  text={intl.messages.liqudation}*/}
          {/*/>*/}
        </>
      )}
      {/*<div className={styles.bar}>*/}
      {/*  <span style={{ width: `${progressValue}%`, background: linearBackgroundColor() }} />*/}
      {/*</div>*/}
    </div>
  );
}

export default ProgressBar;
