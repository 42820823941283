import { memo, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { themes, ThemeContext, getInitialTheme } from '../../contexts/ThemeContext';
import ConnectWalletButton from '../ConnectWalletButton';
import { languages } from '../../lang/locales';
import TPYToggle from '../TPYToggle';
import TPYSelect from '../TSelect';
import useWindowSize from '../../hooks/useWindowSize';
import HeaderMobileMenu from '../HeaderMoblleMenu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreMenuDropdown from '../MoreMenuDropdown';
import { useIntl } from 'react-intl';

import './index.scss';

import LogoWhite from '../../images/logos/thrupenny-logo-light.png';
import LogoDark from '../../images/logos/thrupenny-logo.png';
import Sun from '../../images/icons/sun.svg';
import Moon from '../../images/icons/moon.svg';

function Header({ currentLocale, onChangeLanguage }) {
  const intl = useIntl();

  const [darkMode, setDarkMode] = useState(getInitialTheme() === themes.dark);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const pathname = useLocation().pathname;

  const navigationItems = useMemo(() => {
    return [
      {
        to: 'dashboard',
        title: intl.messages.dashboard
      },
      {
        to: 'market',
        title: intl.messages.market
      },
      // {
      //   to: 'stake',
      //   title: intl.messages.stake
      // },
      // {
      //   to: 'governance',
      //   title: intl.messages.governance
      // }
    ];
  }, [
    intl.messages.dashboard,
    // intl.messages.governance,
    intl.messages.market,
    // intl.messages.stake
  ]);

  const languagesItems = useMemo(() => {
    return languages.map(({ name, code }) => ({
      id: code,
      value: name
    }));
  }, []);

  const { width } = useWindowSize();

  const handleChangeLanguage = (event) => {
    onChangeLanguage(event);
  };

  const handleChangeTheme = (callback) => {
    setDarkMode(!darkMode);
    callback(darkMode ? themes.light : themes.dark);
    localStorage.setItem('theme', darkMode ? themes.light : themes.dark);
  };

  function handleToggleMenu() {
    setIsMobileMenuOpen((isOpen) => !isOpen);
  }

  const isTabletSize = width <= 1150;

  return (
    <header className="header container">
      <div className="logo-with-header">
        <Link to="/">
          <img className="logo" src={darkMode ? LogoDark : LogoWhite} alt="Logo" />
        </Link>
        {!isTabletSize && (
          <nav className="navbar">
            {navigationItems.map((item) => (
              <Link
                key={item.to}
                className={`navigation-item ${
                  pathname === '/' + item.to || (pathname === '/' && item.to === 'dashboard')
                    ? 'active'
                    : ''
                }`}
                to={item.to}>
                {item.title}
              </Link>
            ))}
            <MoreMenuDropdown />
          </nav>
        )}
      </div>
      <div className="actions">
        <ConnectWalletButton />
        {!isTabletSize && (
          <>
            <TPYSelect
              IconComponent={ExpandMoreIcon}
              options={languagesItems}
              value={currentLocale}
              onChange={handleChangeLanguage}
              displayPropertyName="value"
            />
            <ThemeContext.Consumer>
              {({ changeTheme }) => (
                <>
                  <TPYToggle
                    imgSrc={!darkMode ? Sun : Moon}
                    toggleText={darkMode ? intl.messages.off : intl.messages.on}
                    checked={!darkMode}
                    onChange={() => handleChangeTheme(changeTheme)}
                  />
                </>
              )}
            </ThemeContext.Consumer>
          </>
        )}
        {isTabletSize && (
          <>
            <svg
              onClick={handleToggleMenu}
              className="more-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z" fill="#FCFCFF" />
            </svg>
            {isMobileMenuOpen && (
              <HeaderMobileMenu
                logoSrc={darkMode ? LogoDark : LogoWhite}
                navigationItems={navigationItems}
                languagesItems={languagesItems}
                currentLocale={currentLocale}
                onChangeLanguage={handleChangeLanguage}
                onChangeTheme={handleChangeTheme}
                isDarkMode={darkMode}
                isDarKMode={darkMode}
                onClose={handleToggleMenu}
              />
            )}
          </>
        )}
      </div>
    </header>
  );
}

export default memo(Header);
