import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import TextWithImage from '../TextWithImage';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Banner from '../Banner';
import ItemWithTitleAndDescription from '../ItemWithTitleAndDescription';
import WalletIcon from '../WalletIcon';
import ShareIcon from '../ShareIcon';
import IconWithBackground from '../IconWithBackground';
import InfoBanner from '../InfoBanner';
import WarningBanner from '../WarningBanner';
import TitleWithValueAndWarningIcon from '../TitleWithValueAndWarningIcon';
import TPYButton from '../buttons/TPYButton';
import TextWithHighlightedDot from '../TextWithHighlightedDot';
import useChartData from '../../hooks/useChartData';
import { ThemeContext } from '../../contexts/ThemeContext';
import useWindowSize from '../../hooks/useWindowSize';
import Tabs from '../Tabs';
import TabItem from '../TabItem';
import SupplyWithdrawModal from '../SupplyWithdrawModal';
import BorrowRepayModal from '../BorrowRepayModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { convertMarketCurrencyPriceToUSD } from '../../utilitis';
import './index.scss';
import { nFormatter } from '../../utilitis/math';
import Ethereum from '../../images/coins/ethereum.svg';
import blueWarning from '../../images/icons/blue-warning.svg';
import SuccessImg from '../../images/icons/success.svg';
import SuccessPopup from '../SuccessModal';
import { getReserveData, getUserReservesData } from '../../services/Web3';
import { setReservesData, setUserReservesData } from '../../redux/actions/reserveActions';

const DashboardDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const [isToggleSuccessPopup, setIsToggleSuccessPopup] = useState({
    isSuccessPopupModalOpen: false,
    showAddToWalletSection: true
  });
  const userReservesData = useSelector((state) => state.reserveReducer.userReservesData);
  const walletAddress = useSelector((state) => state.wallet.selectedAddress);

  const availableLiquidity = BigNumber(state.asset.totalSuppliedInUsd).minus(
    BigNumber(state.asset.totalBorrowedInUsd)
  );
  const intl = useIntl();

  const price = convertMarketCurrencyPriceToUSD(
    state.asset.priceInMarketReferenceCurrency,
    state.asset.networkBaseTokenPriceInUsd
  );

  const availableSupplyInToken = BigNumber(state.asset.availableSupplyInEth).dividedBy(
    BigNumber(state.asset.priceInMarketReferenceCurrency)
  );
  const availableBorrowInToken = BigNumber(state.asset.availableBorrowInEth).dividedBy(
    BigNumber(state.asset.priceInMarketReferenceCurrency)
  );
  const balance = state.asset.balance;
  const balanceInUsd = price.multipliedBy(balance);
  const utilizationRate = BigNumber(state.asset.totalBorrowed)
    .dividedBy(state.asset.totalSupplied)
    .multipliedBy(100);
  const maxLtv = BigNumber(state.asset.baseLTVasCollateral).dividedBy(100);
  const reserveLiquidationThreshold = BigNumber(state.asset.reserveLiquidationThreshold).dividedBy(
    100
  );
  const reserveLiquidationBonus = BigNumber(state.asset.reserveLiquidationBonus)
    .minus(10000)
    .dividedBy(100);

  const reserveFactor = BigNumber(state.asset.reserveFactor).dividedBy(100);
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { width } = useWindowSize();

  const [activeTab, setActiveTab] = useState('overview');
  const [supplyWithdrawModalState, setSupplyWithdrawModalState] = useState({
    isWithdrawSupplyModalOpen: false,
    activeTab: 'supply',
    tabData: {}
  });
  const [repayBorrowModalState, setRepayBorrowModalState] = useState({
    isRepayBorrowModalOpen: false,
    activeTab: 'borrow',
    tabData: {}
  });

  function toggleWithdrawSupplyModal(activeTab = '', tabData) {
    setSupplyWithdrawModalState((prevState) => {
      return {
        ...prevState,
        isWithdrawSupplyModalOpen: !prevState.isWithdrawSupplyModalOpen,
        activeTab,
        tabData
      };
    });
  }

  function toggleRepayBorrowModal(activeTab = 'borrow', tabData) {
    setRepayBorrowModalState((prevState) => {
      return {
        ...prevState,
        activeTab,
        isRepayBorrowModalOpen: !prevState.isRepayBorrowModalOpen,
        tabData
      };
    });
  }

  const mainInfo = [
    {
      title: intl.messages.reserve_size,
      value: `$ ${nFormatter(state.asset.totalSuppliedInUsd, 2)}`
    },
    {
      title: intl.messages.available_liquidity,
      value: `$ ${nFormatter(availableLiquidity, 2)}`
    },
    {
      title: intl.messages.utilization_rate,
      value: `${nFormatter(utilizationRate, 2)} %`
    },
    {
      title: intl.messages.oracle_price,
      value: `$ ${nFormatter(price, 2)}`,
      href: '/'
    }
  ];

  const config = useChartData({
    theme,
    labels: [0, 1, 2, 3, 4],
    datasets: [
      {
        data: [2.83, 2.148, 2.15, 2.2, 2.23],
        borderColor: '#51cc73'
      }
    ]
  });

  const config1 = useChartData({
    theme,
    labels: [0, 1, 2, 3, 4],
    datasets: [
      {
        data: [2.83, 2.148, 2.15, 2.2, 2.23],
        borderColor: '#fc4a4a'
      }
    ]
  });

  const config2 = useChartData({
    theme,
    labels: [0, 1, 2, 3, 4],
    datasets: [
      {
        data: [1, 2, 3, 4, 5],
        borderColor: '#a49fff'
      },
      {
        data: [2.83, 2.148, 2.15, 2.2, 2.23],
        borderColor: '#51cc73'
      },
      {
        data: [7, 8, 9, 10, 11],
        borderColor: '#fc4a4a'
      }
    ]
  });

  function handleBackButtonClick(item) {
    navigate('/market', { replace: true });
  }
  const [loading, setLoading] = useState(false);
  const isMobileSize = width <= 768;
  const isActiveTabOverview = activeTab === 'overview';
  const isActiveTabYourInfo = activeTab === 'yourInfo';
  const isYourInfoSectionVisible = !isMobileSize || isActiveTabYourInfo;
  const isOverviewSectionVisible = !isMobileSize || isActiveTabOverview;
  const uIPoolDataProviderContract = useSelector(
    (state) => state.contractsReducer.uIPoolDataProiderContract
  );
  const walletBalanceProviderContract = useSelector(
    (state) => state.contractsReducer.walletBalanceProviderContract
  );

  const lendingPoolContract = useSelector((state) => state.contractsReducer.lendingPoolContract);
  const priceOracleContract = useSelector((state) => state.contractsReducer.priceOracleContract);
  const getUserInfo = async () => {
    let reservData = await getReserveData(
      uIPoolDataProviderContract,
      walletBalanceProviderContract,
      priceOracleContract,
      walletAddress,
      window.ethereum,
      lendingPoolContract
    );
    const userReservData = await getUserReservesData(
      uIPoolDataProviderContract,
      walletBalanceProviderContract,
      priceOracleContract,
      walletAddress,
      reservData,
      window.ethereum
    );
    dispatch(setReservesData({ data: reservData }));
    dispatch(setUserReservesData({ data: userReservData }));
  };
  useEffect(() => {
    if (
      lendingPoolContract?.methods !== undefined &&
      uIPoolDataProviderContract?.methods !== undefined &&
      !userReservesData?.userAddr
    ) {
      setLoading(true);
      getUserInfo()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [lendingPoolContract, uIPoolDataProviderContract, priceOracleContract, userReservesData]);
  return (
    <div className={`container dashboard-details`}>
      <SuccessPopup isOpen={isToggleSuccessPopup} />
      <SupplyWithdrawModal
        tabData={supplyWithdrawModalState.tabData}
        initialActiveTab={supplyWithdrawModalState.activeTab}
        isOpen={supplyWithdrawModalState.isWithdrawSupplyModalOpen}
        onClose={toggleWithdrawSupplyModal}
        getUserInfo={getUserInfo}
        setIsToggleSuccessPopup={setIsToggleSuccessPopup}
      />
      <BorrowRepayModal
        tabData={repayBorrowModalState.tabData}
        initialActiveTab={repayBorrowModalState.activeTab}
        isOpen={repayBorrowModalState.isRepayBorrowModalOpen}
        onClose={toggleRepayBorrowModal}
        walletAddress={walletAddress}
        getUserInfo={getUserInfo}
        setIsToggleSuccessPopup={setIsToggleSuccessPopup}
        mode={2}
      />
      <div className="breadcrumb-section">
        <ArrowBackIosNewIcon onClick={handleBackButtonClick} />
        <TextWithImage text={intl.messages.ethereum_market}>
          <img src={Ethereum} alt="Ethereum" />
        </TextWithImage>
      </div>
      <div className="main-content">
        <Banner className="banner intro-section">
          <div className="left-part">
            <ItemWithTitleAndDescription
              imgSrc={location.state.asset.imgSrc}
              title={location.state.asset.description}
              description={`${location.state.asset.description} Stablecoin`}
            />
            <div className="wallet-share-icons">
              <IconWithBackground>
                <WalletIcon />
              </IconWithBackground>
              <IconWithBackground>
                <ShareIcon />
              </IconWithBackground>
            </div>
          </div>
          <div className="main-information">
            {mainInfo.map((item, index) => {
              return (
                <InfoBanner
                  className="main-info-item"
                  key={index}
                  title={item.title}
                  value={item.value}
                  href={item.href}
                />
              );
            })}
          </div>
        </Banner>
        {isMobileSize && (
          <Tabs className={'tabs'}>
            <TabItem
              onClick={() => {
                setActiveTab('overview');
              }}
              isActive={activeTab === 'overview'}
              text={intl.messages.overview}
            />
            <TabItem
              onClick={() => {
                setActiveTab('yourInfo');
              }}
              isActive={activeTab === 'yourInfo'}
              text={intl.messages.your_info}
            />
          </Tabs>
        )}
        <div className="info-section">
          {isOverviewSectionVisible && (
            <div className="charts-section">
              <Banner className="banner banner-info">
                <div className="chart-intro-section">
                  <p className="chart-title">{intl.messages.supply_info}</p>
                  <TextWithHighlightedDot text={intl.messages.supply_apy} color="var(--green)" />
                </div>
                <div className="banners-section">
                  <InfoBanner
                    className="chart-info-item"
                    title={`${location.state.asset.description} ${intl.messages.total_supplied}`}
                    value={`${nFormatter(state.asset.totalSupplied, 2)}`}
                    secondValue={`$ ${nFormatter(state.asset.totalSuppliedInUsd, 2)}`}
                  />
                  <InfoBanner
                    className="chart-info-item"
                    title={intl.messages.apy}
                    value={`${state.asset.supplyAPY.toFixed(2)} %`}
                  />
                  {/* TODO add  in the future */}
                  {/* <InfoBanner
                    className="chart-info-item"
                    title={intl.messages.distribution_apy}
                    value="0 %"
                  /> */}
                </div>
                {/* TODO add chart in the future */}
                {/* <div className="chart-container">
                  <LineChart config={config} />
                </div> */}
                <div className="additional-info-section">
                  <div className="collateral-usage">
                    <p>{intl.messages.collateral_usage}</p>
                    <TextWithImage
                      className="success-icon-container"
                      text={intl.messages.can_be_collateral}>
                      <img src={SuccessImg} alt="" />
                    </TextWithImage>
                  </div>
                  <div className="banners-container">
                    <InfoBanner
                      warning
                      className="chart-info-item"
                      title={intl.messages.max_ltv}
                      value={`${maxLtv.toFixed(2)} %`}
                      warningText={intl.messages.warning_Text_Max_LTV}
                    />
                    <InfoBanner
                      warning
                      className="chart-info-item"
                      title={intl.messages.liquidation_threshold}
                      warningText={intl.messages.warning_Text_Liquidation_Threshold}
                      value={`${reserveLiquidationThreshold.toFixed(2)} %`}
                    />
                    <InfoBanner
                      warning
                      className="chart-info-item"
                      title={intl.messages.liquidation_penalty}
                      warningText={intl.messages.warning_Text_Liquidation_penalty}
                      value={`${reserveLiquidationBonus.toFixed(2)} %`}
                    />
                  </div>
                </div>
              </Banner>
              <Banner className="banner banner-info">
                <div className="chart-intro-section">
                  <p className="chart-title">{intl.messages.borrow_info}</p>
                  <TextWithHighlightedDot text={intl.messages.borrow_apy} color="var(--red)" />
                </div>
                <div className="banners-section">
                  <InfoBanner
                    className="chart-info-item"
                    title={`${location.state.asset.description} ${intl.messages.total_borrow}`}
                    value={`${nFormatter(state.asset.totalBorrowed, 2)}`}
                    secondValue={`$ ${nFormatter(state.asset.totalBorrowedInUsd, 2)}`}
                  />
                  {/*<InfoBanner*/}
                  {/*  className="chart-info-item"*/}
                  {/*  title={intl.messages.apy_stable}*/}
                  {/*  value={`${state.asset.stableBorrowAPY.toFixed(2)} %`}*/}
                  {/*/>*/}
                  <InfoBanner
                    className="chart-info-item"
                    title={intl.messages.apy_variable}
                    value={`${state.asset.variableBorrowAPY.toFixed(2)} %`}
                  />
                </div>
                {/* TODO add chart in the future */}
                {/* <div className="chart-container">
                  <LineChart config={config1} />
                </div> */}
                <div className="additional-info-section">
                  <div className="collateral-usage">
                    <p>{intl.messages.collector_info}</p>
                  </div>
                  <div className="banners-container">
                    <InfoBanner
                      warning
                      className="chart-info-item"
                      title={intl.messages.reserve_factor}
                      value={`${reserveFactor.toFixed(2)} %`}
                      warningText={intl.messages.warning_Text_Reserve_factor}
                    />
                    {/* <InfoBannerWithLink
                      className="chart-info-item"
                      title={intl.messages.collector_contract}
                      text={intl.messages.view_contract}
                      href="/"
                    /> */}
                  </div>
                </div>
              </Banner>
              {/* <Banner className="banner banner-info">
                <div className="chart-intro-section">
                  <p className="chart-title">{intl.messages.interest_rate_model}</p>
                  <div className="chart-series">
                    <TextWithHighlightedDot
                      className="series-item"
                      text={intl.messages.utilization_rate}
                      color="var(--purpleLight)"
                    />
                    <TextWithHighlightedDot
                      className="series-item"
                      text={intl.messages.borrow_apy}
                      color="var(--red)"
                    />
                    <TextWithHighlightedDot
                      className="series-item"
                      text={intl.messages.supply_apy}
                      color="var(--green)"
                    />
                  </div>
                </div>
                <div className="chart-container">
                  <LineChart config={config2} />
                </div>
              </Banner> */}
            </div>
          )}
          {isYourInfoSectionVisible && (
            <div className="your-info-section">
              <Banner className="banner">
                <p className="your-info-title">{intl.messages.your_info}</p>
                {balance === 0 && (
                  <WarningBanner
                    className="warning-banner"
                    imgSrc={blueWarning}
                    type="suggested"
                    title={intl.messages.your_wallet_is_empty}
                  />
                )}
                <TitleWithValueAndWarningIcon
                  title={intl.messages.wallet_balance}
                  value={`${balance} ${location.state.asset.description}`}
                  convertedValue={`$ ${nFormatter(balanceInUsd, 2)}`}
                  className="info-item"
                />
                <TitleWithValueAndWarningIcon
                  title={intl.messages.available_to_supply}
                  value={`${nFormatter(availableSupplyInToken, 2)} ${
                    location.state.asset.description
                  }`}
                  className="info-item"
                />
                <TitleWithValueAndWarningIcon
                  title={intl.messages.available_to_borrow}
                  value={`${nFormatter(availableBorrowInToken, 2)} ${
                    location.state.asset.description
                  }`}
                  className="info-item"
                />
                <div className="your-info-actions">
                  <TPYButton
                    onClick={() => toggleWithdrawSupplyModal('supply', state.asset)}
                    disabled={availableSupplyInToken.isZero() || loading}>
                    {intl.messages.supply}
                  </TPYButton>
                  <TPYButton
                    loading={loading}
                    onClick={() => toggleRepayBorrowModal('borrow', state.asset)}
                    disabled={availableBorrowInToken.isZero() || loading}>
                    {intl.messages.borrow}
                  </TPYButton>
                </div>
              </Banner>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardDetails;
