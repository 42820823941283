import ShareIcon from '../ShareIcon';

import styles from './index.module.css';
import { ETHERSCAN_URL } from '../../constants/rpcUrls';

const TextWithShareIcon = ({ text, className = '', link = '' }) => {
  return (
    <a
      className={`${className} ${styles['text-with-share-icon']}`}
      href={`${ETHERSCAN_URL}${link}`}
      target="_blank"
      rel="noreferrer">
      <p className={styles.text}>{text}</p>
      <ShareIcon />
    </a>
  );
};

export default TextWithShareIcon;
