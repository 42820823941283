import LinearProgress from '@mui/material/LinearProgress';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useIntl } from 'react-intl';
import Banner from '../../components/Banner';
import Section from '../../components/Section';
import ProcessSteps from '../../components/ProcessSteps';
import TPYButton from '../../components/buttons/TPYButton';
import TextWithImage from '../../components/TextWithImage';
import TitleDescription from '../../components/TitleDescription';
import TPYInnerTitlesDescription from '../../components/TPYInnerTitlesDescription';

import processStepsSuccess from '../../images/icons/process-steps-success.svg';

import './styles.scss';

function ConnectedGoverance() {
  const intl = useIntl();

  const PROCESS_STEPS_DATA = [
    { text: intl.messages.created, date: '07 Sep 2022 7:57 PM' },
    { text: intl.messages.active, date: '07 Sep 2022 7:57 PM' },
    { text: intl.messages.succeed, date: '08 Sep 2022 7:59 PM' },
    { text: intl.messages.queue, date: '08 Sep 2022 9:00 PM' },
    { text: intl.messages.execute, date: '10 Sep 2022 10:13 PM' }
  ];

  return (
    <Section>
      <div className="breadcrumb-section">
        <ArrowBackIosNewIcon />
        <TextWithImage text={intl.messages.proposal_overview} />
      </div>
      <Banner className="top-banner-box">
        <div className="banner-title-box">
          <TitleDescription className="banner-description" description={intl.messages.not_voted} />
          <TitleDescription
            className="banner-description"
            title={intl.messages.add_stMATIC_to_aave_pool_on_polygon}
          />
        </div>
        <Banner className="top-inner-banner">
          <ProcessSteps processData={PROCESS_STEPS_DATA} />
        </Banner>
      </Banner>
      <div className="banners-container">
        <Banner className="banner-box">
          <div className="progress-box">
            <div className="progress-title-box">
              <TPYInnerTitlesDescription description="For" />
              <TPYInnerTitlesDescription title="811.88K" />
            </div>
            <LinearProgress
              value={95}
              color="success"
              variant="determinate"
              className="progress-bar"
            />
            <TPYButton tooltipText={intl.messages.for} disabled={true} className="progress-btn">
              {intl.messages.for}
            </TPYButton>
          </div>
          <div className="progress-box">
            <div className="progress-title-box">
              <TPYInnerTitlesDescription description={intl.messages.addresses4} />
              <TPYInnerTitlesDescription title={intl.messages.votes} />
            </div>
            <div className="wallet-key-title">
              <TPYInnerTitlesDescription title="0xc4...2391" className="title" />
              <TPYInnerTitlesDescription title="737.01K" />
            </div>
            <div className="wallet-key-title">
              <TPYInnerTitlesDescription title="0xc4...2391" className="title" />
              <TPYInnerTitlesDescription title="737.01K" />
            </div>
            <div className="wallet-key-title">
              <TPYInnerTitlesDescription title="0xc4...2391" className="title" />
              <TPYInnerTitlesDescription title="737.01K" />
            </div>
            <div className="wallet-key-title">
              <TPYInnerTitlesDescription title="0xc4...2391" className="title" />
              <TPYInnerTitlesDescription title="737.01K" />
            </div>
          </div>
        </Banner>
        <Banner className="banner-box">
          <div className="progress-box">
            <div className="progress-title-box">
              <TPYInnerTitlesDescription description="For" />
              <TPYInnerTitlesDescription title="0.00" />
            </div>
            <LinearProgress
              value={0}
              color="success"
              variant="determinate"
              className="progress-bar"
            />
            <TPYButton tooltipText={intl.messages.against} disabled={true} className="progress-btn">
              {intl.messages.against}
            </TPYButton>
          </div>
          <div className="progress-box">
            <div className="progress-title-box">
              <TPYInnerTitlesDescription description={intl.messages.addresses0} />
              <TPYInnerTitlesDescription title={intl.messages.votes} />
            </div>
          </div>
        </Banner>
        <div className="right-banner-container">
          <Banner className="banner-box empty-banner">
            <TitleDescription
              className="empty-banner-title"
              title={intl.messages.your_voting_info}
              description={intl.messages.you_did_not_participate_in_this_proposal}
            />
          </Banner>
          <Banner className="banner-box">
            <TitleDescription title={intl.messages.voting_results} />
            <div className="right-banner-text-box">
              <div className="text-box-row">
                <TPYInnerTitlesDescription title={intl.messages.state} />
                <h4 className="success-title">{intl.messages.executes_on_sep_17_2022}</h4>
              </div>
              <div className="text-box-row">
                <TPYInnerTitlesDescription title={intl.messages.quorum} />
                <div className="success-text">
                  <img alt="/#" src={processStepsSuccess} />
                  <TPYInnerTitlesDescription title={intl.messages.reached} />
                </div>
              </div>
              <div className="text-box-row">
                <TPYInnerTitlesDescription
                  title={intl.messages.current_votes}
                  description={intl.messages.required}
                />
                <TPYInnerTitlesDescription title="364.98K" description="320.00K" />
              </div>
              <div className="text-box-row">
                <TPYInnerTitlesDescription title={intl.messages.differential} />
                <div className="success-text">
                  <img alt="/#" src={processStepsSuccess} />
                  <TPYInnerTitlesDescription title={intl.messages.reached} />
                </div>
              </div>
              <div className="text-box-row">
                <TPYInnerTitlesDescription
                  title={intl.messages.current_diferential}
                  description={intl.messages.required}
                />
                <TPYInnerTitlesDescription title="360.98K" description="80,000.00" />
              </div>
              <div className="text-box-row">
                <TPYInnerTitlesDescription title={intl.messages.total_voting_power} />
                <TPYInnerTitlesDescription title="16,000,000" />
              </div>
            </div>
          </Banner>
        </div>
      </div>
      <Banner className="last-banner-box">
        <TitleDescription
          className="information-text"
          title={intl.messages.simple_summary}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam enim, laoreet massa, fermentum arcu rutrum. Commodo fusce turpis venenatis dui ut diam eget. Morbi vitae, ultrices habitant enim."
        />
        <TitleDescription title="" className="information-text" description="" />
        <TitleDescription
          className="information-text"
          title={intl.messages.abstract}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae id cras at mi. Magna turpis faucibus nec faucibus auctor amet enim vel. In sapien ultrices facilisis ipsum. Augue malesuada semper tortor sagittis commodo adipiscing eget lobortis sapien. Vitae massa eget at sit et ullamcorper volutpat. Mi, dolor, et at tempor sed sed. Scelerisque sed placerat massa sodales fermentum ultrices volutpat pretium adipiscing. Felis dolor consequat, at enim id. Nisi mi faucibus a malesuada id non ultrices. Cras eu, commodo facilisis mi, neque aliquet consectetur nibh. Mattis orci et ullamcorper ante quam odio non. In augue risus mauris ullamcorper donec ut tellus dictum porttitor. Sollicitudin malesuada posuere pharetra quis ut erat lectus. Sed commodo, quis nunc, iaculis odio. Dolor aliquam vestibulum sit amet lectus ultrices scelerisque ultricies eu."
        />
        <TitleDescription
          className="information-text"
          title={intl.messages.motivation}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae id cras at mi. Magna turpis faucibus nec faucibus auctor amet enim vel. In sapien ultrices facilisis ipsum. Augue malesuada semper tortor sagittis commodo adipiscing eget lobortis sapien. Vitae massa eget at sit et ullamcorper volutpat. Mi, dolor, et at tempor sed sed. Scelerisque sed placerat massa sodales fermentum ultrices volutpat pretium adipiscing. Felis dolor consequat, at enim id. Nisi mi faucibus a malesuada id non ultrices. Cras eu, commodo facilisis mi, neque aliquet consectetur nibh. Mattis orci et ullamcorper ante quam odio non. In augue risus mauris ullamcorper donec ut tellus dictum porttitor. Sollicitudin malesuada posuere pharetra quis ut erat lectus. Sed commodo, quis nunc, iaculis odio. Dolor aliquam vestibulum sit amet lectus ultrices scelerisque ultricies eu."
        />
        <TitleDescription
          className="information-text"
          title={intl.messages.specification}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae id cras at mi. Magna turpis faucibus nec faucibus auctor amet enim vel. In sapien ultrices facilisis ipsum. Augue malesuada semper tortor sagittis commodo adipiscing eget lobortis sapien. Vitae massa eget at sit et ullamcorper volutpat. Mi, dolor, et at tempor sed sed. Scelerisque sed placerat massa sodales"
        />
        <TitleDescription
          className="information-text"
          title={intl.messages.test_cases}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae id cras at mi. Magna turpis faucibus nec faucibus auctor amet enim vel. In sapien ultrices facilisis ipsum. Augue malesuada semper tortor sagittis commodo adipiscing eget lobortis sapien. Vitae massa eget at sit et ullamcorper volutpat. Mi, dolor, et at tempor sed sed. Scelerisque sed placerat massa sodales "
        />
        <TitleDescription
          className="information-text"
          title={intl.messages.implementation}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae id cras at mi. Magna turpis faucibus nec faucibus auctor amet enim vel. In sapien ultrices facilisis ipsum. Augue malesuada semper tortor sagittis commodo adipiscing eget lobortis sapien. Vitae massa eget at sit et ullamcorper volutpat. Mi, dolor, et at tempor sed sed. Scelerisque sed placerat massa sodales  "
        />
      </Banner>
    </Section>
  );
}

export default ConnectedGoverance;
