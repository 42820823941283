import ModalContentWrapperWithClose from '../Modals/ModalContentWrapperWithClose';
import ButtonWithLoader from '../buttons/ButtonWithLoader';
import ModalWithBackdrop from '../Modals/ModalWithBackdrop';
import { useIntl } from 'react-intl';

import styles from './index.module.css';
import { changeBorrowRateMode } from '../../services/Web3';
import { useState } from 'react';
import { setSuccessModalData } from '../../redux/actions/reserveActions';
import { getToken } from '../../utilitis';
import { useDispatch } from 'react-redux';

const EnterAmountModal = ({
  isOpen,
  walletAddress,
  data,
  setIsOpen,
  getUserInfo,
  setIsToggleSuccessPopup
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const changeRate = async () => {
    setIsToggleSuccessPopup({
      isSuccessPopupModalOpen: false,
      showAddToWalletSection: true
    });
    setIsLoading(true);
    try {
      const result = await changeBorrowRateMode(
        window.ethereum,
        walletAddress,
        data.tokenAddr,
        data.variant === 1 ? 2 : 1
      );
      dispatch(
        setSuccessModalData({
          data: {
            txHeash: result.transactionHash,
            rate: data.variant
          }
        })
      );
      setIsOpen(false);
      setIsToggleSuccessPopup({
        isSuccessPopupModalOpen: true,
        showAddToWalletSection: true
      });
      await getUserInfo();
    } catch (e) {}
    setIsLoading(false);
  };
  return (
    <ModalWithBackdrop
      isOpen={isOpen}
      onBackDropClick={() => {
        setIsOpen(false);
      }}>
      <ModalContentWrapperWithClose
        className={styles['enter-an-amount']}
        onClose={() => {
          setIsOpen(false);
        }}>
        <p className={styles.title}>{intl.messages.switch_type}</p>
        <div className={styles.flex}>
          <p className={styles.subtitle}>{intl.messages.transaction_overview}</p>
        </div>
        <div className={`${styles['amount-box']} ${styles.flex}`}>
          <p className={styles['input-value']}>{intl.messages.new_apy}</p>
          <p className={styles['input-value']}>
            {data.variant === 1 ? intl.messages.stable : intl.messages.variable} {data.percent}
          </p>
        </div>
        <ButtonWithLoader
          onClick={changeRate}
          className={styles.button}
          isLoading={isLoading}
          disabled={isLoading}>
          {intl.messages.switch_rate}
        </ButtonWithLoader>
      </ModalContentWrapperWithClose>
    </ModalWithBackdrop>
  );
};

export default EnterAmountModal;
