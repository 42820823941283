import convertTokenAmountToETH from './convertTokenAmountToETH';
import convertMarketCurrencyPriceToUSD from './convertMarketCurrencyPriceToUSD';

const getTotalSuppliedInUsd = (tokenInfo) => {
  const amountInETH = convertTokenAmountToETH(
    tokenInfo.tokenAddr,
    tokenInfo.aTokenTotal,
    tokenInfo
  );
  const totalSuppliedInUsd = convertMarketCurrencyPriceToUSD(
    amountInETH,
    tokenInfo.networkBaseTokenPriceInUsd
  );

  return totalSuppliedInUsd;
};

export default getTotalSuppliedInUsd;
