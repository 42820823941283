import './index.scss';

const SocialMediaIcons = ({ isDarkMode }) => {
  return (
    <div className="icons-container">
      <a href="https://medium.com/@thrupennyprotocol" target="_blank" rel="noreferrer">
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_892_50906)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
              fill={isDarkMode ? '#312E47' : '#FCFCFF'}
            />
            <path
              d="M14.8968 14C14.8968 16.1956 13.129 17.9755 10.9484 17.9755C8.76775 17.9755 7 16.1956 7 14C7 11.8044 8.76775 10.0245 10.9484 10.0245C13.129 10.0245 14.8968 11.8044 14.8968 14Z"
              fill={isDarkMode ? '#FCFCFF' : '#312E47'}
            />
            <path
              d="M19.2282 14C19.2282 16.0668 18.3443 17.7422 17.254 17.7422C16.1637 17.7422 15.2798 16.0668 15.2798 14C15.2798 11.9332 16.1637 10.2578 17.254 10.2578C18.3443 10.2578 19.2282 11.9332 19.2282 14Z"
              fill={isDarkMode ? '#FCFCFF' : '#312E47'}
            />
            <path
              d="M21 14C21 15.8518 20.6891 17.3529 20.3056 17.3529C19.9221 17.3529 19.6113 15.8518 19.6113 14C19.6113 12.1483 19.9221 10.6472 20.3056 10.6472C20.6891 10.6472 21 12.1483 21 14Z"
              fill={isDarkMode ? '#FCFCFF' : '#312E47'}
            />
          </g>
          <defs>
            <clipPath id="clip0_892_50906">
              <rect width="28" height="28" fill={isDarkMode ? '#FCFCFF' : '#312E47'} />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a href="https://t.me/thrupenny" target="_blank" rel="noreferrer">
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_892_50903)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
              fill={isDarkMode ? '#312E47' : '#FCFCFF'}
            />
            <path
              d="M20.0851 9.7853L17.9724 19.4384C17.8129 20.1195 17.3973 20.2891 16.8068 19.9684L13.5874 17.6701L12.0342 19.1177C11.8622 19.2843 11.7187 19.4234 11.3872 19.4234L11.6187 16.2472L17.5848 11.0241C17.8444 10.8003 17.5283 10.6758 17.1818 10.9001L9.80601 15.3998L6.63067 14.4367C5.9401 14.2279 5.9276 13.7676 6.77469 13.4464L19.1945 8.81054C19.7696 8.60173 20.2726 8.93456 20.0851 9.78578V9.7853Z"
              fill={isDarkMode ? '#FCFCFF' : '#312E47'}
            />
          </g>
          <defs>
            <clipPath id="clip0_892_50903">
              <rect width="28" height="28" fill={isDarkMode ? '#FCFCFF' : '#312E47'} />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a href="https://twitter.com/Thrupennydefi" target="_blank" rel="noreferrer">
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_892_50894)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
              fill={isDarkMode ? '#312E47' : '#FCFCFF'}
            />
            <path
              d="M21 10.1012C20.4852 10.3233 19.9249 10.4832 19.3478 10.5454C19.9469 10.1894 20.3956 9.62702 20.6097 8.96371C20.0474 9.29821 19.4315 9.53277 18.7892 9.65698C18.5208 9.36995 18.1961 9.1413 17.8354 8.98526C17.4747 8.82922 17.0858 8.74914 16.6928 8.75001C15.1029 8.75001 13.8242 10.0389 13.8242 11.6207C13.8242 11.8428 13.8511 12.0649 13.8948 12.2786C11.5141 12.1541 9.39082 11.0166 7.97921 9.27501C7.722 9.71438 7.58721 10.2147 7.58887 10.7238C7.58887 11.72 8.0953 12.5983 8.86756 13.1149C8.41246 13.097 7.96801 12.9719 7.57036 12.7498V12.7851C7.57036 14.1801 8.5563 15.3361 9.87033 15.6019C9.62361 15.666 9.3698 15.6988 9.11489 15.6995C8.92813 15.6995 8.75147 15.681 8.57313 15.6558C8.93655 16.7933 9.99483 17.6195 11.255 17.6464C10.2691 18.4188 9.03413 18.8731 7.69319 18.8731C7.45259 18.8731 7.2305 18.8647 7 18.8377C8.27196 19.6538 9.78116 20.125 11.4064 20.125C16.6827 20.125 19.5699 15.7534 19.5699 11.9589C19.5699 11.8344 19.5699 11.7099 19.5615 11.5853C20.1201 11.1764 20.6097 10.67 21 10.1012Z"
              fill={isDarkMode ? '#FCFCFF' : '#312E47'}
            />
          </g>
          <defs>
            <clipPath id="clip0_892_50894">
              <rect width="28" height="28" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a
        href="https://outlook.office.com/mail/deeplink/compose?mailtouri=mailto%3A%2520cs%40thrupenny.io"
        target="_blank"
        rel="noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="20"
          height="20"
          viewBox="0 0 48 48">
          <path
            fill={isDarkMode ? '#FCFCFF' : '#312E47'}
            d="M 21.541016 4.0957031 C 21.277323 4.0910623 21.009427 4.1178181 20.740234 4.1777344 L 20.740234 4.1757812 L 7.5234375 7.1132812 C 5.4728994 7.5691225 4 9.4069339 4 11.507812 L 4 36.492188 C 4 38.593573 5.4735774 40.431028 7.5234375 40.886719 L 20.740234 43.824219 L 20.740234 43.822266 C 22.893773 44.301643 25 42.611755 25 40.40625 L 25 7.59375 C 25 5.663933 23.386864 4.1281887 21.541016 4.0957031 z M 28 11.050781 L 28 22.189453 L 31.509766 24.669922 L 44.990234 15.369141 C 44.900234 12.969141 42.92 11.050781 40.5 11.050781 L 28 11.050781 z M 14.5 16.5 C 17.584 16.5 20 20.014 20 24.5 C 20 28.986 17.584 32.5 14.5 32.5 C 11.416 32.5 9 28.986 9 24.5 C 9 20.014 11.416 16.5 14.5 16.5 z M 45 19.009766 L 32.349609 27.730469 C 32.099609 27.910469 31.8 28 31.5 28 C 31.2 28 30.890859 27.910703 30.630859 27.720703 L 28 25.859375 L 28 38.050781 L 40.5 38.050781 C 42.98 38.050781 45 36.030781 45 33.550781 L 45 19.009766 z M 14.5 19.5 C 13.32 19.5 12 21.638 12 24.5 C 12 27.362 13.32 29.5 14.5 29.5 C 15.68 29.5 17 27.362 17 24.5 C 17 21.638 15.68 19.5 14.5 19.5 z"></path>
        </svg>
      </a>
    </div>
  );
};

export default SocialMediaIcons;
