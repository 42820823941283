import BigNumber from 'bignumber.js';
import percentMul from './percentMul';
import { zero } from '../constants/numbers';

const calculateAvailableBorrowsETH = (collateralInETH, debtInETH, ltv) => {
  collateralInETH = new BigNumber(collateralInETH);
  debtInETH = new BigNumber(debtInETH);
  ltv = new BigNumber(ltv);
  const availableBorrowInETH = percentMul(collateralInETH, ltv);
  if (availableBorrowInETH.lt(debtInETH)) {
    return zero;
  }

  return availableBorrowInETH.minus(debtInETH);
};

export default calculateAvailableBorrowsETH;
