import styles from './index.module.scss';

function WarningBanner({ className = '', title, imgSrc, type, description = '' }) {
  return (
    <div className={`${styles.warningBannerBox} ${className}`}>
      <div className={`${styles[type]} ${styles.waringBox}`}>
        <div className={styles.warningImage}>{imgSrc && <img src={imgSrc} alt="#" />}</div>
        <div>
          <p className={styles.title}>{title}</p>
          {description && <p className={styles.description}>{description}</p>}
        </div>
      </div>
    </div>
  );
}

export default WarningBanner;
