import TextWithImage from '../TextWithImage';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import styles from './index.module.css';
import { convertMarketCurrencyPriceToUSD, convertToNumber } from '../../utilitis';
import BigNumber from 'bignumber.js';
import { ten } from '../../constants/numbers';

const AmountBox = ({
  imgSrc,
  coinName,
  balance,
  setAmountValue,
  tabData,
  className = '',
  balanceType = 'supply',
  maxAmount = 0,
  checkMaxAmount
}) => {
  const intl = useIntl();

  const [value, setValue] = useState('');
  const min = (balance) => {
    if (!maxAmount) {
      return balance;
    }
    return +convertToNumber(maxAmount, tabData?.decimals, false) > balance
      ? balance
      : +convertToNumber(maxAmount, tabData?.decimals, false);
  };
  const priceFormatter = Intl.NumberFormat('en', { notation: 'compact' });
  const handleConfigurePrice = (event) => {
    if (!event.target.value) {
      setValue('');
      setAmountValue(0);
    }
    if (Number(event.target.value) >= 0) {
      const amount = new BigNumber(event.target.value).gt(min(balance))
        ? min(balance)
        : event.target.value;
      setValue(amount);
      setAmountValue(
        amount,
        (maxAmount &&
          new BigNumber(+convertToNumber(maxAmount, tabData?.decimals, false)).isEqualTo(amount)) ||
          (balanceType === 'withdraw' && new BigNumber(balance).isEqualTo(amount))
      );
    }
  };
  useEffect(() => {
    setValue('');
    setAmountValue(0);
  }, [coinName]);
  const handleMaxAmount = () => {
    if (checkMaxAmount) {
      checkMaxAmount().then((data) => {
        const newBalance = convertToNumber(
          balanceType === 'borrow'
            ? new BigNumber(data.toFixed())
                .multipliedBy(0.9999)
                .multipliedBy(ten.pow(tabData?.decimals))
            : data,
          tabData?.decimals,
          false
        );
        setValue(min(+newBalance > balance ? balance : newBalance));
        setAmountValue(min(+newBalance > balance ? balance : newBalance), true);
      });
    } else {
      if (balanceType === 'supply' && coinName !== 'ETH') {
        balance = new BigNumber(tabData.realBalance)
          .dividedBy(new BigNumber(ten).pow(tabData.decimals))
          .toString();
      }
      setValue(min(balance));
      setAmountValue(min(balance), true);
    }
  };
  const checkBalance = () => {
    if (balanceType === 'withdraw') {
      const fixedBalance = new BigNumber(new BigNumber(balance).toFixed(2));
      return fixedBalance.lt(new BigNumber(balance))
        ? fixedBalance.plus(0.01).toFixed()
        : fixedBalance.toFixed();
    } else {
      let newBalance =
        coinName === 'ETH'
          ? balance
          : convertToNumber(tabData.realBalance, tabData.decimals, false);
      const fixedBalance = new BigNumber(new BigNumber(newBalance).toFixed(2));
      return fixedBalance.lt(new BigNumber(newBalance))
        ? fixedBalance.plus(0.01).toFixed()
        : fixedBalance.toFixed();
    }
  };
  return (
    <div className={`${className} ${styles['amount-box']}`}>
      <div className={styles['first-row']}>
        <input
          className={styles.input}
          type="text"
          placeholder="0"
          value={value}
          onChange={(event) => handleConfigurePrice(event)}
        />
        <TextWithImage className={styles['coin-item']} text={coinName}>
          <img className={styles['coin-img']} src={imgSrc} alt="" />
        </TextWithImage>
      </div>
      <div className={styles['second-row']}>
        <p className={styles.price}>
          ${' '}
          {priceFormatter.format(
            convertMarketCurrencyPriceToUSD(
              tabData.priceInMarketReferenceCurrency,
              tabData.networkBaseTokenPriceInUsd
            ).toNumber() * value
          )}
        </p>
        <div className={styles['balance-box']}>
          <span className={styles.balance}>
            {balanceType === 'supply' || balanceType === 'repay'
              ? intl.messages.walletBalance
              : balanceType === 'withdraw'
              ? intl.messages.supplyBalance
              : intl.messages.available}{' '}
            {checkBalance()}
          </span>
          <span className={styles.max} onClick={handleMaxAmount}>
            {intl.messages.max}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AmountBox;
