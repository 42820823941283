import NETWORKS from '../constants/networks';

const changeNetwork = async (networkName) => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        ...NETWORKS[networkName]
      }
    ]
  });
};

export default changeNetwork;
