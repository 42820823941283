import BigNumber from 'bignumber.js';
const percentMul = (value, percentage) => {
  value = new BigNumber(value);
  percentage = new BigNumber(percentage);
  const PERCENTAGE_FACTOR = new BigNumber(10000); // percentage plus two decimals
  const HALF_PERCENT = PERCENTAGE_FACTOR.div(2);
  return new BigNumber(
    value.multipliedBy(percentage).plus(HALF_PERCENT).div(PERCENTAGE_FACTOR).toFixed(0)
  );
};
export default percentMul;
