import styles from './index.module.css';

const ItemWithTitleAndDescription = ({ imgSrc, title, description }) => {
  return (
    <div className={styles['item-with-title-and-description']}>
      <img src={imgSrc} alt={title} />
      <div className={styles['title-with-description']}>
        <span className={styles.title}>{title}</span>
        <span className={styles.description}>{description}</span>
      </div>
    </div>
  );
};

export default ItemWithTitleAndDescription;
