export const setLendingPoolContract = (data) => ({
  type: 'ADD_CONTRACT_LANDING_POOL',
  payload: data
});

export const setUiPoolDataProiderContract = (data) => ({
  type: 'ADD_CONTRACT_UI_POOL_DATA_PROVIDER',
  payload: data
});

export const setPriceOracleContract = (data) => ({
  type: 'ADD_CONTRACT_PRICE_ORACLE',
  payload: data
});

export const setWalletBalanceProviderContract = (data) => ({
  type: 'ADD_CONTRACT_WALLET_BALANCE_PROVIDER',
  payload: data
});
