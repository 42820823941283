import BigNumber from 'bignumber.js';
import { convertTokenAmountToETH } from './index';
import { zero } from '../constants/numbers';
import wadDiv from './wadDiv';
import percentMul from './percentMul';
import { ETH_ADDRESS, WETH_ADDRESS } from '../constants/tokens';

const calculateHealthFactorOnAction = (userReservesData, tabData, tokenAddr, action, amount) => {
  amount = new BigNumber(amount).multipliedBy(new BigNumber(10).pow(tabData.decimals));
  tokenAddr = tokenAddr === ETH_ADDRESS ? WETH_ADDRESS : tokenAddr;
  const userInfo = userReservesData.suppliedTokens.find(
    (token) => token.underlyingAsset === tokenAddr
  );
  const healthFactorBefore = userReservesData.healthFactor;
  let collateralInETHAfter = new BigNumber(userReservesData.collateralInETH);
  let avgLiquidationThresholdAfter = new BigNumber(userReservesData.avgLiquidationThreshold);
  let debtInETHAfter = new BigNumber(userReservesData.debtInETH);
  if (action === 'supply' || action === 'withdraw') {
    if (!userInfo?.usageAsCollateralEnabledOnUser || !tabData?.usageAsCollateralEnabled) {
      return healthFactorBefore;
    }
  }
  const amountInETH = convertTokenAmountToETH(tokenAddr, amount, tabData);
  const reserveLiquidationThreshold = tabData.reserveLiquidationThreshold;
  const liqThrWeightSum = userReservesData.liqThrWeightSum;
  let liqThrWeightSumAfter = liqThrWeightSum;
  if (action === 'supply') {
    liqThrWeightSumAfter = liqThrWeightSum.plus(
      amountInETH.multipliedBy(reserveLiquidationThreshold)
    );
    collateralInETHAfter = userReservesData.collateralInETH.plus(amountInETH);
  } else if (action === 'withdraw') {
    liqThrWeightSumAfter = liqThrWeightSum.minus(
      amountInETH.multipliedBy(reserveLiquidationThreshold)
    );
    collateralInETHAfter = userReservesData.collateralInETH.minus(amountInETH);
  } else if (action === 'borrow') {
    debtInETHAfter = debtInETHAfter.plus(amountInETH);
  } else if (action === 'repay') {
    debtInETHAfter = debtInETHAfter.minus(amountInETH);
  }
  avgLiquidationThresholdAfter = collateralInETHAfter.isZero()
    ? zero
    : liqThrWeightSumAfter.div(collateralInETHAfter);
  return wadDiv(percentMul(collateralInETHAfter, avgLiquidationThresholdAfter), debtInETHAfter);
};

export default calculateHealthFactorOnAction;
