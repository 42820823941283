const initialState = {
  reservesData: {},
  userReservesData: {},
  successModalData: {}
};

const reserveReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'ADD_RESERVES_DATA':
      return {
        ...state,
        reservesData: payload.data
      };
    case 'ADD_USER_RESERVES_DATA':
      return {
        ...state,
        userReservesData: payload.data
      };
    case 'SET_SUCCESS_MODAL_DATA':
      return {
        ...state,
        successModalData: payload.data
      };
    default:
      return state;
  }
};

export default reserveReducer;
