import SocialMediaIcons from '../SocialMediaIcons';
import { useIntl } from 'react-intl';

import './index.scss';

import Logo from '../../images/logos/mini-logo.svg';

const Footer = ({ isDarkMode }) => {
  const intl = useIntl();

  return (
    <footer className="footer container">
      <img src={Logo} alt="Logo" />
      <SocialMediaIcons isDarkMode={isDarkMode} />
      <a
        className="support-text"
        href="https://outlook.office.com/mail/deeplink/compose?mailtouri=mailto%3A%2520cs%40thrupenny.io"
        target="_blank"
        rel="noreferrer">
        {intl.messages.support}
      </a>
    </footer>
  );
};

export default Footer;
