import BigNumber from 'bignumber.js';
import { tenPow18 } from '../constants/numbers';

const wadDiv = (a, b) => {
  a = new BigNumber(a);
  b = new BigNumber(b);
  const WAD = tenPow18;
  const halfB = new BigNumber(b.div(2).toFixed());
  return new BigNumber(a.multipliedBy(WAD).plus(halfB).div(b).toFixed(0));
};
export default wadDiv;
