import TPYTooltipWrapper from '../../TPYTooltipWrapper';

import styles from './index.module.css';

const TPYButton = ({
  children,
  onClick,
  className = '',
  title = '',
  tooltipText,
  isDisabled = false,
  ...defaultButtonProps
}) => {
  return (
    <>
      <TPYTooltipWrapper tooltipText={tooltipText}>
        <button
          type="button"
          className={`${styles.button} ${className} `}
          onClick={onClick}
          title={title}
          {...defaultButtonProps}>
          {children}
        </button>
      </TPYTooltipWrapper>
    </>
  );
};

export default TPYButton;
