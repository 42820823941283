import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TPYButton from '../buttons/TPYButton';
import TPYInnerTitlesDescription from '../TPYInnerTitlesDescription';

import './styles.scss';

import dai from '../../images/coins/dai.png';
import etherum from '../../images/coins/ethereum.svg';
import arrowUp from '../../images/icons/arrow-up.svg';
import arrowDown from '../../images/icons/arrow-down.svg';

const TABLE_HEAD = [
  { text: 'Asset' },
  { text: 'Wallet Balance' },
  { text: 'APY' },
  { text: 'Can Be Collateral' },
  { text: '' }
];

const TABLE_BODY = [
  [],
  [
    { imgSrc: etherum, text: 'ETHER', description: '', button: null },
    { imgSrc: '', text: '0', description: '', button: null },
    { imgSrc: '', text: '90.29 %', description: '', button: null },
    { imgSrc: '', text: '--', description: '', button: null },
    { imgSrc: '', text: '', description: '', button: <TPYButton>Supply</TPYButton> }
  ]
];

function TPYTable({
  className = '',
  isSort = false,
  isPaperTheme = false,
  bodyData = TABLE_BODY,
  headerData = TABLE_HEAD,
  onRowClick = Function.prototype
}) {
  return (
    <div className={`${className}`}>
      {bodyData.length ? (
        <TableContainer component={isPaperTheme && Paper}>
          <Table aria-label="caption table" className="table-container">
            <TableHead className="table-head-box">
              <TableRow>
                {headerData.map(({ text }, index) => (
                  <TableCell key={index}>
                    <div className="header-th">
                      {text}
                      {isSort && (
                        <div className="sort-arrow-box">
                          <img src={arrowUp} alt="#" />
                          <img src={arrowDown} alt="#" />
                        </div>
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="table-body-box">
              {bodyData.map((item, index) => (
                <TableRow onClick={() => onRowClick(item)} key={index}>
                  {item.map(({ imgSrc, text, description, button }, index) => (
                    <TableCell key={index} component="th" scope="row">
                      <div className="coins-img-box">
                        {imgSrc && <img src={imgSrc} alt="#" />}
                        <TPYInnerTitlesDescription title={text} description={description} />
                      </div>
                      {button && <div className="table-button-box">{button}</div>}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
}

export default TPYTable;
