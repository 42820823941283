import BigNumber from "bignumber.js/bignumber";

const getTotalSupplied = (aTokenTotal, decimals) => {
  const tenPowDecimals = BigNumber(10).pow(decimals);
  const totalSupplied = BigNumber(aTokenTotal).dividedBy(tenPowDecimals);

  return totalSupplied;
};

export default getTotalSupplied;
