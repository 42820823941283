const BLOCKCHAIN_IDS = {
  ETH: 1,
  BSC: 56,
  POLYGON: 137,
  AVAX: 43114,
  FANTOM: 250,
  ARBITRUM: 42161,
  HECO: 128,
  MOONRIVER: 1285,
  GNOSIS: 100,
  OPTIMISM: 10,
  KLAYTN: 8217,
  // TEST NETWORKS
  GOERLI: 5
};
export default BLOCKCHAIN_IDS;
