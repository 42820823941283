import BigNumber from 'bignumber.js';

const convertTokenAmountToETH = (tokenAddr, amount, tokenInfo) => {
  return new BigNumber(
    new BigNumber(amount)
      .multipliedBy(tokenInfo.priceInMarketReferenceCurrency)
      .div(new BigNumber(10).pow(tokenInfo.decimals))
      .toFixed()
  );
};

export default convertTokenAmountToETH;
