import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowSize() {
  const [useWindowSize, setUseWindowSize] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setUseWindowSize(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return useWindowSize;
}
