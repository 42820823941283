import styles from './index.module.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const TextWithWarningIcon = ({ text, warning, className = '' }) => {
  return (
    <div className={`${className} ${styles['text-with-warning-icon']}`}>
      <p className={styles.text}>{text}</p>
      {warning && <ErrorOutlineIcon />}
    </div>
  );
};

export default TextWithWarningIcon;
